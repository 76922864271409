import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BiLogoFacebookCircle } from 'react-icons/bi';
import { AiFillTwitterSquare } from 'react-icons/ai';
import { AiOutlineInstagram } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { FaTelegramPlane,FaDiscord,FaYoutube } from "react-icons/fa";

class NewHome extends Component {

    darkTheme() {
        document.body.classList.remove('light_theme');
        document.body.classList.add('dark_theme');
        document.getElementById("sun_icon").classList.remove('active');
        document.getElementById("moon_icon").classList.add('active');
        // document.getElementById("sun_icon_mob").classList.remove('active'); 
        // document.getElementById("moon_icon_mob").classList.add('active'); 
        localStorage.setItem("theme", 'dark_theme');


    }
    lightTheme() {
        document.body.classList.remove('dark_theme');
        document.body.classList.add('light_theme');
        document.getElementById("moon_icon").classList.remove('active');
        document.getElementById("sun_icon").classList.add('active');
        // document.getElementById("moon_icon_mob").classList.remove('active'); 
        // document.getElementById("sun_icon_mob").classList.add('active');
        localStorage.setItem("theme", 'light_theme');


    }



    

    render() {

        return (

            <div className='landing_banner'>

                <Container fluid>
                    <div className='header_top'>
                        <div className='logo_landing_home'>
                            <Link to="/">
                                <img className='mainlogo_img d-md-none' src={require('../images/Bitdeal/logo_curve.png').default} />

                                <img className='mainlogo_img mainlogo_img_desk_ver d-none d-md-block' src={require('../images/Bitdeal/lightlogo.png').default} />

                            </Link>
                            {/* <img className='mainlogo_img' src={require('../images/Bitdeal/logo_curve.png').default}/> */}
                        </div>
                        <div>
                            <Link to="/home"><button className="get-started-btn mr-2 text-uppercase" >Use App</button></Link>
                            <i className="fa fa-sun-o theme_icon" aria-hidden="true" id="sun_icon" onClick={() => this.lightTheme()}></i>
                            <span className='px-1 text_splash'>/</span>
                            <i class="fa fa-moon-o theme_icon active" aria-hidden="true" id="moon_icon" onClick={() => this.darkTheme()}></i>

                        </div>

                    </div>
                    <Container>


                        <Row className='h-100 banner_toprow'>
                            <Col className='banner_left_dtls mb-3' xs={12} sm={12} md={4}>
                                <h3 className="banner_left_ttl">BITDEALZ IDO</h3>
                                <p className='banner_left_hint'>BitDealz Finance is the most advanced IDO launchpad powered by creative and Web3 technologies. Get advanced digital financial solutions and a chance to be part of potentially game-changing projects.
                                </p>
                                {/* <button className="get-started-btn mr-2 text-uppercase" >Create Now</button> */}
                                <Link to="/create" className="get-started-btn mr-2 text-uppercase" >Create Now</Link>

                            </Col>
                            <Col xs={12} sm={12} md={8}>
                                <img className='img-fluid' src={require('../images/bitdeals/bigrocket.png').default} />
                            </Col>
                        </Row>

                        <Row className='h-100 reverse_row'>
                            <Col className='mb-5' xs={12} sm={12} md={5}>
                                <img className='girl_rocketimg img-fluid' src={require('../images/bitdeals/girlrocket.png').default} />
                            </Col>
                            <Col className='mb-5' xs={12} sm={12} md={7}>
                                <p className='why_bitdeals_txt'>Why <span className='bitdeals_why'>BITDEALZ IDO</span> ?</p>
                                <p className='banner_left_hint'>More than just a launchpad, BitDealz Finance is your gateway to innovative crypto projects. Our IDO platform  is built on a strong foundation of integrity, providing a secure and trustworthy environment to engage in token sales. We believe in aligning incentives to create a win-win scenario, fostering a thriving ecosystem for all project creators and contributors.
                                </p>
                                <p className='banner_left_hint'>BitDealz Finance empowers the global crypto community where like-minded individuals come together to support and engage with exciting new blockchain projects. We are well-connected, providing valuable exposure and opportunities for promising projects to succeed in the competitive crypto landscape.
                                </p>
                                {/* <button className="get-started-btn  text-uppercase" >
                      Get Started
                        </button> */}
                                <Link to="/home" className="get-started-btn mr-2 text-uppercase" >Get Started</Link>
                            </Col>
                        </Row>

                        <Row className='mt-5 justify-content-center'>
                            <Col xs={12} sm={12} md={6}>
                                <div className='w-100'>
                                    <p className='why_bitdeals_txt text-center'>Features of <span className='bitdeals_why'>BITDEALZ IDO</span></p>
                                    <p className='banner_left_hint text-center'>BitDealz Finance remains a dynamic IDO launchpad, empowering promising blockchain projects into game-changing ventures with our unique approach. </p>


                                </div>
                            </Col>
                        </Row>

                        <Row className='mt-5 text-center'>
                            <Col className='mb-3' xs={12} sm={12} md={4}>
                                <div className='thunder_whole'>
                                    <img className='img-fluid' src={require('../images/bitdeals/Friend.png').default} />
                                    <p className='thunder_boldtxt'>Beginner Friendly</p>
                                    <p className='banner_left_hint text-center'>Our IDO Launchpad is designed with beginners in mind, providing intuitive features, step-by-step guides, and a supportive community for the fast launch of tokens.
                                    </p>
                                </div>
                            </Col>
                            <Col className='mb-3' xs={12} sm={12} md={4}>
                                <div className='thunder_whole'>
                                    <img className='img-fluid' src={require('../images/bitdeals/long.png').default} />
                                    <p className='thunder_boldtxt'>Long-Term Sustainability </p>
                                    <p className='banner_left_hint text-center'>BitDealz Finance focuses on the long-term success of the projects we support. We encourage projects for sustained growth in web3 and multichain ecosystems.  </p>
                                </div>
                            </Col>
                            <Col className='mb-3' xs={12} sm={12} md={4}>
                                <div className='thunder_whole'>
                                    <img className='img-fluid' src={require('../images/bitdeals/files.png').default} />
                                    <p className='thunder_boldtxt'>Rapid Growth</p>
                                    <p className='banner_left_hint text-center'>Our IDO Launchpad identifies and supports projects with the potential for the rapid growth of the token portfolio by the global audience. </p>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </Container>

                <hr className='green_hrline' />

                 <Container fluid>
                    <Container>
                        <Row>
                            <Col xs={12} lg={4}></Col>
                            <Col xs={12} lg={8}>
                                <div className='bottom_footer'>
                                    <p className='banner_left_hint copyright_txt'>COPYRIGHT @ 2023. ALL RIGHTS RESERVED</p>
                                    <div className='social_icons_bottomfoot'>
                                        <a href="https://telegram.me/+VOXtFEJ7-wxlNmU0" target="_blank"><FaTelegramPlane className='footer_social_logos' /></a>
                                        <a href="https://discord.com/invite/85hc8Cup" target="_blank"><FaDiscord className='footer_social_logos' /></a>
                                        {/* <a href="/" target="_blank"><AiOutlineInstagram className='footer_social_logos' /></a> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>

            </div>
        )
    }
}

export default NewHome