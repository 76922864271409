import { ERC20_ABI, DECIMAL } from "../config/erc20";
import { PROXY_SALE,PROXY_SALE_ABI } from '../config/proxy'
import { SALE_ABI } from '../config/presale'
import toast, { Toaster } from 'react-hot-toast';
import { iconTheme, position, style } from "./useToast";
import { useWeb3 } from "./useWeb3";
import { getProxyOwner, getSaleInfoCard, toFixedNumber, UseBCoinSale, UseSale } from "./useContract";
import { getAccount } from "./useAccount";
import { userinvestedhook } from "./usebackend";
import { CHAINS, frontendurl } from "../config/env";
import { UseAddReferrer } from "./useReferrer";

export const calculateBuy = async(saleAddress,bnbValue)=>{
    const saleContact = await UseSale(saleAddress);
    const bnbValues = toFixedNumber(bnbValue * 10 **18);
    console.log("claim token in use buy" , bnbValues);
    const willGet = await saleContact.methods.getTokensPerEth(bnbValues.toString()).call();
    return willGet;
}

export const BcoinBuyToken = async(saleAddress,useramount,isMax,maxNumber,account,decimal)=>{
    console.log("buytoken data" , saleAddress,useramount,isMax,maxNumber,account,decimal);
    const web3 = await useWeb3();
    const saleContact = await UseBCoinSale(saleAddress);
    const saleInfo = await getSaleInfoCard(saleAddress);
    // const referrer = await getProxyOwner(); //  default referrer
    const amount = !isMax? toFixedNumber(useramount * 10 **decimal): maxNumber ;
    if(saleInfo.buytype)
        var data =  saleContact.methods.contribute(amount.toString()).send({ from: account,value: amount.toString() })
    else 
    var data =  saleContact.methods.contribute(amount.toString()).send({ from: account})
    // const data = web3.eth.sendTransaction({ from: account, to: saleAddress, value: amount.toString() })
    
    await toast.promise(data, {
        loading: 'Making a Buy Token Request...',
        success: 'Bougt Token Successfully',
        error: 'Error ! When Buying Token',
    }, {
        position:position.position,
    style:style,
    iconTheme: iconTheme
    }
    );
    let wallet = getAccount();
    if(wallet){
        wallet = wallet.toString();
        wallet = wallet.toLowerCase();
        console.log("wallet" , wallet);
        let payload = {
            walletaddress :wallet,
            saleaddress : saleAddress,
            amount : useramount
        }
        let usercontribute = await userinvestedhook(payload);
    }
}

export const BuyToken = async(saleAddress,useramount,isMax,maxNumber,account,decimal,referrer,tokenaddress,rewardAmount,tokensymbol)=>{
    console.log("buytoken data" , saleAddress,useramount,isMax,maxNumber,account,decimal,referrer);
    const web3 = await useWeb3();
    const saleContact = await UseSale(saleAddress);
    const saleInfo = await getSaleInfoCard(saleAddress);

    const amount = !isMax? toFixedNumber(useramount * 10  ** decimal):  toFixedNumber(maxNumber *10  ** decimal) ;
    console.log("MaxVC saleInfo.buytype",saleInfo,amount,maxNumber,amount>saleInfo.maxEthLimit)
    if(saleInfo.buytype){
console.log("if");
        var data =  saleContact.methods.contribute(amount.toString(),referrer).send({ from: account,value: amount.toString()})
    }
    else {
        console.log("else");   
        var data =  saleContact.methods.contribute(amount.toString(),referrer).send({ from: account})
    }
    // const data = web3.eth.sendTransaction({ from: account, to: saleAddress, value: amount.toString() })
    
    await toast.promise(data, {
        loading: 'Making a Buy Token Request...',
        success: 'Bougt Token Successfully',
        error: 'Error ! When Buying Token',
    }, {
     position:position.position,
     style:style,
     iconTheme: iconTheme
    }
    );
if(data){

        const data = {


  parantaddress :referrer,
  saleaddress :saleAddress,
  useraddress : account,
  TokenAddress:tokenaddress,
  tokensymbol : tokensymbol,
  buyAmount : parseFloat(useramount),
  link :  `${frontendurl}/launchpaddetail/${saleAddress}/${referrer}`,
  rewardAmount : parseFloat(rewardAmount),
 }

var buy = await UseAddReferrer(data);
   
    
}

    let wallet = getAccount();
    if(wallet){
        wallet = wallet.toString();
        wallet = wallet.toLowerCase();
        console.log("wallet" , wallet);
        let payload = {
            walletaddress :wallet,
            saleaddress : saleAddress,
            amount : useramount
        }
        let usercontribute = await userinvestedhook(payload);
    }
}

export const claimToken = async(saleAddress,account) => {
    const saleContact = await UseSale(saleAddress);
    const data = saleContact.methods.claimTokens().send({ from: account });
    await toast.promise(data, {
        loading: 'Requesting for Claim Tokens...',
        success: 'Tokens Claimed Successfully',
        error: 'Error ! When Claiming Token',
    }, {
        position:position.position,
        style:style,
        iconTheme: iconTheme
    }
    );
}




