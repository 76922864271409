import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";

import Header from '../Header';
import Sidebar from '../Sidebar';
import favicon from "../../images/Bitdeal/logos.png";
import { Container, ProgressBar, InputGroup, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import Pagination from '@mui/material/Pagination';
import AirdroplistModal from "../AirdroplistModal"
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css'
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import { GetAirdropdetail, UseTokenInfo, ClaimAirdrop, AirdropClaimedUsers, Claimstatus } from '../../hooks/useContract';

const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
        return <div className='cout_man_div'>
            <div>{days}d </div>
            <div>{hours}h</div>
            <div>{minutes}m</div>
            <div>{seconds}s </div>
        </div>
    } else {
        // Render a countdown
        return <div className='cout_man_div'>
            {/* <span>{days}<span>Days</span> </span>
                <span>{hours}<span>Hours</span></span>
                <span>{minutes}<span>Minuits</span></span>   
                <span>{seconds}<span>Seconds</span> </span>    */}
            <div>{days}d </div>
            <div>{hours}h</div>
            <div>{minutes}m</div>
            <div>{seconds}s </div>
        </div>;
    }
}

class AirdropDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            airdropModal: false,
            airdropdata: {},
            tokeninfo: {},
            userclaimstatus: null,
            claimedusers: [],
            claimedtabel: [],
            social: []
        };
    }

    componentDidMount() {
        this.getairdropdetail();
    }

    async getairdropdetail() {
        var proxyaddress = window.location.pathname.split('/')[2];
        var result = await GetAirdropdetail(proxyaddress);
        console.log("result in airdropdetail", result?.social.length);
        let tokendata = await UseTokenInfo(result?.token);
        console.log("tokendata1", tokendata);
        this.setState({ tokeninfo: tokendata, "airdropdata": result , social:result?.social });
        let claimedarray = await AirdropClaimedUsers(proxyaddress);
        let arraydata = []
        claimedarray.map((data, i) => {
            let refdata = { tokenaddress: <a href='/' target='_blank'>{data} </a>, tokenvalue: <p className='mb-0'>{result?.amountPerHead / 10 ** 18} {tokendata?.symbol}</p> };
            arraydata.push(refdata);
        })
        let status = await Claimstatus(proxyaddress);
        this.setState({ "userclaimstatus": status, "claimedusers": arraydata });
    }

    async claim() {
        var address = window.location.pathname.split('/')[2]
        var claimed = await ClaimAirdrop(address);
        window.location.reload();
    }

    render() {
        const { saleInfo, airdropModal } = this.state
        const now = 60;

        const columns = [
            { dataField: "tokenaddress", text: "" },
            { dataField: "tokenvalue", text: "" },
        ]
        const data = [
            { tokenaddress: <a href='/' target='_blank'>0xD1d0434F3a3D6b846717eC68162A94B25e6BC6e3 </a>, tokenvalue: <p className='mb-0'>3 SOFT</p>, },
            { tokenaddress: <a href='/' target='_blank'>0xD1d0434F3a3D6b846717eC68162A94B25e6BC6e3 </a>, tokenvalue: <p className='mb-0'>3 SOFT</p>, },
            { tokenaddress: <a href='/' target='_blank'>0xD1d0434F3a3D6b846717eC68162A94B25e6BC6e3 </a>, tokenvalue: <p className='mb-0'>3 SOFT</p>, },
            { tokenaddress: <a href='/' target='_blank'>0xD1d0434F3a3D6b846717eC68162A94B25e6BC6e3 </a>, tokenvalue: <p className='mb-0'>3 SOFT</p>, },
            { tokenaddress: <a href='/' target='_blank'>0xD1d0434F3a3D6b846717eC68162A94B25e6BC6e3 </a>, tokenvalue: <p className='mb-0'>3 SOFT</p>, },
            { tokenaddress: <a href='/' target='_blank'>0xD1d0434F3a3D6b846717eC68162A94B25e6BC6e3 </a>, tokenvalue: <p className='mb-0'>3 SOFT</p>, },
            { tokenaddress: <a href='/' target='_blank'>0xD1d0434F3a3D6b846717eC68162A94B25e6BC6e3 </a>, tokenvalue: <p className='mb-0'>3 SOFT</p>, },
            { tokenaddress: <a href='/' target='_blank'>0xD1d0434F3a3D6b846717eC68162A94B25e6BC6e3 </a>, tokenvalue: <p className='mb-0'>3 SOFT</p>, },
            { tokenaddress: <a href='/' target='_blank'>0xD1d0434F3a3D6b846717eC68162A94B25e6BC6e3 </a>, tokenvalue: <p className='mb-0'>3 SOFT</p>, },
            { tokenaddress: <a href='/' target='_blank'>0xD1d0434F3a3D6b846717eC68162A94B25e6BC6e3 </a>, tokenvalue: <p className='mb-0'>3 SOFT</p>, },
            { tokenaddress: <a href='/' target='_blank'>0xD1d0434F3a3D6b846717eC68162A94B25e6BC6e3 </a>, tokenvalue: <p className='mb-0'>3 SOFT</p>, },
            { tokenaddress: <a href='/' target='_blank'>0xD1d0434F3a3D6b846717eC68162A94B25e6BC6e3 </a>, tokenvalue: <p className='mb-0'>3 SOFT</p>, },
        ]
        const pagination = paginationFactory({
            page: 1,
            sizePerPage: 4,
            lastPageText: ">>",
            firstPageText: "<<",
            nextPageText: ">",
            prePageText: "<",
            showTotal: true,
            alwaysShowAllBtns: true,
            onPageChange: function (page, sizePerPage) {
                console.log('page', page);
                console.log('sizePerPage', sizePerPage)
            },
            onSizePerPageChange: function (page, sizePerPage) {
                console.log('page', page);
                console.log('sizePerPage', sizePerPage)
            },

        });

        return (
            <>
                {/* <div id="loader_main"> */}
                {/* <div id="loader_div">
                        <span className="spin_round">

                        </span>
                        <img src={favicon} className="logo_load" />
                    </div> */}
                <div className='de logo_overlay' id="logo_overlay">
                    <Header />
                    <div className="whole_sec pb-5">
                        <div className='flex_side_right'>
                            <Sidebar />
                            <div className='right_side_sec'>
                                <div className="right_side_spacing">
                                    <Container className='px-1'>
                                        <div className='row mt-5'>
                                            <div className='col-12 col-md-8 col-lg-8 mb-4'>
                                                <div className='card_bg card h-100'>
                                                    <div className='card-body'>
                                                        <div className='row align-items-center'>
                                                            <div className='col-12 col-sm-12 col-md-12 col-xl-8 mb-3 mb-md-0'>
                                                                <div className='d-flex align-items-center fklex_dircol flex_mob_blk_div align_col_bkk'>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className='profimg'>
                                                                            <img src={this?.state?.social && this?.state?.social[6]} className='img_ind_circle' />
                                                                        </div>{console.log("this?.state?.airdropdata" ,this?.state?.social ,(this?.state?.social).length)}
                                                                        <span className='sale_deta_name'>
                                                                            {this?.state?.social && this?.state?.social[0]}
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <div className='flex_badges_dv mt-2'>

                                                                            {/* { saleInfo?.owner == localStorage.getItem("accountInfo") ?    
                   <Link to={`/launchpadedit/${this.state.saleAddress}`}  className='btn_social_new btn_social_new_link ml-2'>
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                        </Link> : <></>} */}

                                                                            <a target="_blank" href={this?.state?.social[2]} className='btn_social_new btn_social_new_a_new ml-1'>
                                                                                <i class="fa fa-globe" aria-hidden="true"></i>
                                                                            </a>

                                                                            <a target="_blank" href={this?.state?.social[3]} className='btn_social_new btn_social_new_a_new ml-1'>
                                                                                <i class="fa fa-twitter" aria-hidden="true"></i>
                                                                            </a>

                                                                            <a target="_blank" href={this?.state?.social[4]} className='btn_social_new btn_social_new_a_new ml-1'>
                                                                                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                                                            </a>

                                                                            <a target="_blank" href={this?.state?.social[10]} className='btn_social_new btn_social_new_a_new ml-1'>
                                                                                <i class="fa fa-instagram" aria-hidden="true"></i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className='col-12 col-sm-12 col-md-12 col-xl-4 mb-3 mb-md-0'>
                                                                <p className='text-right-res-new'>
                                                                    <span className="badge badge-green-rect mt-0">
                                                                        <span className='blk_txt'>Live</span>
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>

                                                        {/* <p className='airno'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p> */}
                                                        <div className='airlists listed'>
                                                            <ul>
                                                                <li className='d-flex justify-content-between'>
                                                                    <p>Airdrop Address :</p>  <a href='/' target='_blank'>{this?.state?.airdropdata?.airdropaddress}<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg></a>
                                                                </li>
                                                                <li className='d-flex justify-content-between'>
                                                                    <p>Token Address :</p>  <a href='/' target='_blank'>{this?.state?.airdropdata?.token} <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg></a>
                                                                </li>
                                                                <li className='d-flex justify-content-between'>
                                                                    <p>Name :</p>  <p>{this?.state?.tokeninfo?.name}</p>
                                                                </li>
                                                                <li className='d-flex justify-content-between'>
                                                                    <p>Symbol :</p>  <p>{this?.state?.tokeninfo?.symbol}</p>
                                                                </li>
                                                                <li className='d-flex justify-content-between'>
                                                                    <p>decimal :</p>  <p>{this?.state?.tokeninfo?.decimals}</p>
                                                                </li>
                                                                <li className='d-flex justify-content-between'>
                                                                    <p>Total Tokens :</p>  <p>{(this?.state?.airdropdata?.totalInvested) / 10 ** 18} {this?.state?.tokeninfo?.symbol}</p>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-12 col-md-4 col-lg-4 mb-4'>
                                                <div className='card_bg card h-100'>
                                                    <div className='card-body'>
                                                        <p class="mb-2 airno text-center">Airdrop is live now</p>
                                                        {/* <div className='countdown_new text-center mb-3'>
                                                            <Countdown date={Date.now()} renderer={renderer} className="countdown_grey" />
                                                        </div> */}
                                                        {console.log("progressbar datasss" , parseFloat(this.state.airdropdata.amountPerHead)/ 10 ** 18 , parseFloat(this.state.claimedusers.length) , parseFloat(this?.state?.airdropdata?.totalInvested) / 10 ** 18)}
                                                        {console.log("progressbra data" , (((parseFloat(this.state.airdropdata.amountPerHead)/ 10 ** 18) * parseFloat(this.state.claimedusers.length))*100)/parseFloat(this?.state?.airdropdata?.totalInvested) / 10 ** 18)}
                                                        <ProgressBar  now={((parseFloat(this.state.airdropdata.amountPerHead)/ 10 ** 18)*parseFloat(this.state.claimedusers.length)*100)/(parseFloat(this?.state?.airdropdata?.totalInvested) / 10 ** 18)} label={((parseFloat(this.state.airdropdata.amountPerHead)/ 10 ** 18)*parseFloat(this.state.claimedusers.length)*100)/(parseFloat(this?.state?.airdropdata?.totalInvested) / 10 ** 18)} className='yellow_bar mt-3' />
                                                        <p className='white_txt_sm d-flex justify-content-between mt-1'>
                                                            <span className='desc_grey_txt'>{((this?.state?.airdropdata?.amountPerHead) / 10 ** 18) * this?.state?.claimedusers?.length} {this?.state?.tokeninfo?.symbol} </span>
                                                            <span className='desc_grey_txt'>{(this?.state?.airdropdata?.totalInvested) / 10 ** 18} {this?.state?.tokeninfo?.symbol}</span>
                                                        </p>
                                                        <div className='airlists mt-3'>
                                                            <ul>
                                                                {/* <li className='d-flex justify-content-between'>
                                                                    <p>Start Time :</p>  <p>03/06/2023 15:30 (UTC)</p>
                                                                </li> */}
                                                                <li className='d-flex justify-content-between'>
                                                                    <p>Your Allocation :</p>  <p>{(this?.state?.airdropdata?.amountPerHead) / 10 ** 18} {this?.state?.tokeninfo?.symbol}</p>
                                                                </li>
                                                                {this.state.userclaimstatus && <li className='d-flex justify-content-between'>
                                                                    <p>Your Claimed :</p>  <p>{(this?.state?.airdropdata?.amountPerHead) / 10 ** 18} {this?.state?.tokeninfo?.symbol}</p>
                                                                </li>}

                                                                {this.state.userclaimstatus ? <p>Already claimed!</p> : 
                                                                <>
                                                                <b>Claim your rewards!</b>
                                                                <div className='text-center mt-3'><button className='get-started-btn link_wallet_new dex_btn_svg' onClick={() => this.claim()}>Claim Rewards</button></div></>}

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-12 mb-4 tabless'>
                                                <div className='card_bg card h-100'>
                                                    <div className='card-body'>
                                                        <h5 className='airno allocation mb-3'>Allocations({this?.state?.claimedusers?.length})</h5>
                                                        {this?.state?.claimedusers?.length > 0 ?
                                                        <BootstrapTable bootstrap4 keyField='id' columns={columns} data={this?.state?.claimedusers} pagination={pagination} />:<p className='text-white text-center'>No Data Found</p>}
                                                    </div>
                                                </div>
                                            </div>


                                            {/* <div className='col-12 col-md-8 col-lg-8 mb-4'>
                                                <div className='card_bg card h-100'>
                                                    <div className='card-body'>
                                                        <h5 className='airno'>Allocations(174)</h5>
                                                        <div className='airlists mt-3'>
                                                            <ul>
                                                                <li className='d-flex justify-content-between'>
                                                                    <a href='/' target='_blank'>0xD1d0434F3a3D6b846717eC68162A94B25e6BC6e3 </a>  <p>3 SOFT</p>
                                                                </li>
                                                                <li className='d-flex justify-content-between'>
                                                                    <a href='/' target='_blank'>0xD1d0434F3a3D6b846717eC68162A94B25e6BC6e3 </a>  <p>13 SOFT</p>
                                                                </li>
                                                                <li className='d-flex justify-content-between'>
                                                                    <a href='/' target='_blank'>0xD1d0434F3a3D6b846717eC68162A94B25e6BC6e3 </a>  <p>8 SOFT</p>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                          
                                           

                                        </div>
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                {airdropModal && <AirdroplistModal onDismiss={() => this.setState({ airdropModal: false })} />}
            </>
        )
    }
}

export default AirdropDetails;