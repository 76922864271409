import axios from "axios";
import * as api from "../routes/userroutefront";
import { API_URL } from "../config/env";
import { addnewSale, addReferrer ,GetReferrer, GetsaleInfo} from "../routes/adminroutesfront";




export const UseAddReferrer = async(param) => {

    console.log("log>>>>>>>>>>>",param);

    try{
      console.log("try");
      const data = await  axios.post(API_URL + addReferrer, param);

      console.log("datadatadata",data);

      if(data){
        return data;
      }
    }
   catch (e) {
        console.log("error", e);
    }
    
};

export const UseUserReferrerData = async(useraddress) => {
  console.log("call");
   
  const tokeninfo =  await   axios.get(`${API_URL + GetReferrer}/${useraddress}`)
        return tokeninfo.data;
}




export const UseAddSale = async(param) => {

  console.log("log>>>>>>>>>>>",param);

  try{
    console.log("try");
    const data = await  axios.post(API_URL + addnewSale, param);

    console.log("datadatadata",data);

    if(data){
      return data;
    }
  }
 catch (e) {
      console.log("error", e);
  }
  
};


export const UseUserSaleInfo = async(useraddress,chainid) => {
  console.log("call");
   
  const tokeninfo =  await   axios.get(`${API_URL + GetsaleInfo}/${useraddress}/${chainid}`)
        return tokeninfo.data;
}