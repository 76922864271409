import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import isEmpty from "is-empty";

import Validator from "validator";
import Header from '../Header';
import Sidebar from '../Sidebar';
import Trendingslider from '../trendingslider';
import Walletmodal from "../Walletmodal";
import folderimage from "../../images/folder_zip.png";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import { getAccount } from '../../hooks/useAccount'
import { Container, FormControl, InputGroup } from 'react-bootstrap';
import '../../css/styles.css';

import favicon from "../../images/Bitdeal/logo_curve.png"
import proof from "../../images/proof.webp"
import { Link } from 'react-router-dom';
import { kycvalid ,KYCSubmit} from '../../hooks/kycvalidation';
import { getkychook, uploadkychook } from '../../hooks/usebackend';
import { API_URL } from '../../config/env';
// import Walletmodal from "../Walletmodal";
// import *as valid from '../../hooks/kycvalidation';

import bgstyle2 from "../../images/bg_style2.png";

import bgstyle from "../../images/bg_style.png";

import bgoutline1 from "../../images/bg_outline1.png";
import whiteoutline1 from "../../images/outline-white1.png";

import bgoutline from "../../images/bg_outline.png";
import whiteoutline from "../../images/outline-white.png";
import bgoutline2 from "../../images/bg_outline2.png";
import whiteoutline2 from "../../images/outline-white2.png";
class Kyc extends Component {
  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");


  }

  hideLoader() {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");



  }

  componentDidMount() {
    // this.showLoader();
    this.hideLoader();
    this.handlegetkyc()
    //    this.setState({ accountInfo: getAccount() });

    var account = getAccount();
    if (!account) {
      // console.log("sessionStorage.getItem" , kyc)
      this.props.history.push("/");
    }
  }


  async fetchdata1() {
    if (!await window?.ethereum?._metamask.isUnlocked() || !localStorage.getItem("accountInfo") || !sessionStorage.getItem("accountInfo")) {
      this.setState({ walletModal: true })
    }

  }

  constructor(props) {
    super(props);
    this.state = {
      walletModal: false,
      accountInfo: localStorage.getItem("accountInfo"),
      front: '',
      // back : '',
      idtype: '',
      idnumber: '',

      // frontview : '',
      // backview : '',
      // address : '',
      identitytype: '',
      emailid: '',
      city: '',
      region: '',
      country: '',
      zipcode: '',
      contactnumber: '',
      businessOwnerName: '',
      surname: '',
      businessname: '',
      companynumber: '',
      registrationdate: '',
      description: '',
      websiteurl: '',
      businessemail: '',
      businessaddress: '',
      businesscontactnumber: '',
      businesscity: '',
      businessregion: '',
      businesscountry: '',
      businesszipcode: '',
      ceoname: '',
      organizationname: '',
      nonprofitregistrationdate: '',
      nonprofitorganizationaddress: '',
      nonprofitcity: '',
      nonprofitregion: '',
      nonprofitcountry: '',
      nonprofitzipcode: '',
      nonprofitemailid: '',
      nonprofitcontactnumber: '',
      nonprofitwebsiteurl: '',
      kyc: {},
      errors: {},
    //   type:{
    //   individual:{
       
    //   },
    //   Company:{

    //   },
    //   Nonprofit:{

    //   }
    // }
    };
  }

  // componentDidUpdate(){
  //   console.log("data");
  //   this.handlegetkyc()
  // }

  empty =  (value)=>{
    console.log(value,"value")
    if(value ==="Individual"){
      console.log("Individual")
      this.setState({
        businessOwnerName: '',
        surname: '',
        businessname:'',
        companynumber: '',
        registrationdate:'',
        description: '',
        websiteurl: '',
        businessemail: '',
        businessaddress:'' ,
        businesscontactnumber: '',
        businesscity: '',
        businessregion: '',
        businesscountry: '',
        businesszipcode:'',

        ceoname:'',
        organizationname: '',
        nonprofitregistrationdate:'' ,
        nonprofitorganizationaddress:'',
        nonprofitcity:'',
        nonprofitregion:'' ,
        nonprofitcountry:'' ,
        nonprofitzipcode:'',
        nonprofitemailid: '',
        nonprofitcontactnumber:'' ,
        nonprofitwebsiteurl: '',
        idtype:'',
        idnumber:'',
        front: '',
      })

    }else if(value === "Company"){
      console.log("Company")
      this.setState({
        emailid:'',
        city:'',
        region:'',
        country: '',
        zipcode: '',
        contactnumber: '',

        ceoname:'',
        organizationname: '',
        nonprofitregistrationdate:'' ,
        nonprofitorganizationaddress:'',
        nonprofitcity:'',
        nonprofitregion:'' ,
        nonprofitcountry:'' ,
        nonprofitzipcode:'',
        nonprofitemailid: '',
        nonprofitcontactnumber:'' ,
        nonprofitwebsiteurl: '',
        idtype:'',
        idnumber:'',
        front: '',

    })
    }else{
      console.log("profit")
      this.setState({
        emailid:'',
        city:'',
        region:'',
        country: '',
        zipcode: '',
        contactnumber: '',

        businessOwnerName: '',
        surname: '',
        businessname:'',
        companynumber: '',
        registrationdate:'',
        description: '',
        websiteurl: '',
        businessemail: '',
        businessaddress:'' ,
        businesscontactnumber: '',
        businesscity: '',
        businessregion: '',
        businesscountry: '',
        businesszipcode:'',
        idtype: '',
        idnumber: '',
        front: ''
    })
    }
  }

  handlegetkyc = async () => {
    if (isEmpty(this.state.kyc)) {
      let walletaddress = localStorage.getItem("accountInfo") ? localStorage.getItem("accountInfo") : "";
      let result = await getkychook(walletaddress.toLowerCase());
      console.log("result", result?.data?.data);
      if (result?.data?.data?.record?.status == 'Approved' || result?.data?.data?.record?.status == "Pending") {
        this.setState({ kyc: result?.data?.data?.record })
      }
    }
  }

  onChangeError = async (e,value)=>{
    var payload
    // if (this.state.identitytype == "Individual") {
    //   payload = {
    //     emailid: this.state.emailid,
    //     city: this.state.city,
    //     region: this.state.region,
    //     country: this.state.country,
    //     zipcode: this.state.zipcode,
    //     contactnumber: this.state.contactnumber,
    //     walletaddress: localStorage.getItem("accountInfo").toLowerCase(),
    //     idtype: this.state.idtype,
    //     idnumber: this.state.idnumber,
    //     front: this.state.front,
    //     identitytype: this.state.identitytype
    //   }
    // }
    // else if (this.state.identitytype == "Company") {
    //   payload = {
    //     businessOwnerName: this.state.businessOwnerName,
    //     surname: this.state.surname,
    //     businessname: this.state.businessname,
    //     companynumber: this.state.companynumber,
    //     registrationdate: this.state.registrationdate,
    //     description: this.state.description,
    //     websiteurl: this.state.websiteurl,
    //     businessemail: this.state.businessemail,
    //     businessaddress: this.state.businessaddress,
    //     businesscontactnumber: this.state.businesscontactnumber,
    //     businesscity: this.state.businesscity,
    //     businessregion: this.state.businessregion,
    //     businesscountry: this.state.businesscountry,
    //     businesszipcode: this.state.businesszipcode,
    //     walletaddress: localStorage.getItem("accountInfo").toLowerCase(),
    //     idtype: this.state.idtype,
    //     idnumber: this.state.idnumber,
    //     front: this.state.front,
    //     identitytype: this.state.identitytype
    //   }
    // }
    // else {
    //   payload = {
    //     ceoname: this.state.ceoname,
    //     organizationname: this.state.organizationname,
    //     nonprofitregistrationdate: this.state.nonprofitregistrationdate,
    //     nonprofitorganizationaddress: this.state.nonprofitorganizationaddress,
    //     nonprofitcity: this.state.nonprofitcity,
    //     nonprofitregion: this.state.nonprofitregion,
    //     nonprofitcountry: this.state.nonprofitcountry,
    //     nonprofitzipcode: this.state.nonprofitzipcode,
    //     nonprofitemailid: this.state.nonprofitemailid,
    //     nonprofitcontactnumber: this.state.nonprofitcontactnumber,
    //     nonprofitwebsiteurl: this.state.nonprofitwebsiteurl,
    //     walletaddress: localStorage.getItem("accountInfo").toLowerCase(),
    //     idtype: this.state.idtype,
    //     idnumber: this.state.idnumber,
    //     front: this.state.front,
    //     identitytype: this.state.identitytype
    //   }
    // }
    var validate = await kycvalid(value,e,this.state.identitytype);
    var errors = this.state.errors
    errors = {...errors,...{[e]:validate.errors[`${e}`]}}
    this.setState({'errors':errors})
    
    console.log("validate_error",validate.errors[`${e}`],e,{...this.state.errors,...{[e]:validate.errors[`${e}`]}})
  }


  handlesubmit = async () => {
    console.log("kyc")
    var payload

    // let payload = {
    if (this.state.identitytype == "Individual") {
      payload = {
        emailid: this.state.emailid,
        city: this.state.city,
        region: this.state.region,
        country: this.state.country,
        zipcode: this.state.zipcode,
        contactnumber: this.state.contactnumber,
        walletaddress: localStorage.getItem("accountInfo").toLowerCase(),
        idtype: this.state.idtype,
        idnumber: this.state.idnumber,
        front: this.state.front,
        identitytype: this.state.identitytype
      }
    }
    else if (this.state.identitytype == "Company") {
      payload = {
        businessOwnerName: this.state.businessOwnerName,
        surname: this.state.surname,
        businessname: this.state.businessname,
        companynumber: this.state.companynumber,
        registrationdate: this.state.registrationdate,
        description: this.state.description,
        websiteurl: this.state.websiteurl,
        businessemail: this.state.businessemail,
        businessaddress: this.state.businessaddress,
        businesscontactnumber: this.state.businesscontactnumber,
        businesscity: this.state.businesscity,
        businessregion: this.state.businessregion,
        businesscountry: this.state.businesscountry,
        businesszipcode: this.state.businesszipcode,
        walletaddress: localStorage.getItem("accountInfo").toLowerCase(),
        idtype: this.state.idtype,
        idnumber: this.state.idnumber,
        front: this.state.front,
        identitytype: this.state.identitytype
      }
    }
    else {
      payload = {
        ceoname: this.state.ceoname,
        organizationname: this.state.organizationname,
        nonprofitregistrationdate: this.state.nonprofitregistrationdate,
        nonprofitorganizationaddress: this.state.nonprofitorganizationaddress,
        nonprofitcity: this.state.nonprofitcity,
        nonprofitregion: this.state.nonprofitregion,
        nonprofitcountry: this.state.nonprofitcountry,
        nonprofitzipcode: this.state.nonprofitzipcode,
        nonprofitemailid: this.state.nonprofitemailid,
        nonprofitcontactnumber: this.state.nonprofitcontactnumber,
        nonprofitwebsiteurl: this.state.nonprofitwebsiteurl,
        walletaddress: localStorage.getItem("accountInfo").toLowerCase(),
        idtype: this.state.idtype,
        idnumber: this.state.idnumber,
        front: this.state.front,
        identitytype: this.state.identitytype
      }
    }
    // }
    var validate = await KYCSubmit(payload);
    console.log("validate", validate)
    if (validate.isValid) {
      this.showLoader();
      let result = await uploadkychook(payload)
      console.log("result", result)
      this.handlegetkyc()
      this.hideLoader()
    }
    else {
      this.setState({ errors: validate.errors })
      console.log("error", validate.errors);
    }
  }


  render() {




    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round">

          </span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className='logo_overlay' id="logo_overlay">

          <Header />

          <div className="whole_sec pb-5">
            <div className='flex_side_right'>
              <Sidebar />
              {/* ongoing_sec */}
              <div className='right_side_sec'>
                {/* <div className="text-white topBar d-flex justify-content-between">
                  
                  <small>Trending</small>
                <small>
                  #1&nbsp;<span>Husy</span>
                </small>
                <small>
                  #2&nbsp;<span>ORL</span>
                </small>
                <small>
                  #3&nbsp;<span>UWC</span>
                </small>
                <small>
                  #4&nbsp;<span>777</span>
                </small>
                <small>
                  #5&nbsp;<span>IDXS</span>
                </small>
                <small>
                  #6&nbsp;<span>COUGNU</span>
                </small>
                <small>
                  #7&nbsp;<span>CRICLE</span>
                </small>
                <small>
                  #8&nbsp;<span>KASA</span>
                </small>
                <small>
                  #9&nbsp;<span>FIFAPP</span>
                </small>
                <small>
                  #10&nbsp;<span>SOG</span>
                </small>
                <small>
                  #11&nbsp;<span>COOSHA</span>
                </small>
                <small>
                  #12&nbsp;<span>Honey</span>
                </small>
                
                
              </div> */}
                {/* <Trendingslider/> */}
                <div className="right_side_spacing kyc_sec">
                  <div className="bg_outline1">
                    {/* <img src={bgoutline1} className="out_dark"/>
                    <img src={whiteoutline1} className="out_light" /> */}
                  </div>
                  <div className='row mt-5 row_height_kyc'>
                    <div className='col-12 col-md-10 col-lg-9 mx-auto'>
                      <div className="tab_img">
                        <div className='card_bg card'>
                          <div className='card-body'>



                            <div>
                              <div className='row'>
                                {isEmpty(this.state.kyc) && <>
                                  <div className='col-12 col-md-12 mb-3'>

                                    <p className='input_desc_sm'>1. Proof of Identity</p>
                                    <p className='desc_grey_txt'>Please upload a clear, readable copy of any of the following:</p>

                                    <div className='note_desc mt-1 mb-0'>
                                      <p>Document must be a supported  extension .zip</p>
                                    </div>
                                  </div>

                                  <div className='col-12 col-md-12 mb-3'>

                                    <p className='input_desc_sm'>Select Identity Type</p>
                                    <div className="inputs input-groups">
                                      <select className="form-control custm_sel" id="exampleFormControlSelectidentity"
                                        value={this.state.identitytype}
                                        onChange={(e) => {
                                          this.empty(e.target.value)
                                          this.setState({ 'identitytype': e.target.value });
                                          
                                          // this.setState({'kyc':{}})
                                        }}
                                      >
                                        <option value='' disabled>Select Identity Type</option>
                                        <option value='Individual'>Individual</option>
                                        <option value='Company'>Company</option>
                                        <option value='Nonprofit Organization'>Nonprofit Organization</option>

                                      </select>
                                      <span className="text-danger">{this.state.errors.identitytype}</span>
                                    </div>
                                  </div>

                                  {
                                    this.state.identitytype == "Individual" ?

                                      <>
                                        <div className='col-12 col-md-12 mb-3'>
                                          <p className='input_desc_sm'>Email ID</p>
                                          <div className="inputs input-groups">
                                            <InputGroup className="">
                                              <FormControl id="emailid" placeholder=""
                                                aria-describedby="basic-addonemail" type="email"
                                                onChange={(e) => { this.setState({ emailid: e.target.value });this.onChangeError(e.target.id,e.target.value) }}
                                               value={this.state.emailid}
                                               maxlength="30"
                                              />

                                            </InputGroup>
                                            <span className="text-danger">{this.state.errors.emailid}</span>
                                          </div>
                                        </div>

                                        <div className='col-12 col-md-12 mb-3'>
                                          <p className='input_desc_sm'>City</p>
                                          <div className="inputs input-groups">
                                            <InputGroup className="">
                                              <FormControl id="city" placeholder=""
                                                aria-describedby="basic-addoncity" type="text"
                                                onChange={(e) => { this.setState({ city: e.target.value });this.onChangeError(e.target.id,e.target.value)  }}
                                                value={this.state.city}
                                                maxlength="30"
                                                
                                              />

                                            </InputGroup>
                                            <span className="text-danger">{this.state.errors.city}</span>
                                          </div>
                                        </div>

                                        <div className='col-12 col-md-12 mb-3'>
                                          <p className='input_desc_sm'>Region</p>
                                          <div className="inputs input-groups">
                                            <InputGroup className="">
                                              <FormControl id="region" placeholder=""
                                                aria-describedby="basic-addonregion" type="text"
                                                onChange={(e) => { this.setState({ region: e.target.value }) ;this.onChangeError(e.target.id,e.target.value) }}
                                                value={this.state.region}
                                                maxlength="30"
                                              />

                                            </InputGroup>
                                            <span className="text-danger">{this.state.errors.region}</span>
                                          </div>
                                        </div>

                                        <div className='col-12 col-md-12 mb-3'>
                                          <p className='input_desc_sm'>Country</p>
                                          <div className="inputs input-groups">
                                            <InputGroup className="">
                                              <FormControl id="country" placeholder=""
                                                aria-describedby="basic-addoncountry" type="text"
                                                onChange={(e) => { this.setState({ country: e.target.value }) ;this.onChangeError(e.target.id,e.target.value) }}
                                                value={this.state.country}
                                                maxlength="30"
                                              />

                                            </InputGroup>
                                            <span className="text-danger">{this.state.errors.country}</span>
                                          </div>
                                        </div>

                                        <div className='col-12 col-md-12 mb-3'>
                                          <p className='input_desc_sm'>Zip Code</p>
                                          <div className="inputs input-groups">
                                            <InputGroup className="">
                                              <FormControl id="zipcode" placeholder=""
                                                aria-describedby="basic-addonzipcode" type="text"
                                                onChange={(e) => { this.setState({ zipcode: e.target.value }) ;this.onChangeError(e.target.id,e.target.value) }}
                                                value={this.state.zipcode}
                                                maxlength="30"
                                              />

                                            </InputGroup>
                                            <span className="text-danger">{this.state.errors.zipcode}</span>
                                          </div>
                                        </div>

                                        <div className='col-12 col-md-12 mb-3'>
                                          <p className='input_desc_sm'>Contact Number</p>
                                          <div className="inputs input-groups">
                                            <InputGroup className="">
                                              <FormControl id="contactnumber" placeholder=""
                                                aria-describedby="basic-addoncontactnumber" type="text"
                                                onChange={(e) => { this.setState({ contactnumber: e.target.value });this.onChangeError(e.target.id,e.target.value)  }}
                                                value={this.state.contactnumber}
                                                maxlength="15"
                                              />

                                            </InputGroup>
                                            <span className="text-danger">{this.state.errors.contactnumber}</span>
                                          </div>
                                        </div>

                                      </>
                                      :

                                      this.state.identitytype == "Company" ?
                                        <>
                                          <div className='col-12 col-md-12 mb-3'>
                                            <p className='input_desc_sm'>Business Owner Name*</p>
                                            <div className="inputs input-groups">
                                              <InputGroup className="">
                                                <FormControl id="businessOwnerName" placeholder=""
                                                  aria-describedby="basic-addonname" type="text"
                                                  onChange={(e) => { this.setState({ businessOwnerName: e.target.value }) ;this.onChangeError(e.target.id,e.target.value) }}
                                                  value={this.state.businessOwnerName}
                                                  maxlength="30"
                                                />

                                              </InputGroup>
                                              <span className="text-danger">{this.state.errors.businessOwnerName}</span>
                                            </div>
                                          </div>

                                          <div className='col-12 col-md-12 mb-3'>
                                            <p className='input_desc_sm'>Business Owner Surname*</p>
                                            <div className="inputs input-groups">
                                              <InputGroup className="">
                                                <FormControl id="surname" placeholder=""
                                                  aria-describedby="basic-addonsurname" type="text"
                                                  onChange={(e) => { this.setState({ surname: e.target.value }) ;this.onChangeError(e.target.id,e.target.value) }}
                                                  value={this.state.surname}
                                                  maxlength="30"
                                                />

                                              </InputGroup>
                                              <span className="text-danger">{this.state.errors.surname}</span>
                                            </div>
                                          </div>


                                          <div className='col-12 col-md-12 mb-3'>
                                            <p className='input_desc_sm'>Business Name*</p>
                                            <div className="inputs input-groups">
                                              <InputGroup className="">
                                                <FormControl id="businessname" placeholder=""
                                                  aria-describedby="basic-addonbusinessname" type="text"
                                                  onChange={(e) => { this.setState({ businessname: e.target.value }) ;this.onChangeError(e.target.id,e.target.value) }}
                                                  value={this.state.businessname}
                                                  maxlength="30"
                                                />

                                              </InputGroup>
                                              <span className="text-danger">{this.state.errors.businessname}</span>
                                            </div>
                                          </div>



                                          <div className='col-12 col-md-12 mb-3'>
                                            <p className='input_desc_sm'>Company Number*</p>
                                            <div className="inputs input-groups">
                                              <InputGroup className="">
                                                <FormControl id="companynumber" placeholder=""
                                                  aria-describedby="basic-addoncompanynumber" type="text"
                                                  onChange={(e) => { this.setState({ companynumber: e.target.value }) ;this.onChangeError(e.target.id,e.target.value) }}
                                                  value={this.state.companynumber}
                                                  maxlength="30"
                                                />

                                              </InputGroup>
                                              <span className="text-danger">{this.state.errors.companynumber}</span>
                                            </div>
                                          </div>
                                          <div className='col-12 col-md-12 mb-3'>
                                            <p className='input_desc_sm'>Registration Date*</p>
                                            <div className="inputs input-groups">
                                              <div className="inputs input-groups date_inoput_grps inut_grp_date_inpt_pad">
                                                <InputGroup className="datepicker_input">
                                                  <DatePicker
                                                  selected={this.state.registrationdate}
                                                  onChange={(date) => {
                                                      let newDate = new Date(date)
                                                      newDate.setMilliseconds(0)
                                                      // let formData = {
                                                      //     // ...this.state.formValue,
                                                      //     'registrationdate': newDate.getTime()
                                                      // };
                                                      this.onChangeError("registrationdate",(date).toString())

                                                      this.setState({
                                                        'registrationdate': newDate.getTime()
                                                       
                                                    });
                                                  }}
                                                  dateFormat="dd/MM/yyyy"
                                                  maxDate={new Date()}
                                                  onKeyDown={(e) => {
                                                      e.preventDefault();
                                                    }}
                                                    // minDate={new Date(Date.now() + (86400000 * 30))}
                                                    //   filterTime={this.filterPassedTime.bind(this)}
                                                    // disabledKeyboardNavigation
                                                    // showTimeSelect
                                                    // showYearDropdown
                                                    // showMonthDropdown
                                                    // selected={this.state.registrationdate }
                                                    // id="registrationdate"
                                                    // maxDate={new Date()}
                                                    // onChange={(data) => { console.log("date",data);this.setState({ registrationdate:data }) }}
                                                    // onKeyDown={e => e.preventDefault()}
                                                    // dateFormat="dd/MM/yyyy"
                                                    
                                                  />

                                                </InputGroup>
                                                <InputGroup.Append className='cur_pointer create_lock_date'>
                                                  <button variant="outline-secondary" className="trans_cal_btn">
                                                    <i class="far fa-calendar-alt"></i>
                                                  </button>
                                                </InputGroup.Append>
                                              </div>
                                              <span className="text-danger">{this.state.errors.registrationdate}</span>
                                            </div>
                                          </div>

                                          <div className='col-12 col-md-12 mb-3'>
                                            <p className='input_desc_sm'>Business Type</p>
                                            <div className="inputs input-groups text_are_grp text_area_norla_colr">
                                              <InputGroup className="">
                                                <textarea
                                                  id="description" rows="3"
                                                  aria-describedby="basic-addon2"
                                                  onChange={(e) => { this.setState({ description: e.target.value }) ;this.onChangeError(e.target.id,e.target.value)}}
                                                  value={this.state.description}
                                                  maxlength="30"
                                                />

                                              </InputGroup>
                                              <span className="text-danger">{this.state.errors.description}</span>
                                            </div>
                                          </div>

                                          <div className='col-12 col-md-12 mb-3'>
                                            <p className='input_desc_sm'>Website URL</p>
                                            <div className="inputs input-groups">
                                              <InputGroup className="">
                                                <FormControl id="websiteurl" placeholder=""
                                                  aria-describedby="basic-addonwebsiteurl" type="text"
                                                  onChange={(e) => { this.setState({ websiteurl: e.target.value }) ;this.onChangeError(e.target.id,e.target.value)}}
                                                  value={this.state.websiteurl}

                                                />

                                              </InputGroup>
                                              <span className="text-danger">{this.state.errors.websiteurl}</span>
                                            </div>
                                          </div>

                                          <div className='col-12 col-md-12 mb-3'>
                                            <p className='input_desc_sm'>Business Email*</p>
                                            <div className="inputs input-groups">
                                              <InputGroup className="">
                                                <FormControl id="businessemail" placeholder=""
                                                  aria-describedby="basic-addonbusinessemail" type="email"
                                                  onChange={(e) => { this.setState({ businessemail: e.target.value });this.onChangeError(e.target.id,e.target.value) }}
                                                  value={this.state.businessemail}
                                                  maxlength="30"
                                                />

                                              </InputGroup>
                                              <span className="text-danger">{this.state.errors.businessemail}</span>
                                            </div>
                                          </div>

                                          <div className='col-12 col-md-12 mb-3'>
                                            <p className='input_desc_sm'>Business Address*</p>
                                            <div className="inputs input-groups">
                                              <InputGroup className="">
                                                <FormControl id="businessaddress" placeholder=""
                                                  aria-describedby="basic-addonbusinessaddress" type="text"
                                                  onChange={(e) => { this.setState({ businessaddress: e.target.value }) ;this.onChangeError(e.target.id,e.target.value)}}
                                                  value={this.state.businessaddress}
                                                  maxlength="50"
                                                />

                                              </InputGroup>
                                              <span className="text-danger">{this.state.errors.businessaddress}</span>
                                            </div>
                                          </div>

                                          <div className='col-12 col-md-12 mb-3'>
                                            <p className='input_desc_sm'>Contact Number*</p>
                                            <div className="inputs input-groups">
                                              <InputGroup className="">
                                                <FormControl id="businesscontactnumber" placeholder=""
                                                  aria-describedby="basic-addonbusinesscontactnumber" type="text"
                                                  onChange={(e) => { this.setState({ businesscontactnumber: e.target.value });this.onChangeError(e.target.id,e.target.value) }}
                                                  value={this.state.businesscontactnumber}
                                                  maxlength="15"
                                                />

                                              </InputGroup>
                                              <span className="text-danger">{this.state.errors.businesscontactnumber}</span>
                                            </div>
                                          </div>

                                          <div className='col-12 col-md-12 mb-3'>
                                            <p className='input_desc_sm'>City</p>
                                            <div className="inputs input-groups">
                                              <InputGroup className="">
                                                <FormControl id="businesscity" placeholder=""
                                                  aria-describedby="basic-addonbusinesscity" type="text"
                                                  onChange={(e) => { this.setState({ businesscity: e.target.value }) ;this.onChangeError(e.target.id,e.target.value)}}
                                                  value={this.state.businesscity}
                                                  maxlength="30"
                                                />

                                              </InputGroup>
                                              <span className="text-danger">{this.state.errors.businesscity}</span>
                                            </div>
                                          </div>

                                          <div className='col-12 col-md-12 mb-3'>
                                            <p className='input_desc_sm'>Region</p>
                                            <div className="inputs input-groups">
                                              <InputGroup className="">
                                                <FormControl id="businessregion" placeholder=""
                                                  aria-describedby="basic-addonbusinessregion" type="text"
                                                  onChange={(e) => { this.setState({ businessregion: e.target.value }) ;this.onChangeError(e.target.id,e.target.value)}}
                                                  value={this.state.businessregion}
                                                  maxlength="30"
                                                />

                                              </InputGroup>
                                              <span className="text-danger">{this.state.errors.businessregion}</span>
                                            </div>
                                          </div>


                                          <div className='col-12 col-md-12 mb-3'>
                                            <p className='input_desc_sm'>Country</p>
                                            <div className="inputs input-groups">
                                              <InputGroup className="">
                                                <FormControl id="businesscountry" placeholder=""
                                                  aria-describedby="basic-addonbusinesscountry" type="text"
                                                  onChange={(e) => { this.setState({ businesscountry: e.target.value });this.onChangeError(e.target.id,e.target.value) }}
                                                  value={this.state.businesscountry}
                                                  maxlength="30"
                                                />

                                              </InputGroup>
                                              <span className="text-danger">{this.state.errors.businesscountry}</span>
                                            </div>
                                          </div>

                                          <div className='col-12 col-md-12 mb-3'>
                                            <p className='input_desc_sm'>Zip Code</p>
                                            <div className="inputs input-groups">
                                              <InputGroup className="">
                                                <FormControl id="businesszipcode" placeholder=""
                                                  aria-describedby="basic-addonbusinesszipcode" type="text"
                                                  onChange={(e) => { this.setState({ businesszipcode: e.target.value });this.onChangeError(e.target.id,e.target.value) }}
                                                  value={this.state.businesszipcode}
                                                  maxlength="30"
                                                />

                                              </InputGroup>
                                              <span className="text-danger">{this.state.errors.businesszipcode}</span>
                                            </div>
                                          </div>

                                        </>
                                        :
                                        this.state.identitytype == "Nonprofit Organization" ?
                                          <>
                                            <div className='col-12 col-md-12 mb-3'>
                                              <p className='input_desc_sm'>Name of CEO*</p>
                                              <div className="inputs input-groups">
                                                <InputGroup className="">
                                                  <FormControl id="ceoname" placeholder=""
                                                    aria-describedby="basic-addonceoname" type="text"
                                                    onChange={(e) => { this.setState({ ceoname: e.target.value }) ;this.onChangeError(e.target.id,e.target.value)}}
                                                    value={this.state.ceoname}
                                                    maxlength="30"
                                                  />

                                                </InputGroup>
                                                <span className="text-danger">{this.state.errors.ceoname}</span>
                                              </div>
                                            </div>

                                            <div className='col-12 col-md-12 mb-3'>
                                              <p className='input_desc_sm'>Organization Name*</p>
                                              <div className="inputs input-groups">
                                                <InputGroup className="">
                                                  <FormControl id="organizationname" placeholder=""
                                                    aria-describedby="basic-addonorganizationname" type="text"
                                                    onChange={(e) => { this.setState({ organizationname: e.target.value });this.onChangeError(e.target.id,e.target.value) }}
                                                    value={this.state.organizationname}
                                                    maxlength="30"
                                                  />

                                                </InputGroup>
                                                <span className="text-danger">{this.state.errors.organizationname}</span>
                                              </div>
                                            </div>


                                            <div className='col-12 col-md-12 mb-3'>
                                              <p className='input_desc_sm'>Registration Date*</p>
                                              <div className="inputs input-groups">
                                                <div className="inputs input-groups date_inoput_grps inut_grp_date_inpt_pad">
                                                  <InputGroup className="datepicker_input">
                                                    <DatePicker
                                                    selected={this.state.nonprofitregistrationdate}
                                                    onChange={(date) => {
                                                        let newDate = new Date(date)
                                                        newDate.setMilliseconds(0)
                                                        // let formData = {
                                                        //     // ...this.state.formValue,
                                                        //     'registrationdate': newDate.getTime()
                                                        // };
                                                        this.onChangeError("nonprofitregistrationdate",(date).toString())
                                                        this.setState({
                                                          'nonprofitregistrationdate': newDate.getTime()
                                                      });
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={new Date()}
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                      }}
                                                      // minDate={new Date(Date.now() + (86400000 * 30))}
                                                      //   filterTime={this.filterPassedTime.bind(this)}
                                                      // disabledKeyboardNavigation
                                                      // showTimeSelect
                                                      // selected={this.state.locktime }
                                                      // onChange={(date) => { this.setState({ nonprofitregistrationdate: date }) }}
                                                      // onKeyDown={e => e.preventDefault()}
                                                      // dateFormat="MMMM d, yyyy"
                                                    />

                                                  </InputGroup>
                                                  <InputGroup.Append className='cur_pointer create_lock_date'>
                                                    <button variant="outline-secondary" className="trans_cal_btn">
                                                      <i class="far fa-calendar-alt"></i>
                                                    </button>
                                                  </InputGroup.Append>
                                                </div>
                                                <span className="text-danger">{this.state.errors.nonprofitregistrationdate}</span>
                                              </div>
                                            </div>



                                            <div className='col-12 col-md-12 mb-3'>
                                              <p className='input_desc_sm'>Organization Address*</p>
                                              <div className="inputs input-groups">
                                                <InputGroup className="">
                                                  <FormControl id="nonprofitorganizationaddress" placeholder=""
                                                    aria-describedby="basic-addonnonprofitorganizationaddress" type="text"
                                                    onChange={(e) => { this.setState({ nonprofitorganizationaddress: e.target.value });this.onChangeError(e.target.id,e.target.value) }}
                                                    value={this.state.nonprofitorganizationaddress}
                                                    maxlength="50"
                                                  />

                                                </InputGroup>
                                                <span className="text-danger">{this.state.errors.nonprofitorganizationaddress}</span>
                                              </div>
                                            </div>




                                            <div className='col-12 col-md-12 mb-3'>
                                              <p className='input_desc_sm'>City*</p>
                                              <div className="inputs input-groups">
                                                <InputGroup className="">
                                                  <FormControl id="nonprofitcity" placeholder=""
                                                    aria-describedby="basic-addonnonprofitcity" type="text"
                                                    onChange={(e) => { this.setState({ nonprofitcity: e.target.value });this.onChangeError(e.target.id,e.target.value) }}
                                                    value={this.state.nonprofitcity}
                                                    maxlength="30"
                                                  />

                                                </InputGroup>
                                                <span className="text-danger">{this.state.errors.nonprofitcity}</span>
                                              </div>
                                            </div>

                                            <div className='col-12 col-md-12 mb-3'>
                                              <p className='input_desc_sm'>Region*</p>
                                              <div className="inputs input-groups">
                                                <InputGroup className="">
                                                  <FormControl id="nonprofitregion" placeholder=""
                                                    aria-describedby="basic-addonnonprofitregion" type="text"
                                                    onChange={(e) => { this.setState({ nonprofitregion: e.target.value });this.onChangeError(e.target.id,e.target.value) }}
                                                    value={this.state.nonprofitregion}
                                                    maxlength="30"
                                                  />

                                                </InputGroup>
                                                <span className="text-danger">{this.state.errors.nonprofitregion}</span>
                                              </div>
                                            </div>


                                            <div className='col-12 col-md-12 mb-3'>
                                              <p className='input_desc_sm'>Country*</p>
                                              <div className="inputs input-groups">
                                                <InputGroup className="">
                                                  <FormControl id="nonprofitcountry" placeholder=""
                                                    aria-describedby="basic-addonnonprofitcountry" type="text"
                                                    onChange={(e) => { this.setState({ nonprofitcountry: e.target.value });this.onChangeError(e.target.id,e.target.value) }}
                                                    value={this.state.nonprofitcountry}
                                                    maxlength="30"
                                                  />

                                                </InputGroup>
                                                <span className="text-danger">{this.state.errors.nonprofitcountry}</span>
                                              </div>
                                            </div>

                                            <div className='col-12 col-md-12 mb-3'>
                                              <p className='input_desc_sm'>Zip Code*</p>
                                              <div className="inputs input-groups">
                                                <InputGroup className="">
                                                  <FormControl id="nonprofitzipcode" placeholder=""
                                                    aria-describedby="basic-addonnonprofitzipcode" type="text"
                                                    onChange={(e) => { this.setState({ nonprofitzipcode: e.target.value }) ;this.onChangeError(e.target.id,e.target.value)}}
                                                    value={this.state.nonprofitzipcode}
                                                    maxlength="30"
                                                  />

                                                </InputGroup>
                                                <span className="text-danger">{this.state.errors.nonprofitzipcode}</span>
                                              </div>
                                            </div>

                                            <div className='col-12 col-md-12 mb-3'>
                                              <p className='input_desc_sm'>Email ID*</p>
                                              <div className="inputs input-groups">
                                                <InputGroup className="">
                                                  <FormControl id="nonprofitemailid" placeholder=""
                                                    aria-describedby="basic-addonnonprofitemailid" type="text"
                                                    onChange={(e) => { this.setState({ nonprofitemailid: e.target.value }) ;this.onChangeError(e.target.id,e.target.value)}}
                                                    value={this.state.nonprofitemailid}
                                                    maxlength="30"
                                                  />

                                                </InputGroup>
                                                <span className="text-danger">{this.state.errors.nonprofitemailid}</span>
                                              </div>
                                            </div>

                                            <div className='col-12 col-md-12 mb-3'>
                                              <p className='input_desc_sm'>Contact Number*</p>
                                              <div className="inputs input-groups">
                                                <InputGroup className="">
                                                  <FormControl id="nonprofitcontactnumber" placeholder=""
                                                    aria-describedby="basic-addonnonprofitcontactnumber" type="text"
                                                    onChange={(e) => { this.setState({ nonprofitcontactnumber: e.target.value });this.onChangeError(e.target.id,e.target.value) }}
                                                    value={this.state.nonprofitcontactnumber}
                                                    maxlength="15"
                                                  />

                                                </InputGroup>
                                                <span className="text-danger">{this.state.errors.nonprofitcontactnumber}</span>
                                              </div>
                                            </div>

                                            <div className='col-12 col-md-12 mb-3'>
                                              <p className='input_desc_sm'>Website URL</p>
                                              <div className="inputs input-groups">
                                                <InputGroup className="">
                                                  <FormControl id="nonprofitwebsiteurl" placeholder=""
                                                    aria-describedby="basic-addonnonprofitwebsiteurl" type="text"
                                                    onChange={(e) => { this.setState({ nonprofitwebsiteurl: e.target.value });this.onChangeError(e.target.id,e.target.value) }}
                                                    value={this.state.nonprofitwebsiteurl}
                                                  />

                                                </InputGroup>
                                                <span className="text-danger">{this.state.errors.nonprofitwebsiteurl}</span>
                                              </div>
                                            </div>




                                          </>
                                          :
                                          <></>

                                  }

                                  {/* end Individual */}

                                  {/* common fields */}
                                  <div className='col-12 col-md-12 mb-3'>

                                    <p className='input_desc_sm'>Select Document Type</p>
                                    <div className="inputs input-groups">
                                      <select className="form-control custm_sel" id="exampleFormControlSelect3"
                                        value={this.state.idtype}
                                        onChange={(e) => {
                                          this.setState({ 'idtype': e.target.value });this.onChangeError(e.target.id,e.target.value)
                                        }}
                                      >
                                        <option value=''>Select Document Type</option>
                                        <option value='Voter ID'>Personal ID</option>
                                        <option value='Aadhar Card'>Passport</option>
                                        <option value='Driving License'>Driving License</option>

                                      </select>
                                      <span className="text-danger">{this.state.errors.idtype}</span>
                                    </div>
                                  </div>

                                  <div className='col-12 col-md-12 mb-3'>
                                    <p className='input_desc_sm'>ID Number</p>
                                    <div className="inputs input-groups">
                                      <InputGroup className="">
                                        <FormControl id="idnumber" placeholder=""
                                          aria-describedby="basic-addon2"
                                          value={this?.state?.idnumber}
                                          onChange={(e) => {
                                            this.setState({ 'idnumber': e.target.value }, () => console.log("idtype", this.state.idnumber));this.onChangeError(e.target.id,e.target.value)
                                          }}
                                          maxlength="30"
                                        />

                                      </InputGroup>
                                      <span className="text-danger">{this.state.errors.idnumber}</span>
                                    </div>
                                  </div>

                                  {/* <div className='col-12 col-md-12 mb-3'>
                    <p className='input_desc_sm'>Address</p>
                    <div className="inputs input-groups">
                        <InputGroup className="">
                            <FormControl id="address" placeholder=""
                                aria-describedby="basic-addon2"
                                value={this.state.address}
                                onChange={(e)=>{
                                  this.setState({"address" : e.target.value});
                                }}
                            />
                          
                        </InputGroup>
                        <span className="text-danger">{this.state.errors.address}</span>
                    </div>
                </div> */}

                                  <div className='col-12 col-md-12 mb-3'>
                                    <p className='input_desc_sm'>Upload Document in zip file</p>
                                    <div className="inputs input-groups">
                                      <InputGroup className="file_grp_input"
                                        onChange={(e) => {
                                          this.setState({ 'front': e.target.files[0] });
                                          this.setState({ 'frontview': URL.createObjectURL(e.target.files[0]) });this.onChangeError(e.target.id,e.target.value)
                                        }}
                                      >
                                        <div className="w-100">
                                          <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" />
                                            <label className="custom-file-label mb-0" for="inputGroupFile04">
                                              {this.state.front ? this.state.front.name : "Choose file"}</label>
                                          </div>

                                        </div>

                                      </InputGroup>
                                      <span className="text-danger">{this.state.errors.front}</span>
                                    </div>
                                  </div>

                                  {/* end common fields */}
                                  {/* <div className='col-12 col-md-12 mb-3'>
                    <p className='input_desc_sm'>Back Side</p>
                    <div className="inputs input-groups">
                        <InputGroup className="file_grp_input"
                          onChange={(e)=>{
                            this.setState({'back' : e.target.files[0]});
                            this.setState({'backview' : URL.createObjectURL(e.target.files[0])})
                          }}
                        >
                        <div className="w-100">
                    <div className="custom-file">
                        <input type="file" className="custom-file-input" id="inputGroupFile05" aria-describedby="inputGroupFileAddon04" />
                        <label className="custom-file-label mb-0" for="inputGroupFile04">Choose file</label>
                    </div>
                    
                    </div>
                          
                        </InputGroup>
                        <span className="text-danger">{this.state.errors.back}</span>
                    </div>
                        </div> */}
                                  {/* <div className='col-12 col-sm-6 mb-3 mt-1'>
                        <p className='input_desc_sm'>Front Side Image</p>
                        <div className="icon_align_border">
                        <img src={this.state.frontview ? this.state.frontview : proof} className="img-fluid p-3" />
                        </div>
                        </div>
                        <div className='col-12 col-sm-6 mb-3 mt-1'>
                        <p className='input_desc_sm'>Back Side Image</p>
                        <div className="icon_align_border">
                        <img src={this.state.backview ? this.state.backview : proof} className="img-fluid p-3" />
                        </div>
                        </div> */}
                                </>     }
                                {this.state.kyc.status == "Pending" ?
                                  <div className='col-12'>
                                    <div className='kyc_status_div_final'>
                                      <p className='kyc_fianl_text1'>Thank you for the information provided!</p>
                                      <p className='kyc_fianl_text2'>The BITDEALZ IDO team is already verifying the information in order to validate your information.</p>
                                    </div>
                                  </div> : this.state.kyc.status == "Approved"?
                                  <div className='col-12'>
                                  <div className='kyc_status_div_final'>
                                    <p className='kyc_fianl_text1'>Thank you for the information provided!</p>
                                    <p className='kyc_fianl_text2'>Your KYC information successfully Approved!</p>
                                  </div>
                                </div>:""}
                                {/* <p>The trading contract becomes available in your trading account immediately after the data validation.</p> */}

                                {/* {!isEmpty(this.state.kyc) &&
                                  <div className='col-12'>
                                    <div className='kyc_status_div_final'>
                                      <p className='kyc_fianl_text1'>Thank you for the information provided!</p>
                                      <p className='kyc_fianl_text2'>Your KYC information successfully Approved!</p>
                                    </div>
                                  </div>} */}
                                {/* <>
                                  <div className='col-12 col-md-12 mb-3'>

                                    <p className='input_desc_sm'>1. Proof of identity</p>
                                    <p className='desc_grey_txt'>Please upload a clear, readable copy of any of the following:</p>

                                    <div className='note_desc mt-1 mb-0'>
                                      <p>Images must be a supported image extension .png, .jpg, .jpeg Max 3MB</p>
                                    </div>
                                  </div>
                                  <div className='col-12 col-md-12 mb-3'>

                                    <p className='input_desc_sm'>Select Document Type</p>
                                    <div className="inputs input-groups">
                                      <select className="form-control custm_sel" id="exampleFormControlSelect3"
                                        value={this.state.kyc.idtype}
                                        disabled="disabled"
                                      >
                                        <option>Voter ID</option>
                                        <option>Aadhar Card</option>
                                        <option>Driving License</option>

                                      </select>
                                    </div>
                                  </div>

                                  <div className='col-12 col-md-12 mb-3'>
                                    <p className='input_desc_sm'>ID Number</p>
                                    <div className="inputs input-groups">
                                      <InputGroup className="">
                                        <FormControl id="idnumber" placeholder=""
                                          aria-describedby="basic-addon2"
                                          value={this?.state?.kyc?.idnumber}
                                          readOnly={true}
                                        />

                                      </InputGroup>
                                    </div>
                                  </div>

                                  <div className='col-12 col-md-12 mb-3'>
                                    <p className='input_desc_sm'>Address</p>
                                    <div className="inputs input-groups">
                                      <InputGroup className="">
                                        <FormControl id="address" placeholder=""
                                          aria-describedby="basic-addon2"
                                          value={this.state.kyc.address}
                                          readOnly={true}
                                        />

                                      </InputGroup>
                                    </div>
                                  </div>

                                  <div className='col-12 col-md-12 mb-3'>
                                    <p className='input_desc_sm'>Uploaded Document in zipfile</p>
                                    <div className="inputs input-groups">
                                      <a href={API_URL + "/" + this.state.kyc.path + "/" + this.state.kyc.image} download >
                                        <img src={folderimage} alt="W3Schools" width="104" height="142"></img>

                                      </a>
                                 
                                      <span className="text-danger">{this.state.errors.front}</span>
                                    </div>
                                  </div>

                               
                                </> */}
                                {/* } */}

  {/* <div className='col-12 col-md-12 text-center mt-3'>
                                  <button className="get-started-btn" onClick={this.handlesubmit}
                                    disabled={this.state.kyc.status == "Approved" || this.state.kyc.status == "Pending"}
                                  >
                                    {this.state.kyc.status ? this.state.kyc.status : "Apply"}
                                  </button>
                                </div> */}
                                {isEmpty(this.state.kyc) &&
                                <div className='col-12 col-md-12 text-center mt-3'>
                                  <button className="get-started-btn" onClick={this.handlesubmit}    
                                  >
                                    {this.state.kyc.status ? this.state.kyc.status : "Apply"}
                                  </button>
                                </div>}
                              </div>
                            </div>



                          </div>
                        </div>
                      </div>
                    </div>




                  </div>

                  <Container className='pb-5 px-0 mt-4'>

                    <center>
                      <small className="mt-3 bottom_text">
                        Disclaimer: The information provided shall not in any way
                        constitute a recommendation as to whether you should invest
                        in any product discussed. We accept no liability for any
                        loss occasioned to any person acting or refraining from
                        action as a result of any material provided or published.
                      </small>
                    </center>
                  </Container>
                </div>
              </div>
            </div>
          </div>

          {/* <Footer /> */}
        </div>
      </div>
    )
  }
}

export default Kyc