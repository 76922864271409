import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Modal, Toast } from 'react-bootstrap';
import { API_URL } from '../../config/env';
import { approvekychook, rejectkychook } from '../../hooks/usebackend';
import folderimage from "../../images/folder_zip.png";
import { Container, FormControl, InputGroup } from 'react-bootstrap';
import proof from "../../images/proof.webp"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import JSZip from  'jszip';
import { saveAs } from 'file-saver';
class Kycmodal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kycModal: true,
      kycdetail: {}

    };
  }

  componentDidMount() {
    console.log("this?.props?.detail", this?.props,this?.props?.detail?.proof[0], this?.props?.detail?.proof[0]?.front);
    // if(!this.state.kycdetail){
    //   console.log("set")
    //   this.setState({"kycdetail" : this?.props?.detail})
    // }
    if (this?.props?.detail?.proof[0]?.identitytype === "Individual") {


      this.setState({
        emailid: this?.props?.detail?.proof[0]?.emailid,
        city: this?.props?.detail?.proof[0]?.city,
        region: this?.props?.detail?.proof[0]?.region,
        country: this?.props?.detail?.proof[0]?.country,
        zipcode: this?.props?.detail?.proof[0]?.zipcode,
        contactnumber: this?.props?.detail?.proof[0]?.contactnumber,
        walletaddress: this?.props?.detail?.proof[0]?.walletaddress,
        idtype: this?.props?.detail?.proof[0]?.idtype,
        idnumber: this?.props?.detail?.proof[0]?.idnumber,
        front: this?.props?.detail?.proof[0]?.front,
        identitytype: this?.props?.detail?.proof[0]?.identitytype
      })
    }
    else if (this?.props?.detail?.proof[0]?.identitytype === "Company") {
      this.setState({
        businessOwnerName: this?.props?.detail?.proof[0]?.businessOwnerName,
        surname: this?.props?.detail?.proof[0]?.surname,
        businessname: this?.props?.detail?.proof[0]?.businessname,
        companynumber: this?.props?.detail?.proof[0]?.companynumber,
        registrationdate: moment(Number(this?.props?.detail?.proof[0]?.registrationdate)).format('DD/MM/YYYY'),
        description: this?.props?.detail?.proof[0]?.description,
        websiteurl: this?.props?.detail?.proof[0]?.websiteurl,
        businessemail: this?.props?.detail?.proof[0]?.businessemail,
        businessaddress: this?.props?.detail?.proof[0]?.businessaddress,
        businesscontactnumber: this?.props?.detail?.proof[0]?.businesscontactnumber,
        businesscity: this?.props?.detail?.proof[0]?.businesscity,
        businessregion: this?.props?.detail?.proof[0]?.businessregion,
        businesscountry: this?.props?.detail?.proof[0]?.businesscountry,
        businesszipcode: this?.props?.detail?.proof[0]?.businesszipcode,
        walletaddress: this?.props?.detail?.proof[0]?.walletaddress,
        idtype: this?.props?.detail?.proof[0]?.idtype,
        idnumber: this?.props?.detail?.proof[0]?.idnumber,
        front: this?.props?.detail?.proof[0]?.front,
        identitytype: this?.props?.detail?.proof[0]?.identitytype
      })
    }
    else {
      this.setState({
        ceoname: this?.props?.detail?.proof[0]?.ceoname,
        organizationname: this?.props?.detail?.proof[0]?.organizationname,
        nonprofitregistrationdate: moment(Number(this?.props?.detail?.proof[0]?.nonprofitregistrationdate)).format('DD/MM/YYYY'),
        nonprofitorganizationaddress: this?.props?.detail?.proof[0]?.nonprofitorganizationaddress,
        nonprofitcity: this?.props?.detail?.proof[0]?.nonprofitcity,
        nonprofitregion: this?.props?.detail?.proof[0]?.nonprofitregion,
        nonprofitcountry: this?.props?.detail?.proof[0]?.nonprofitcountry,
        nonprofitzipcode: this?.props?.detail?.proof[0]?.nonprofitzipcode,
        nonprofitemailid: this?.props?.detail?.proof[0]?.nonprofitemailid,
        nonprofitcontactnumber: this?.props?.detail?.proof[0]?.nonprofitcontactnumber,
        nonprofitwebsiteurl: this?.props?.detail?.proof[0]?.nonprofitwebsiteurl,
        walletaddress: this?.props?.detail?.proof[0]?.walletaddress,
        idtype: this?.props?.detail?.proof[0]?.idtype,
        idnumber: this?.props?.detail?.proof[0]?.idnumber,
        front: this?.props?.detail?.proof[0]?.front,
        identitytype: this?.props?.detail?.proof[0]?.identitytype
        // regdate: moment(nextprops?.record?.companyRegistrationDetails?.regdate).format('DD/MM/YYYY'),

      })
    }
  }

  fileDownloader = (URL) => {
    console.log("URL",URL)
    // let a = document.createElement("a");
    // a.href = URL;
    // a.download = URL;
    let filename = "MultiFilesDownload";
    const zip = new JSZip()
    const folder = zip.folder('project')
    // fetch(URL).then((response) => {
    //   console.log("response",response)
    //     response.blob().then((blob) => {
    //       console.log("blob",blob)
    //       // blob.type = "text/zip"
    //         let url = window.URL.createObjectURL(blob);
    //         console.log("blob",url)
    //         let a = document.createElement("a");
    //         a.href = url;
    //         a.download = url
    //         a.click();
    //     });
        
    // })
    const blobPromise =  fetch(URL)    
    .then(function (response) {  
      console.log({response})             
        if (response.status === 200 || response.status === 0) {
            return Promise.resolve(response.blob());
        } else {
            return Promise.reject(new Error(response.statusText));
        }
    })  
    const name = URL.substring(URL.lastIndexOf('/'))
    folder.file(name, blobPromise)
    // urls.forEach((url)=> {
//    const blobPromise =  fetch(url)    
// .then(function (response) {  
//   console.log({response})             
//     if (response.status === 200 || response.status === 0) {
//         return Promise.resolve(response.blob());
//     } else {
//         return Promise.reject(new Error(response.statusText));
//     }
// })                          
 
        
    // })

    zip.generateAsync({type:"blob"})
        .then(blob => saveAs(blob, filename))
        .catch(e => console.log(e));
}




  handleapprove = async () => {
    let payload = {
      walletaddress: this?.props?.detail?.walletaddress,
      id: this?.props?.detail?.proof[0]?.id
    }
    let approve = await approvekychook(payload);
    console.log("approve", approve);
    if (approve?.data?.type == "success") {
      // Toast.error("Error Try Again !")
    }
    else {
      // Toast.error("Error Try Again !")
    }
    this.props.onDismiss();
  }

  handlereject = async () => {
    let payload = {
      walletaddress: this?.props?.detail?.walletaddress,
      id: this?.props?.detail?.proof[0]?.id
    }
    let reject = await rejectkychook(payload);
    this.props.onDismiss();
  }

  render() {


    const { kycModal } = this.state


    return (



      <Modal className="wallet-modal" show={kycModal} centered size="md">
        <Modal.Header className='pt-3 header_odal_head'>
          <h3 className="sec-head ">KYC</h3>
          <button type="button" class="close" onClick={() => this.props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

        </Modal.Header>
        <Modal.Body className="select-wallet modal_body pt-0 pb-0 px-0">

          <div className="wallet-lists px-2">

            <div className='mod_pad_space'>

              <div className='row'>
                <div className='col-12 col-md-12 mb-0'>
                  <p className='input_desc_sm bold_text_proof'>{this?.props?.detail?.proof[0]?.idtype}</p>

                </div>
                <>
                  {/* <div className='col-12 col-md-12 mb-3'>

                    <p className='input_desc_sm'>1. Proof of Identity</p>
                    <p className='desc_grey_txt'>Please upload a clear, readable copy of any of the following:</p>

                    <div className='note_desc mt-1 mb-0'>
                      <p>Document must be a supported  extension .zip</p>
                    </div>
                  </div> */}

                  <div className='col-12 col-md-12 mb-3'>

                    <p className='input_desc_sm'>Identity Type</p>
                    <div className="inputs input-groups">
                    <InputGroup className="">
                                  <FormControl id="identitytype" placeholder=""
                                    aria-describedby="basic-addonceoname" type="text"
                                    value={this.state.identitytype}
                                    readOnly 
                                  />

                     </InputGroup>
                      {/* <select className="form-control custm_sel" id="exampleFormControlSelectidentity"
                        value={this.state.identitytype}

                      >
                        <option value='' disabled>Select Identity Type</option>
                        <option value='Individual'>Individual</option>
                        <option value='Company'>Company</option>
                        <option value='Nonprofit Organization'>Nonprofit Organization</option>

                      </select> */}

                    </div>
                  </div>

                  {
                    this.state.identitytype == "Individual" ?

                      <>
                        <div className='col-12 col-md-12 mb-3'>
                          <p className='input_desc_sm'>Email ID</p>
                          <div className="inputs input-groups">
                            <InputGroup className="">
                              <FormControl id="emailid" placeholder=""
                                aria-describedby="basic-addonemail" type="email"
                                value={this?.state?.emailid}
                                readOnly
                              />

                            </InputGroup>

                          </div>
                        </div>

                        <div className='col-12 col-md-12 mb-3'>
                          <p className='input_desc_sm'>City</p>
                          <div className="inputs input-groups">
                            <InputGroup className="">
                              <FormControl id="city" placeholder=""
                                aria-describedby="basic-addoncity" type="text"
                                value={this?.state?.city}
                                readOnly
                              />

                            </InputGroup>

                          </div>
                        </div>

                        <div className='col-12 col-md-12 mb-3'>
                          <p className='input_desc_sm'>Region</p>
                          <div className="inputs input-groups">
                            <InputGroup className="">
                              <FormControl id="region" placeholder=""
                                aria-describedby="basic-addonregion" type="text"
                                value={this?.state?.region}
                                readOnly
                              />

                            </InputGroup>

                          </div>
                        </div>

                        <div className='col-12 col-md-12 mb-3'>
                          <p className='input_desc_sm'>Country</p>
                          <div className="inputs input-groups">
                            <InputGroup className="">
                              <FormControl id="country" placeholder=""
                                aria-describedby="basic-addoncountry" type="text"
                                value={this?.state?.country}
                                readOnly
                              />

                            </InputGroup>

                          </div>
                        </div>

                        <div className='col-12 col-md-12 mb-3'>
                          <p className='input_desc_sm'>Zip Code</p>
                          <div className="inputs input-groups">
                            <InputGroup className="">
                              <FormControl id="zipcode" placeholder=""
                                aria-describedby="basic-addonzipcode" type="text"
                                value={this?.state?.zipcode}
                                readOnly
                              />

                            </InputGroup>

                          </div>
                        </div>

                        <div className='col-12 col-md-12 mb-3'>
                          <p className='input_desc_sm'>Contact Number</p>
                          <div className="inputs input-groups">
                            <InputGroup className="">
                              <FormControl id="contactnumber" placeholder=""
                                aria-describedby="basic-addoncontactnumber" type="text"
                                value={this?.state?.contactnumber}
                                readOnly
                              />

                            </InputGroup>

                          </div>
                        </div>

                      </>
                      :

                      this.state.identitytype == "Company" ?
                        <>
                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Business Owner Name*</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businessOwnerName" placeholder=""
                                  aria-describedby="basic-addonname" type="text"
                                  value={this?.state?.businessOwnerName}
                                  readOnly
                                />

                              </InputGroup>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Business Owner Surname*</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="surname" placeholder=""
                                  aria-describedby="basic-addonsurname" type="text"
                                  value={this?.state?.surname}
                                  readOnly
                                />

                              </InputGroup>

                            </div>
                          </div>


                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Business Name*</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businessname" placeholder=""
                                  aria-describedby="basic-addonbusinessname" type="text"
                                  value={this?.state?.businessname}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>



                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Company Number*</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="companynumber" placeholder=""
                                  aria-describedby="basic-addoncompanynumber" type="text"
                                  value={this?.state?.companynumber}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>
                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Registration Date*</p>
                            <div className="inputs input-groups">
                              <div className="inputs input-groups date_inoput_grps inut_grp_date_inpt_pad">
                                <InputGroup className="datepicker_input">
                                <FormControl id="registrationdate" placeholder=""
                                    aria-describedby="basic-addonorganizationname" type="text"
                                    value={this?.state?.registrationdate}
                                    readOnly

                                  />
                                  {/* <DatePicker
                                    // selected={this.state.registrationdate}
                                    // onChange={(date) => {
                                    //   let newDate = new Date(date)
                                    //   newDate.setMilliseconds(0)
                                    //   // let formData = {
                                    //   //     // ...this.state.formValue,
                                    //   //     'registrationdate': newDate.getTime()
                                    //   // };
                                    //   this.setState({
                                    //     'registrationdate': newDate.getTime()
                                    //   });
                                    // }}
                                    value={this.state.registrationdate}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                  // minDate={new Date(Date.now() + (86400000 * 30))}
                                  //   filterTime={this.filterPassedTime.bind(this)}
                                  // disabledKeyboardNavigation
                                  // showTimeSelect
                                  // showYearDropdown
                                  // showMonthDropdown
                                  // selected={this.state.registrationdate }
                                  // id="registrationdate"
                                  // maxDate={new Date()}
                                  // onChange={(data) => { console.log("date",data);this.setState({ registrationdate:data }) }}
                                  // onKeyDown={e => e.preventDefault()}
                                  // dateFormat="dd/MM/yyyy"

                                  /> */}

                                </InputGroup>
                                {/* <InputGroup.Append className='cur_pointer create_lock_date'>
                                  <button variant="outline-secondary" className="trans_cal_btn">
                                    <i class="far fa-calendar-alt"></i>
                                  </button>
                                </InputGroup.Append> */}
                              </div>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Business Type</p>
                            <div className="inputs input-groups text_are_grp text_area_norla_colr">
                              <InputGroup className="">
                                <textarea
                                  id="description" rows="3"
                                  aria-describedby="basic-addon2"
                                  value={this?.state?.description}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Website URL</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="websiteurl" placeholder=""
                                  aria-describedby="basic-addonwebsiteurl" type="text"
                                  value={this?.state?.websiteurl}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Business Email*</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businessemail" placeholder=""
                                  aria-describedby="basic-addonbusinessemail" type="email"
                                  value={this?.state?.businessemail}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Business Address*</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businessaddress" placeholder=""
                                  aria-describedby="basic-addonbusinessaddress" type="text"
                                  value={this?.state?.businessaddress}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Contact Number*</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businesscontactnumber" placeholder=""
                                  aria-describedby="basic-addonbusinesscontactnumber" type="text"
                                  value={this?.state?.businesscontactnumber}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>City</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businesscity" placeholder=""
                                  aria-describedby="basic-addonbusinesscity" type="text"
                                  value={this?.state?.businesscity}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Region</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businessregion" placeholder=""
                                  aria-describedby="basic-addonbusinessregion" type="text"
                                
                                  value={this?.state?.businessregion}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>


                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Country</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businesscountry" placeholder=""
                                  aria-describedby="basic-addonbusinesscountry" type="text"
                                  value={this?.state?.businesscountry}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>

                          <div className='col-12 col-md-12 mb-3'>
                            <p className='input_desc_sm'>Zip Code</p>
                            <div className="inputs input-groups">
                              <InputGroup className="">
                                <FormControl id="businesszipcode" placeholder=""
                                  aria-describedby="basic-addonbusinesszipcode" type="text"
                                  value={this?.state?.businesszipcode}
                                  readOnly

                                />

                              </InputGroup>

                            </div>
                          </div>

                        </>
                        :
                        this.state.identitytype == "Nonprofit Organization" ?
                          <>
                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Name of CEO*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="ceoname" placeholder=""
                                    aria-describedby="basic-addonceoname" type="text"
                                    value={this?.state?.ceoname}
                                    readOnly

                                  />

                                </InputGroup>

                              </div>
                            </div>

                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Organization Name*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="organizationname" placeholder=""
                                    aria-describedby="basic-addonorganizationname" type="text"
                                    value={this?.state?.organizationname}
                                    readOnly


                                  />

                                </InputGroup>

                              </div>
                            </div>


                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Registration Date*</p>
                              <div className="inputs input-groups">
                                <div className="inputs input-groups date_inoput_grps inut_grp_date_inpt_pad">
                                  <InputGroup className="datepicker_input">
                                  <FormControl id="nonprofitregistrationdate" placeholder=""
                                    aria-describedby="basic-addonorganizationname" type="text"
                                    value={this?.state?.nonprofitregistrationdate}
                                    readOnly

                                  />
                                    {/* <DatePicker
                                      // selected={this.state.nonprofitregistrationdate}
                                      // onChange={(date) => {
                                      //   let newDate = new Date(date)
                                      //   newDate.setMilliseconds(0)
                                      //   // let formData = {
                                      //   //     // ...this.state.formValue,
                                      //   //     'registrationdate': newDate.getTime()
                                      //   // };
                                      //   this.setState({
                                      //     'nonprofitregistrationdate': newDate.getTime()
                                      //   });
                                      // }}
                                      value={this.state.nonprofitregistrationdate}
                                      dateFormat="dd/MM/yyyy"
                                      maxDate={new Date()}
                                      // onKeyDown={(e) => {
                                      //   e.preventDefault();
                                      // }}
                                    // minDate={new Date(Date.now() + (86400000 * 30))}
                                    //   filterTime={this.filterPassedTime.bind(this)}
                                    // disabledKeyboardNavigation
                                    // showTimeSelect
                                    // selected={this.state.locktime }
                                    // onChange={(date) => { this.setState({ nonprofitregistrationdate: date }) }}
                                    // onKeyDown={e => e.preventDefault()}
                                    // dateFormat="MMMM d, yyyy"
                                    /> */}

                                  </InputGroup>
                                  {/* <InputGroup.Append className='cur_pointer create_lock_date'>
                                    <button variant="outline-secondary" className="trans_cal_btn">
                                      <i class="far fa-calendar-alt"></i>
                                    </button>
                                  </InputGroup.Append> */}
                                </div>

                              </div>
                            </div>



                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Organization Address*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="nonprofitorganizationaddress" placeholder=""
                                    aria-describedby="basic-addonnonprofitorganizationaddress" type="text"
                                    value={this?.state?.nonprofitorganizationaddress}
                                    readOnly

                                  />

                                </InputGroup>

                              </div>
                            </div>




                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>City*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="nonprofitcity" placeholder=""
                                    aria-describedby="basic-addonnonprofitcity" type="text"
                                    value={this?.state?.nonprofitcity}
                                    readOnly
 
                                  />

                                </InputGroup>

                              </div>
                            </div>

                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Region*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="nonprofitregion" placeholder=""
                                    aria-describedby="basic-addonnonprofitregion" type="text"
                                    value={this?.state?.nonprofitregion}
                                    readOnly

                                  />

                                </InputGroup>

                              </div>
                            </div>


                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Country*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="nonprofitcountry" placeholder=""
                                    aria-describedby="basic-addonnonprofitcountry" type="text"
                                    value={this?.state?.nonprofitcountry}
                                    readOnly

                                  />

                                </InputGroup>

                              </div>
                            </div>

                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Zip Code*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="nonprofitzipcode" placeholder=""
                                    aria-describedby="basic-addonnonprofitzipcode" type="text"
                                    value={this?.state?.nonprofitzipcode}
                                  readOnly

                                  />

                                </InputGroup>

                              </div>
                            </div>

                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Email ID*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="nonprofitemailid" placeholder=""
                                    aria-describedby="basic-addonnonprofitemailid" type="text"
                                    value={this?.state?.nonprofitemailid}
                                  readOnly

                                  />

                                </InputGroup>

                              </div>
                            </div>

                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Contact Number*</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="nonprofitcontactnumber" placeholder=""
                                    aria-describedby="basic-addonnonprofitcontactnumber" type="text"
                                    value={this?.state?.nonprofitcontactnumber}
                                  readOnly

                                  />

                                </InputGroup>

                              </div>
                            </div>

                            <div className='col-12 col-md-12 mb-3'>
                              <p className='input_desc_sm'>Website URL</p>
                              <div className="inputs input-groups">
                                <InputGroup className="">
                                  <FormControl id="nonprofitwebsiteurl" placeholder=""
                                    aria-describedby="basic-addonnonprofitwebsiteurl" type="text"
                                    value={this?.state?.nonprofitwebsiteurl}
                                  readOnly

                                  />

                                </InputGroup>

                              </div>
                            </div>




                          </>
                          :
                          <></>

                  }

                  {/* end Individual */}

                  {/* common fields */}
                  <div className='col-12 col-md-12 mb-3'>

                    <p className='input_desc_sm'>Document Type</p>
                    <div className="inputs input-groups">
                    <InputGroup className="">
                                  <FormControl id="idtype" placeholder=""
                                    aria-describedby="basic-addonceoname" type="text"
                                    value={this.state.idtype}
                                  readOnly

                                  />
                                     </InputGroup>

                      {/* <select className="form-control custm_sel" id="exampleFormControlSelect3"
                        value={this.state.idtype}
                        onChange={(e) => {
                          this.setState({ 'idtype': e.target.value })
                        }}
                      >
                        <option value=''>Select Document Type</option>
                        <option value='Voter ID'>Voter ID</option>
                        <option value='Aadhar Card'>Aadhar Card</option>
                        <option value='Driving License'>Driving License</option>

                      </select> */}

                    </div>
                  </div>

                  <div className='col-12 col-md-12 mb-3'>
                    <p className='input_desc_sm'>ID Number</p>
                    <div className="inputs input-groups">
                      <InputGroup className="">
                        <FormControl id="idnumber" placeholder=""
                          aria-describedby="basic-addon2"
                          value={this?.state?.idnumber}
                          readOnly

                        />

                      </InputGroup>

                    </div>
                  </div>

                  {/* <div className='col-12 col-md-12 mb-3'>
                    <p className='input_desc_sm'>Address</p>
                    <div className="inputs input-groups">
                        <InputGroup className="">
                            <FormControl id="address" placeholder=""
                                aria-describedby="basic-addon2"
                                value={this.state.address}
                                onChange={(e)=>{
                                  this.setState({"address" : e.target.value});
                                }}
                            />
                          
                        </InputGroup>
                        <span className="text-danger">{this.state.errors.address}</span>
                    </div>
                </div> */}

                  {/* <div className='col-12 col-md-12 mb-3'>
                                    <p className='input_desc_sm'>Upload Document in zip file</p>
                                    <div className="inputs input-groups">
                                      <InputGroup className="file_grp_input"
                                        onChange={(e) => {
                                          this.setState({ 'front': e.target.files[0] });
                                          this.setState({ 'frontview': URL.createObjectURL(e.target.files[0]) })
                                        }}
                                      >
                                        <div className="w-100">
                                          <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" />
                                            <label className="custom-file-label mb-0" for="inputGroupFile04">
                                              {this.state.front ? this.state.front.name : "Choose file"}</label>
                                          </div>

                                        </div>

                                      </InputGroup>
                                      
                                    </div>
                                  </div> */}

                  {/* end common fields */}
                  {/* <div className='col-12 col-md-12 mb-3'>
                    <p className='input_desc_sm'>Back Side</p>
                    <div className="inputs input-groups">
                        <InputGroup className="file_grp_input"
                          onChange={(e)=>{
                            this.setState({'back' : e.target.files[0]});
                            this.setState({'backview' : URL.createObjectURL(e.target.files[0])})
                          }}
                        >
                        <div className="w-100">
                    <div className="custom-file">
                        <input type="file" className="custom-file-input" id="inputGroupFile05" aria-describedby="inputGroupFileAddon04" />
                        <label className="custom-file-label mb-0" for="inputGroupFile04">Choose file</label>
                    </div>
                    
                    </div>
                          
                        </InputGroup>
                        <span className="text-danger">{this.state.errors.back}</span>
                    </div>
                        </div> */}
                  {/* <div className='col-12 col-sm-6 mb-3 mt-1'>
                        <p className='input_desc_sm'>Front Side Image</p>
                        <div className="icon_align_border">
                        <img src={this.state.frontview ? this.state.frontview : proof} className="img-fluid p-3" />
                        </div>
                        </div>
                        <div className='col-12 col-sm-6 mb-3 mt-1'>
                        <p className='input_desc_sm'>Back Side Image</p>
                        <div className="icon_align_border">
                        <img src={this.state.backview ? this.state.backview : proof} className="img-fluid p-3" />
                        </div>
                        </div> */}
                </>

                <div className='col-12 col-sm-6 mb-3 mt-1'>
                  <p className='input_desc_sm'>Kyc Document</p>
                  <div className="icon_align_border">
                    {/* <img src={API_URL+this?.props?.detail?.proof[0]?.path + this?.props?.detail?.proof[0]?.image[0]} className="img-fluid p-3" /> */}
                    {/* {API_URL +"/"+ this.state.kyc.path +"/"+ this.state.kyc.image} */}
                    {/* <a href={API_URL + "/" + this?.props?.detail?.proof[0]?.path + "/" + this?.props?.detail?.proof[0]?.front} download={API_URL + "/" + this?.props?.detail?.proof[0]?.path + "/" + this?.props?.detail?.proof[0]?.front}> */}
                     
                      
                      {/* <a href={"/images/myw3schoolsimage.jpg"} download> */}
                      <img src={folderimage} alt="W3Schools" width="104" height="142"></img>

                    {/* </a> */}
                  </div>
                  <div className='text-center'>
                  <button className="get-started-btn mt-3" onClick={()=>{this.fileDownloader(API_URL + "/" + this?.props?.detail?.proof[0]?.path + this?.props?.detail?.proof[0]?.front)}}>Download</button>
                  </div>
                </div>
                {/* <div className='col-12 col-sm-6 mb-3 mt-1'>
                        <p className='input_desc_sm'>Back Side Image</p>
                        <div className="icon_align_border">
                          <img src={API_URL+this?.props?.detail?.proof[0]?.path + this?.props?.detail?.proof[0]?.image[1]} className="img-fluid p-3" />
                        </div>
                        </div> */}
              </div>
              {/* <hr className='hr_green' />
                                <div className='row'>
                                <div className='col-12 col-md-12 mb-0'>
                    <p className='input_desc_sm bold_text_proof'>Driving License</p>
                  
                        </div>
                        <div className='col-12 col-sm-6 mb-3 mt-1'>
                        <p className='input_desc_sm'>Front Side Image</p>
                        <div className="icon_align_border">
                          <img src={proof} className="img-fluid p-3" />
                        </div>
                        </div>
                        <div className='col-12 col-sm-6 mb-3 mt-1'>
                        <p className='input_desc_sm'>Back Side Image</p>
                        <div className="icon_align_border">
                          <img src={proof} className="img-fluid p-3" />
                        </div>
                        </div>
                                </div> */}
              <div className='text-center mt-4'>

                <button className='get-started-btn mr-2'
                  onClick={this.handleapprove}
                  disabled={this?.props?.detail?.proof[0].status != "Pending"}
                >
                  Approve</button>
                <button className='get-started-btn'
                  onClick={this.handlereject}
                  disabled={this?.props?.detail?.proof[0].status != "Pending"}
                >
                  Reject</button>
              </div>

            </div>
          </div>
        </Modal.Body>
      </Modal>



    )
  }

}


export default Kycmodal