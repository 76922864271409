import React, { Component } from "react";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Header from "../Header";
import Sidebar from "../Sidebar";
import Trendingslider from "../trendingslider";

import Buycard from "../Launchpad/Buycard";

import Web3 from "web3";
import "../../css/styles.css";

import coin from "../../images/coin.png";
import favicon from "../../images/Bitdeal/logo_curve.png";

import loader from "../../images/loader.gif";
import {
  getlaunchpaddatalimit,
  getLaunchpadsaleCardLimit,
  getmycontributiondatalimit,
  getPrivatesaleCardLimit,
  getSaleCards,
  getSaleCardsLimit,
  getsaledatalimit,
  getTotalSalesInfo,
  searchCards,
  searchSale,
} from "../../hooks/useProjects";
import {
  Dropdown,
  Nav,
  Tab,
  Row,
  Col,
  Container,
  ProgressBar,
  InputGroup,
  Form,
  Alert,
} from "react-bootstrap";
import { CHAINS } from "../../config/env";
import { getAccount, getChainId, setChainId } from "../../hooks/useAccount";
import { UseTokenInfo } from "../../hooks/useContract";
import {
  gettrendingdisplayhook,
  getusecreatedlaunchhook,
  getuserdatahook,
} from "../../hooks/usebackend";
import {
  privatesalefilterby,
  privatesalesortby,
  tokenfilter,
  tokenfilterlaunchpad,
} from "../../hooks/filter";
import { FaArrowRight } from "react-icons/fa";

import bgstyle2 from "../../images/bg_style2.png";

import bgstyle from "../../images/bg_style.png";

import bgoutline1 from "../../images/bg_outline1.png";
import whiteoutline1 from "../../images/outline-white1.png";

import bgoutline from "../../images/bg_outline.png";
import whiteoutline from "../../images/outline-white.png";
import bgoutline2 from "../../images/bg_outline2.png";
import whiteoutline2 from "../../images/outline-white2.png";

// whitepaper images

import image1 from "../../images/whitepaper/image6.png";
import image2 from "../../images/whitepaper/image26.png";
import image3 from "../../images/whitepaper/image10.png";
import image4 from "../../images/whitepaper/image23.png";
import image5 from "../../images/whitepaper/image8.png";
import image6 from "../../images/whitepaper/image32.png";
import image7 from "../../images/whitepaper/image11.png";
import image8 from "../../images/whitepaper/image22.png";
import image9 from "../../images/whitepaper/image16.png";
import image10 from "../../images/whitepaper/image3.png";
import image11 from "../../images/whitepaper/image15.png";
import image12 from "../../images/whitepaper/image28.png";
import image13 from "../../images/whitepaper/image37.png";
import image14 from "../../images/whitepaper/image31.png";
import image15 from "../../images/whitepaper/image12.png";
import image16 from "../../images/whitepaper/image29.png";
import image17 from "../../images/whitepaper/image6.png";
import image18 from "../../images/whitepaper/image4.png";
import image19 from "../../images/whitepaper/image14.png";
import image20 from "../../images/whitepaper/image40.png";
import image21 from "../../images/whitepaper/image42.png";
import image22 from "../../images/whitepaper/image13.png";
import image23 from "../../images/whitepaper/image35.png";
import image24 from "../../images/whitepaper/image7.png";
import image25 from "../../images/whitepaper/image34.png";
import image26 from "../../images/whitepaper/image9.png";
import image27 from "../../images/whitepaper/image9.png";
import image28 from "../../images/whitepaper/image9.png";
import image29 from "../../images/whitepaper/image41.png";
import image30 from "../../images/whitepaper/image2.png";
import image31 from "../../images/whitepaper/image33.png";
import image32 from "../../images/whitepaper/image39.png";
import image33 from "../../images/whitepaper/image24.png";
import image34 from "../../images/whitepaper/image1.png";
import image35 from "../../images/whitepaper/image5.png";
import image36 from "../../images/whitepaper/image20.png";
import image37 from "../../images/whitepaper/image21.png";
import image38 from "../../images/whitepaper/image30.png";
import image39 from "../../images/whitepaper/image36.png";
import image40 from "../../images/whitepaper/image38.png";
import image41 from "../../images/whitepaper/image18.png";
import image42 from "../../images/whitepaper/image25.png";
import image43 from "../../images/whitepaper/image19.png";

class WhitePaper extends Component {
  showLoader() {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.5;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");
  }

  hideLoader() {
    document
      .getElementsByTagName("body")[0]
      .classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }

  closeAllMenusStatus() {
    this.setState({ closeKycmenu: true });
    this.setState({ closeHcmenu: true });
    this.setState({ closeCoinmenu: true });
  }

  closeAllMenusKyc() {
    this.setState({ closeStatusmenu: true });
    this.setState({ closeHcmenu: true });
    this.setState({ closeCoinmenu: true });
  }

  closeAllMenusCoin() {
    this.setState({ closeStatusmenu: true });
    this.setState({ closeHcmenu: true });
    this.setState({ closeKycmenu: true });
  }

  closeAllMenusHc() {
    this.setState({ closeStatusmenu: true });
    this.setState({ closeCoinmenu: true });
    this.setState({ closeKycmenu: true });
  }

  // menuToggle(e)
  // {
  //   console.log(e,"fsd");
  //   console.log(this.state.closeHcmenu,"fdsf");
  // }
  async gettrending() {
    let data = await gettrendingdisplayhook();
    console.log("dataa trending", data?.data?.data);
    if (data?.data?.data?.length > 0)
      this.setState({ trending: data?.data?.data });
  }

  getuserdata = async () => {
    const totalsale = await getTotalSalesInfo();
    let userdata = await getuserdatahook(
      this.state.accountInfo && this.state.accountInfo.toLowerCase()
    );
    console.log("userdata", userdata.data.data);
    // this.setState({investedpools : userdata.data.data.investedpools})
    // this.setState({wishlist : userdata.data.data.wishlist})
    // this.setState({viewlist : userdata.data.data.viewlist})
    // this.setState({investedamount : userdata.data.data.investedamount})
    // const totalproxydata = userdata.data.data.launchpad.filter(data => data.iswhitelist == true)
    // console.log("totalproxydata" , totalproxydata);
    let totalproxydata = userdata.data.data.launchpad;
    const newly = totalproxydata.filter(
      (data) => data.iswhitelist == false || data.iswhitelist == "false"
    );
    let newarray = [];
    newly.map((val, i) => {
      let newvalue = totalsale.find((e) => e._sale == val.saleaddress);
      if (newvalue) {
        newarray.push(newvalue);
      }
    });
    console.log("userdatanewly", newly, newarray);
    this.setState({ mycontribution: newarray.reverse() });
  };

  // getdummylaunchpad = async() => {
  //   let dummylaunchpad = await getusecreatedlaunchhook();
  //   var dummylaunch = [];
  //   console.log("get dummy launchpad" , dummylaunchpad);
  //   if(dummylaunchpad?.data?.type == "success"){
  //     dummylaunch = dummylaunchpad?.data?.data?.filter(data => data?.whitelist != "privatesale");
  //     this.setState({dummylaunchpad : dummylaunch})
  //   }
  //   // newtotalproxy = newtotalproxy.concat(dummylaunch)
  // }

  constructor(props) {
    super(props);
    this.state = {
      proxy: [],
      totalSales: [],
      onGoingSales: [],
      upComingSales: [],
      isSearch: false,
      searchSale: [],
      search: "",
      interval: 3,
      loadInterval: 3,
      searchInterval: 3,
      searchProxy: [],
      isLoading: false,
      currentChain: 0,
      closeHcmenu: false,
      closeCoinmenu: false,
      closeKycmenu: false,
      closeStatusmenu: false,
      isuser: false,
      button: true,
      mycontribution: [],
      accountInfo: getAccount(),
      interval: 0,
      loadinterval: 3,
      original: [],
      filterdata: "All Status",
      sortdata: "No Filter",
      coin: [],
      status: [],
      type: [],
      trending: [],
      dummylaunchpad: [],
      defaultActiveKey: "first",
    };
  }

  componentDidMount() {
    // this.showLoader();
    this.hideLoader();
    // document.getElementById("launchpad_parent").classList.add("active");
    this.setState({ currentChain: getChainId() });
    this.gettrending();
    this.getuserdata();
    this.loadData();

    this.mytoken();
    // this.getdummylaunchpad()
  }

  async mytoken() {
    // debugger
    const link = window.location.pathname.split("/")[1];
    if (link && link == "mylaunchpadlist") {
      document.getElementById("third").click();
      // console.log("link",link);
      this.setState({ isuser: true });
      // this.setState({loadInterval : 3});
      this.setState({ interval: 3 });
      this.setState({ searchInterval: 3 });
      // this.setState({button : true})
      // this.setState({ isSearch: true})
      this.setState({ filterdata: "All Status", sortdata: "No Filter" });
      await this.loadmycontribution();
    } else {
      console.log("linklink", link);
      document.getElementById("first").click();
      //  this.nouser()
    }
  }

  sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  //     async loadData(){
  //       this.showLoader();
  //       const Proxydata = await getTotalSalesInfo();
  //       console.log("Proxydata",Proxydata);
  //       const totalproxydata = Proxydata.filter(data => data._isWhitelisted == false)
  //       let trenddata = await gettrendingdisplayhook();
  //       console.log("dataa trending" , trenddata?.data?.data);
  //       if(trenddata?.data?.data?.length > 0)
  //       this.setState({trending : trenddata?.data?.data})
  //       var trending = trenddata?.data?.data
  //       console.log("trendingdataaaaaaaaaaaaaa" , trending);
  //       let newtotalproxy = [];
  //         trending.map((val , i)=>{
  //           let singledata = totalproxydata.find(e=>e?._sale == val.saleaddress);
  //           if(singledata){
  //             newtotalproxy.push(singledata)
  //           }
  //         })
  // //add on works
  //         // let dummylaunchpad = await getusecreatedlaunchhook();
  //         // var dummylaunch = [];
  //         // console.log("get dummy launchpad" , dummylaunchpad);
  //         // if(dummylaunchpad?.data?.type == "success"){
  //         //   dummylaunch = dummylaunchpad?.data?.data?.filter(data => data?.whitelist != "privatesale");
  //         //   this.setState({dummylaunchpad : dummylaunch})
  //         //   newtotalproxy = dummylaunch ? newtotalproxy.concat(dummylaunch) : []
  //         // }

  //         totalproxydata.map((datas , i) => {
  //           let singlesale = newtotalproxy.find(e=>e._sale == datas?._sale);
  //           if(!singlesale){
  //             newtotalproxy.push(datas)
  //           }
  //         })
  //         this.setState({ proxy : newtotalproxy })
  //         console.log("trendingdataaaaaaaaaaaaaa" , newtotalproxy);
  //       console.log("total proxy data" , totalproxydata);
  //       // this.setState({ proxy : totalproxydata })
  //       this.setState({ original : newtotalproxy })
  //       const total = await getlaunchpaddatalimit(newtotalproxy , 0 , this.state.loadinterval);
  //       console.log("total>>>" , total);
  //       // const total = await getPrivatesaleCardLimit(Proxydata,0,this.state.interval , this.state.isuser);
  //       // console.log("button " ,Proxydata?.length , total.index );
  //       // if(Proxydata?.length == total.index || !total){
  //       //   this.setState({button : false})
  //       // }
  //       // await this.sleep(1000)
  //       //  this.setState({ totalSales: total.saleInfoCards });
  //       //  this.setState({ interval: total.index+1 });
  //       this.setState({ totalSales: total });
  //        this.setState({ interval: this.state.loadinterval});
  //       this.hideLoader();
  //   }

  async loadData() {
    this.showLoader();
    const Proxydata = await getTotalSalesInfo();
    console.log("Proxydata", Proxydata);
    const totalproxydata = Proxydata.filter(
      (data) => data._isWhitelisted == false
    );
    let trenddata = await gettrendingdisplayhook();
    console.log("dataa trending", trenddata?.data?.data);
    if (trenddata?.data?.data?.length > 0)
      this.setState({ trending: trenddata?.data?.data });
    var trending = trenddata?.data?.data;
    console.log("trendingdataaaaaaaaaaaaaa", trending);
    let newtotalproxy = [];
    trending.map((val, i) => {
      let singledata = totalproxydata.find((e) => e?._sale == val.saleaddress);
      if (singledata) {
        newtotalproxy.push(singledata);
      }
    });
    //add on works
    // let dummylaunchpad = await getusecreatedlaunchhook();
    // var dummylaunch = [];
    // console.log("get dummy launchpad" , dummylaunchpad);
    // if(dummylaunchpad?.data?.type == "success"){
    //   dummylaunch = dummylaunchpad?.data?.data?.filter(data => data?.whitelist != "privatesale");
    //   this.setState({dummylaunchpad : dummylaunch})
    //   newtotalproxy = dummylaunch ? newtotalproxy.concat(dummylaunch) : []
    // }

    totalproxydata.map((datas, i) => {
      let singlesale = newtotalproxy.find((e) => e._sale == datas?._sale);
      if (!singlesale) {
        newtotalproxy.push(datas);
      }
    });
    this.setState({ proxy: newtotalproxy });
    console.log("trendingdataaaaaaaaaaaaaa", newtotalproxy);
    console.log("total proxy data", totalproxydata);
    // this.setState({ proxy : totalproxydata })
    this.setState({ original: newtotalproxy });
    const total = await getlaunchpaddatalimit(
      newtotalproxy,
      0,
      this.state.loadinterval
    );
    console.log("total>>>", total);
    // const total = await getPrivatesaleCardLimit(Proxydata,0,this.state.interval , this.state.isuser);
    // console.log("button " ,Proxydata?.length , total.index );
    // if(Proxydata?.length == total.index || !total){
    //   this.setState({button : false})
    // }
    // await this.sleep(1000)
    //  this.setState({ totalSales: total.saleInfoCards });
    //  this.setState({ interval: total.index+1 });
    this.setState({ totalSales: total });
    this.setState({ interval: this.state.loadinterval });
    this.hideLoader();
  }

  async loadMore() {
    console.log(
      "Lad moreee",
      this.state.totalSales.length < this.state.proxy.length
    );
    this.setState({ isLoading: true });
    const newly = await getlaunchpaddatalimit(
      this.state.proxy,
      this.state.interval,
      this.state.interval + this.state.loadInterval
    );
    console.log("newly ", newly);
    const total = this.state.totalSales.concat(newly);
    this.setState({
      totalSales: total,
      interval: this.state.interval + this.state.loadInterval,
    });
    this.setState({ isLoading: false });
  }

  async loadmycontribution() {
    this.showLoader();
    console.log("my contribution", this.state.mycontribution);
    // this.setState({ isLoading: true});
    this.setState({ proxy: this.state.mycontribution });
    const newly = await getmycontributiondatalimit(
      this.state.mycontribution,
      0,
      this.state.loadInterval
    );
    console.log("newly ", newly);
    this.setState({ totalSales: newly });
    //  this.setState({ isLoading: false});
    this.hideLoader();
  }

  // async searchloadMoremycontribution(){
  //   console.log("Lad more",this.state.totalSales.length < this.state.proxy.length)
  //   this.setState({ isLoading: true});
  //   const newly = await getmycontributiondatalimit(this.state.searchProxy,this.state.searchInterval,this.state.searchInterval+this.state.loadInterval);
  //   const total = this.state.searchSale.concat(newly);
  //   await this.sleep(1000)
  //    this.setState({ searchSale: total,searchInterval: this.state.searchInterval+this.state.loadInterval });
  //    this.setState({ isLoading: false});
  // }

  async loadMoremycontribution() {
    console.log(
      "Lad more",
      this.state.totalSales.length < this.state.proxy.length
    );
    this.setState({ isLoading: true });
    const newly = await getmycontributiondatalimit(
      this.state.mycontribution,
      this.state.interval,
      this.state.interval + this.state.loadInterval
    );
    console.log("newly ", newly);
    const total = this.state.totalSales.concat(newly);
    // if(this?.state?.proxy?.length == newly.index || !newly.saleInfoCards){
    //   console.log("button");
    //   this.setState({button : false})
    // }
    // await this.sleep(1000)
    //  this.setState({ totalSales: total,interval: this.state.interval+this.state.loadInterval });
    this.setState({
      totalSales: total,
      interval: this.state.interval + this.state.loadInterval,
    });
    this.setState({ isLoading: false });
    //  this.setState({ isLoading: false});
  }

  async loadSearchMore() {
    console.log(
      "Lad more",
      this.state.totalSales.length < this.state.proxy.length
    );
    this.setState({ isLoading: true });
    const newly = await getlaunchpaddatalimit(
      this.state.searchProxy,
      this.state.searchInterval,
      this.state.searchInterval + this.state.loadInterval
    );
    const total = this.state.searchSale.concat(newly);
    await this.sleep(1000);
    this.setState({
      searchSale: total,
      searchInterval: this.state.searchInterval + this.state.loadInterval,
    });
    this.setState({ isLoading: false });
  }

  async handleChange(e) {
    if (e.target.value.length > 0) {
      if (e.key === "Enter") {
        console.log("Search : ", e.target.value);
        this.showLoader();
        const searchS = await searchCards(this.state.proxy, e.target.value);
        this.setState({ searchProxy: searchS });
        const data = await getlaunchpaddatalimit(
          searchS,
          0,
          this.state.searchInterval
        );
        await this.sleep(1050);
        console.log("Search sale : ", searchS);
        this.setState({ searchSale: data, isSearch: true });
        this.hideLoader();
      }
    } else {
      this.setState({ isSearch: false });
    }
  }

  async nouser() {
    this.setState({ isuser: false });
    // this.setState({loadInterval : 3});
    this.setState({ interval: 0 });
    this.setState({ searchInterval: 3 });
    // this.setState({button : true});
    this.setState({ filterdata: "All Status", sortdata: "No Filter" });
    // this.setState({ isSearch: true})
    await this.loadData();
  }

  async yesuser() {
    this.setState({ isuser: true });
    // this.setState({loadInterval : 3});
    this.setState({ interval: 3 });
    this.setState({ searchInterval: 3 });
    // this.setState({button : true})
    // this.setState({ isSearch: true})
    this.setState({ filterdata: "All Status", sortdata: "No Filter" });
    await this.loadmycontribution();
  }

  async handlefilter(val) {
    if (val == "All Status") {
      this.setState({ filterdata: val });
      this.setState({ button: true });
      this.loadData();
    } else {
      this.showLoader();
      this.setState({ button: true });
      this.setState({ filterdata: val });
      var filtered = await privatesalefilterby(
        this.state.original,
        val,
        this.state.isuser
      );
      console.log("filtered", filtered);
      // var saleinfo = await getPrivatesaleFilter(filtered , this.state.isuser);
      this.setState({ proxy: filtered });
      if (filtered.length > 0) {
        const total = await getlaunchpaddatalimit(
          filtered,
          0,
          this.state.loadInterval
        );
        console.log("button ", filtered?.length, total);

        if (filtered?.length == 0) {
          this.setState({ totalSales: filtered });
        }
        await this.sleep(1000);
        this.setState({ totalSales: total });
        this.setState({ interval: 3 });
        this.hideLoader();
      } else {
        // this.setState({button : false})
        this.setState({ totalSales: filtered });
        this.hideLoader();
      }
    }
  }

  async handlesort(val) {
    if (val == "No Filter") {
      this.setState({ button: true });
      this.loadData();
      this.setState({ sortdata: val });
    } else {
      this.showLoader();
      this.setState({ sortdata: val });
      this.setState({ button: true });
      var sorted = await privatesalesortby(
        this.state.original,
        val,
        this.state.isuser
      );
      console.log("sorted", sorted);
      this.setState({ proxy: sorted });
      if (sorted.length > 0) {
        const total = await getLaunchpadsaleCardLimit(
          sorted,
          0,
          this.state.interval,
          this.state.isuser
        );
        console.log("button ", sorted?.length, total);

        if (sorted?.length == total.index || !total) {
          this.setState({ button: false });
        }
        await this.sleep(1000);
        this.setState({ totalSales: total.saleInfoCards });
        this.setState({ interval: total.index + 1 });
        this.hideLoader();
      } else {
        this.setState({ button: false });
        this.setState({ totalSales: sorted });
        this.hideLoader();
      }
    }
  }

  async handlecoin(val) {
    var coins = this.state.coin;
    if (coins.length == 0) {
      coins.push(val);
    } else {
      if (coins.includes(val)) {
        const index = coins.indexOf(val);
        if (index > -1) {
          coins.splice(index, 1);
        }
      } else {
        coins.push(val);
      }
    }
    console.log("coin", coins);
    this.setState({ coin: coins });
  }

  async handlestatus(val) {
    var statuss = this.state.status;
    if (statuss.length == 0) {
      statuss.push(val);
    } else {
      if (statuss.includes(val)) {
        const index = statuss.indexOf(val);
        if (index > -1) {
          statuss.splice(index, 1);
        }
      } else {
        statuss.push(val);
      }
    }
    console.log("status", statuss);
    this.setState({ status: statuss });
  }

  async handletype(val) {
    var statuss = this.state.type;
    if (statuss.length == 0) {
      statuss.push(val);
    } else {
      if (statuss.includes(val)) {
        const index = statuss.indexOf(val);
        if (index > -1) {
          statuss.splice(index, 1);
        }
      } else {
        statuss.push(val);
      }
    }
    console.log("status", statuss);
    this.setState({ type: statuss });
  }

  async handleadvancefilter() {
    this.showLoader();
    if (
      this.state.coin.length == 0 &&
      this.state.status.length == 0 &&
      this.state.type.length == 0
    ) {
      this.loadData();
    } else {
      // let filtered = await tokenfilter(this.state.proxy , this.state.coin , this.state.status);
      let filtered = await tokenfilterlaunchpad(
        this.state.proxy,
        this.state.coin,
        this.state.status,
        this.state.type
      );
      this.setState({ proxy: filtered });
      console.log("filtered", filtered);
      if (filtered.length > 0) {
        const total = await getlaunchpaddatalimit(
          filtered,
          0,
          this.state.interval
        );
        console.log("button ", filtered?.length, total);
        // if(filtered?.length == 0){
        //   this.setState({button : false})
        // }
        await this.sleep(1000);
        this.setState({ totalSales: total });
        this.setState({ interval: 3 });
        this.hideLoader();
      } else {
        this.setState({ button: false });
        this.setState({ totalSales: filtered });
        this.hideLoader();
      }
    }
    this.hideLoader();
  }

  render() {
    const location = this.props.location.pathname.split("/")[1];
    const {
      totalSales,
      onGoingSales,
      upComingSales,
      search,
      isSearch,
      searchSale,
      accountInfo,
    } = this.state;

    return (
      <div id="loader_main">
        <div id="loader_div">
          <span className="spin_round"></span>
          <img src={favicon} className="logo_load" />
        </div>
        <div className="logo_overlay" id="logo_overlay">
          <Header onUpdate={this.loadData.bind(this)} />

          <div className="whole_sec pb-5">
            <div className="flex_side_right">
              <Sidebar />
              {/* ongoing_sec */}
              <div className="right_side_sec">
                {/* <Trendingslider /> */}
                <div className="right_side_spacing">
                  <div className="ongoing_sec proj_bg pb-5">
                    <div className="container px-1">
                      <div className="top_heqad_sec private_sec_bg mt-5 mt-md-0">
                        <div className="bg_outline1"></div>
                        {/* <div className="row">
                          <div className="col-12 col-md-12 flex_cen_col mt-md-5 mb-md-4">
                            <p className="banner_subtitle form_subhead">
                              White Paper
                            </p>

                        
                          </div>
                        </div> */}
                      </div>
                    </div>

                    <div className="inner_bg mt-4">
                      <div className="container px-1">
                        <Tab.Container defaultActiveKey="first">
                          <Row className="container-fluid w-100 mx-0 px-0">
                            <Col lg={12} className="px-4 tab_heads_fix px-xl-5">
                              <p className="banner_subtitle form_subhead">
                                White Paper
                              </p>
                              <Nav
                                variant="pills"
                                className="nav_green tab_scrl_xauto"
                              >
                                <Nav.Item>
                                  <Nav.Link eventKey="first" id="first">
                                    <p className="mb-0">Create Launchpad</p>
                                  </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                  <Nav.Link eventKey="second" id="second">
                                    <p className="mb-0">Create Fair Launch</p>
                                  </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                  <Nav.Link eventKey="third" id="third">
                                    <p className="mb-0">Token Creation</p>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="four" id="four">
                                    <p className="mb-0">Private sale</p>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="five" id="five">
                                    <p className="mb-0">In lock</p>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="six" id="six">
                                    <p className="mb-0">Airdrop</p>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="seven" id="seven">
                                    <p className="mb-0">KYC and Audit</p>
                                  </Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item>
                                  <Nav.Link eventKey="eight" id="eight">
                                    <p className="mb-0">BCoin</p>
                                  </Nav.Link>
                                </Nav.Item> */}
                              </Nav>
                              <hr class="hr_yellow mt-0 mt-5 mb-3 " />
                            </Col>

                            <Col
                              lg={12}
                              className="img_center_lg px-0 tab_cont_mar_top"
                            >
                              <div className="container container_custom  px-0 ml-0">
                                <Tab.Content>
                                  <Tab.Pane eventKey="first">
                                    <div>
                                      {/* <h3 className="h3_res">
                                        Create Launchpad
                                      </h3> */}
                                      <p className="text-white">
                                        BitDealz Launchpad is a decentralized
                                        platform allowing futuristic crypto
                                        projects to create and deploy their
                                        tokens, secure funds, opt for an initial
                                        token sale, and many more.
                                      </p>
                                      <p className="text-white">
                                        Follow these simple steps and seamlessly
                                        create & market your crypto projects in
                                        the BitDealz launchpad.
                                      </p>
                                      <h4 className="wp_sub_heads">
                                        Create Launchpad
                                      </h4>
                                      <p className="desc_grey_txt">
                                        To get an idea on how to launch a
                                        presale on a BitDealz Launchpad, visit
                                        ……,{" "}
                                      </p>
                                      <div className="pl-3 pl-sm-4">
                                        <h5 className="wp-sub_heads_opt">
                                          Token Verification
                                        </h5>
                                        <p className="desc_grey_txt">
                                          First, verify the token before
                                          launching a presale. To do,
                                        </p>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Connect the wallet, then go to ……
                                          </li>
                                          <li className="mb-2">
                                            Now, input the address of an
                                            existing or new token (for a fair
                                            launch)
                                          </li>
                                          <li className="mb-2">
                                            Now, select the appropriate fee.
                                          </li>
                                          <li className="mb-2">
                                            Next, choose BNB or other tokens.
                                          </li>
                                          <li className="mb-2">
                                            After selecting the token type, view
                                            the token address to confirm it and
                                            click Approve.
                                          </li>
                                          <li className="mb-2">
                                            Metamask or another wallet suggests
                                            you verify and confirm the
                                            transaction. Now, finish the token
                                            verification process by clicking on
                                            the Confirm button.
                                          </li>
                                        </ul>
                                        <Row className="justify-content-center">
                                          <Col md={10}>
                                            <div className="my-4 card_bg wp_img_wrp">
                                              <img
                                                src={image1}
                                                alt="Token Verification"
                                                className="img-fluid"
                                              />
                                            </div>
                                          </Col>
                                        </Row>

                                        <h5 className="wp-sub_heads_opt">
                                          Enter Defi Launchpad Info
                                        </h5>
                                        <p className="desc_grey_txt">
                                          After token verification, enter the
                                          necessary details for the Defi
                                          launchpad in the appropriate fields.
                                          Remember these tips while entering the
                                          information of the launchpad,
                                        </p>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Use Positive numbers
                                          </li>
                                          <li className="mb-2">
                                            Turn on the Whitelist to include a
                                            Whitelist of presale contributors.
                                            Remember that you can Turn on or off
                                            the Whitelist option whenever you
                                            want.
                                          </li>
                                          <li className="mb-2">
                                            Enter a reasonable soft cap value
                                            (equal to or greater than 50% of the
                                            hard cap).
                                          </li>
                                          <li className="mb-2">
                                            Choose refund types (refund or burn)
                                            from the drop-down list.
                                          </li>
                                          <li className="mb-2">
                                            Now, enter the value of funds (in
                                            terms of percentage) to distribute
                                            to Pancakeswap liquidity in the
                                            Pancakeswap Liquidity field (Minimum
                                            - 51% & Max - 100%).
                                          </li>
                                          <li className="mb-2">
                                            Enter the lock-up time for the
                                            liquidity pool in the Liquidity
                                            lock-up tab.
                                          </li>
                                          <li className="mb-2">
                                            Pancakswap listing rate is the
                                            initial rate of the liquidity pool
                                            (1 BNB = xtokens), which should be
                                            lower than the presale rate to
                                            enable a higher listing on
                                            Pancakeswap.
                                          </li>
                                          <li className="mb-2">
                                            Select the Vesting Contributor
                                            feature to check the token required
                                            to create a presale pool (indicated
                                            near the next/back button).
                                          </li>
                                        </ul>
                                        <p className="desc_grey_txt">
                                          Note - Start time should be earlier
                                          than the end time.
                                        </p>
                                        <p className="desc_grey_txt">
                                          Presale Rate - Total tokens a user
                                          receives at the presale.
                                        </p>
                                        <Row className="justify-content-center">
                                          <Col md={10}>
                                            <div className="my-4 card_bg wp_img_wrp">
                                              <img
                                                src={image2}
                                                alt="Token Verification"
                                                className="img-fluid"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <h5 className="wp-sub_heads_opt">
                                          Affiliate Program Percentage
                                        </h5>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            While creating the token in the
                                            token launchpad, you get an
                                            affiliate program percentage of your
                                            token’s value.
                                          </li>
                                          <li className="mb-2">
                                            If someone buys or sells the token
                                            using an affiliate link, you will
                                            receive a percentage of the
                                            transaction value as a reward for
                                            bringing in new users or customers.
                                          </li>
                                        </ul>
                                        <h5 className="wp-sub_heads_opt">
                                          Other Additional Information
                                        </h5>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            In this section, enter the website
                                            and Logo URL
                                          </li>
                                          <li className="mb-2">
                                            The logo URL must be supported by an
                                            image extension (For instance, png,
                                            jpeg).
                                          </li>
                                          <li className="mb-2">
                                            After giving the necessary details,
                                            click{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Next.
                                            </span>
                                          </li>
                                        </ul>

                                        <Row className="justify-content-center">
                                          <Col md={10}>
                                            <div className="my-4 card_bg wp_img_wrp">
                                              <img
                                                src={image3}
                                                alt="Token Verification"
                                                className="img-fluid"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <h5 className="wp-sub_heads_opt">
                                          Finish
                                        </h5>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Review all the entered details
                                          </li>
                                          <li className="mb-2">
                                            Go to the previous page to make any
                                            changes
                                          </li>
                                          <li className="mb-2">
                                            Finally, click
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Submit.
                                            </span>
                                          </li>
                                          <li className="mb-2">
                                            Now, Metamask or another wallet asks
                                            you to confirm the transaction where
                                            the transaction fee is shown.
                                          </li>
                                          <li className="mb-2">
                                            Now, click the
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Confirm{" "}
                                            </span>
                                            button.
                                          </li>
                                        </ul>
                                      </div>
                                      <Row className="justify-content-center">
                                        <Col md={10}>
                                          <div className="my-4 card_bg wp_img_wrp">
                                            <img
                                              src={image4}
                                              alt="Token Verification"
                                              className="img-fluid"
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <h4 className="wp_sub_heads">
                                        Update a Launchpad
                                      </h4>
                                      <p className="desc_grey_txt">
                                        Follow this procedure to update the
                                        information on the launchpad.
                                      </p>
                                      <div className="pl-3 pl-sm-4">
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">Visit,</li>
                                          <li className="mb-2">
                                            Click on My Contributions to view
                                            your Pool.
                                          </li>
                                          <li className="mb-2">
                                            Next, click View Pool.
                                          </li>

                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image5}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            To edit the pool, click the button
                                            highlighted in the below image. In
                                            this section, you can edit
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image6}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <p className="desc_grey_txt">
                                            <span className="pr-2">1.</span>{" "}
                                            Logo URL. It must have an associated
                                            image extension at the end. (Needed)
                                          </p>
                                          <p className="desc_grey_txt">
                                            <span className="pr-2">2.</span>{" "}
                                            Website (Needed)
                                          </p>
                                          <p className="desc_grey_txt">
                                            <span className="pr-2">3.</span>{" "}
                                            Facebook
                                          </p>
                                          <p className="desc_grey_txt">
                                            <span className="pr-2">4.</span>{" "}
                                            Twitter
                                          </p>
                                          <p className="desc_grey_txt">
                                            <span className="pr-2">5.</span>{" "}
                                            Github
                                          </p>
                                          <p className="desc_grey_txt">
                                            <span className="pr-2">6.</span>
                                            Telegram
                                          </p>
                                          <p className="desc_grey_txt">
                                            <span className="pr-2">7.</span>
                                            Reddit
                                          </p>
                                          <p className="desc_grey_txt">
                                            <span className="pr-2">8.</span>
                                            Launchpad description.
                                          </p>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image7}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            Then, click{" "}
                                            <span className="wp_grn_hts">
                                              Update.
                                            </span>
                                          </li>
                                          <li className="mb-2">
                                            Now, Metamask or the selected wallet
                                            asks you to confirm the transaction
                                            by clicking the
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Confirm{" "}
                                            </span>
                                            button while the corresponding fee
                                            amount appears on the screen.
                                          </li>
                                        </ul>
                                      </div>
                                      <h4 className="wp_sub_heads">
                                        Finalize a Launchpad
                                      </h4>
                                      <p className="desc_grey_txt">
                                        To finalize the presale,
                                      </p>
                                      <div className="pl-3 pl-sm-4">
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Click <FaArrowRight /> Launchpad{" "}
                                            <FaArrowRight /> Launchpad list{" "}
                                            <FaArrowRight /> click my
                                            contribution{" "}
                                          </li>
                                          <li className="mb-2">
                                            Next, click the created presale.
                                          </li>
                                          <li className="mb-2">
                                            Now, click{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              View pool{" "}
                                            </span>
                                          </li>
                                          <li className="mb-2">
                                            Click Stop sale if you want to stop
                                            the sale manually.
                                          </li>

                                          <li className="mb-2">
                                            In the Pool Actions section, click{" "}
                                            <span className="wp_grn_hts">
                                              Finalize
                                            </span>{" "}
                                            to complete the launchpad & list the
                                            token or cancel it
                                          </li>

                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image8}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            Now, Metamask or another selected
                                            wallet asks you to confirm the
                                            transaction by clicking the
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Confirm{" "}
                                            </span>
                                            button. The corresponding fee amount
                                            appears on the screen.
                                          </li>
                                        </ul>
                                      </div>
                                      <h4 className="wp_sub_heads">
                                        Cancel a Launchpad
                                      </h4>
                                      <p className="desc_grey_txt">
                                        To cancel a Presale, follow the given
                                        steps.
                                      </p>
                                      <div className="pl-3 pl-sm-4">
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Connect the wallet.
                                          </li>
                                          <li className="mb-2">
                                            Visit your Presale pool.
                                          </li>
                                          <li className="mb-2">
                                            Click the{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Cancel Pool{" "}
                                            </span>{" "}
                                            button in the owner zone.
                                          </li>

                                          <li className="mb-2">
                                            Now, Metamask asks you to confirm
                                            the transaction by clicking the{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Confirm
                                            </span>{" "}
                                            button while the screen indicates
                                            the transaction fee. Click the
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Confirm
                                            </span>{" "}
                                            button to finish the process.
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image9}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </ul>
                                      </div>
                                      <h5 className="wp_h5_itc">
                                        How to withdraw tokens from the
                                        cancelled Pool?
                                      </h5>
                                      <div className="pl-3 pl-sm-4">
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Exclude fees, rewards, and max tax
                                            in the contract or Skip this step if
                                            the token is free of fee, rewards,
                                            or max tx.
                                          </li>
                                          <li className="mb-2">
                                            Click on the token address on the
                                            launchpad page or access the
                                            contract address from the BSC scan.
                                          </li>
                                          <li className="mb-2">
                                            Click Contract and choose Write the
                                            contract. Next, click{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Connect to Web3{" "}
                                            </span>{" "}
                                            and use the owner's address.
                                          </li>

                                          <li className="mb-2">
                                            Click{" "}
                                            <span className="wp_grn_hts">
                                              Ctrl+F
                                            </span>{" "}
                                            and type
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Exclude or Exempt
                                            </span>{" "}
                                            to enter the Presale address and
                                            select{" "}
                                            <span className="wp_grn_hts">
                                              Write
                                            </span>{" "}
                                            to exclude, fee, etc., for the
                                            presale address.
                                          </li>

                                          <li className="mb-2">
                                            Now, Metamask asks you to confirm
                                            the transaction by clicking the{" "}
                                            <span className="wp_grn_hts">
                                              Confirm
                                            </span>{" "}
                                            button while the corresponding fee
                                            amount appears on the screen. Enter
                                            <span className="wp_grn_hts">
                                              {" "}
                                              True
                                            </span>{" "}
                                            if you see exclude (bool)
                                          </li>
                                          <li className="mb-2">
                                            Next, click on the{" "}
                                            <span className="wp_grn_hts">
                                              Withdraw cancelled tokens button.
                                            </span>{" "}
                                          </li>
                                        </ul>
                                      </div>
                                      <h4 className="wp_sub_heads">
                                        Add/Remove Whitelists
                                      </h4>
                                      <p className="desc_grey_txt">
                                        Indicates a set of platform-verified
                                        addresses which can participate in the
                                        presale. Note that these addresses can
                                        be enabled/ disabled during presale
                                        creation, before or during the presale.
                                      </p>
                                      <div className="pl-3 pl-sm-4">
                                        <h5 className="wp-sub_heads_opt">
                                          To Enable Whitelisting
                                        </h5>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image10}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>

                                          <li className="mb-2">
                                            Click Enable or Disable Whitelist in
                                            the Whitelist section to
                                            enable/disable it during launchpad
                                            creation (to be specific while
                                            entering Defi launchpad info)
                                          </li>

                                          <li className="mb-2">
                                            Remember to check{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Sale Method: Whitelist Only{" "}
                                            </span>{" "}
                                            while submitting the launchpad
                                          </li>

                                          <li className="mb-2">
                                            Click the{" "}
                                            <span className="wp_grn_hts">
                                              Whitelist
                                            </span>{" "}
                                            button in the owner zone to enable
                                            the Whitelist while updating the
                                            launchpad
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image11}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            Next, the wallet asks you to confirm
                                            the transaction by clicking the{" "}
                                            <span className="wp_grn_hts">
                                              Confirm
                                            </span>{" "}
                                            button, and the corresponding fee
                                            amount appears on the screen.
                                          </li>
                                        </ul>
                                        <h5 className="wp-sub_heads_opt">
                                          To Disable the Whitelist,
                                        </h5>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Click{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Disable Whitelist{" "}
                                            </span>{" "}
                                            in the owner zone of the launchpad.
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image12}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>

                                          <li className="mb-2">
                                            After clicking, Metamask asks you to
                                            confirm the transaction by clicking
                                            the{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Confirm{" "}
                                            </span>{" "}
                                            button. The corresponding fee amount
                                            appears on the screen.
                                          </li>

                                          <li className="mb-2">
                                            Disable the Whitelist during the
                                            presale of a sale that becomes a
                                            public sale instead of a whitelisted
                                            presale.
                                          </li>
                                        </ul>
                                        <h5 className="wp-sub_heads_opt">
                                          To Add a Whitelist,
                                        </h5>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Click on{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Add user to Whitelist{" "}
                                            </span>{" "}
                                            to add the wallet address of presale
                                            participants at any time in the
                                            owner zone of the launchpad.
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image13}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>

                                          <li className="mb-2">
                                            A pop-up appears where you can add
                                            the participant's wallet addresses.
                                            A maximum of 800 users can be added
                                            to the Whitelist each time.
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image14}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            Click{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Add users{" "}
                                            </span>{" "}
                                            after entering the addresses. Now,
                                            Metamask asks you to confirm the
                                            transaction by clicking the{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Confirm{" "}
                                            </span>{" "}
                                            button, and the screen shows the
                                            corresponding fee amount.
                                          </li>
                                        </ul>
                                        <h5 className="wp-sub_heads_opt">
                                          To Remove the Whitelist,
                                        </h5>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Click on{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Add user to Whitelist
                                            </span>{" "}
                                            in the owner zone of the Launchpad
                                            to remove the wallet address of
                                            presale participants at any time.
                                          </li>{" "}
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image15}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            Next, a pop-up appears on the screen
                                            where you can remove the unwanted
                                            wallet address by pasting it &
                                            clicking{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Remove Users.
                                            </span>{" "}
                                            Each address appears on a new line.
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image16}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            Metamask asks you to confirm the
                                            transaction by clicking the{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Confirm{" "}
                                            </span>{" "}
                                            button. The corresponding fee amount
                                            appears on the screen.{" "}
                                          </li>
                                        </ul>
                                      </div>{" "}
                                      <h4 className="wp_sub_heads">
                                        Create a Presale Using Stablecoin
                                      </h4>
                                      <p className="desc_grey_txt">
                                        On BitDealz Launchpad, launch a presale
                                        or fair launch using stablecoins (BUSD,
                                        USDT) on a supported blockchain.
                                      </p>
                                      <div className="pl-3 pl-sm-4">
                                        <h5 className="wp-sub_heads_opt">
                                          Token Verification
                                        </h5>
                                        <p className="desc_grey_txt">
                                          Follow the guidelines to verify the
                                          token,
                                        </p>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Connect the wallet, then visit
                                          </li>

                                          <li className="mb-2">
                                            Input the address of an existing or
                                            new token for the launchpad.
                                          </li>

                                          <li className="mb-2">
                                            Select the appropriate fee & the
                                            appropriate stablecoin.
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image17}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            After selecting the above, check the
                                            token name & symbol and click{" "}
                                            <span className="wp_grn_hts">
                                              Approve.
                                            </span>{" "}
                                          </li>
                                          <li className="mb-2">
                                            Remember that the Stablecoin pair
                                            works with standard tokens only and
                                            not with taxed tokens (Liquidity
                                            Generator Token).
                                          </li>
                                          <li className="mb-2">
                                            Next, the wallet (For instance,
                                            Metamask) suggests you verify and
                                            confirm the transaction.
                                          </li>
                                          <li className="mb-2">
                                            Click on the{" "}
                                            <span className="wp_grn_hts">
                                              Confirm
                                            </span>{" "}
                                            button to finish the token
                                            verification process. Remember that
                                            presale can be supported using the
                                            supported stablecoin in the
                                            appropriate blockchain.
                                          </li>
                                        </ul>
                                        <h5 className="wp-sub_heads_opt">
                                          Enter Defi Launchpad Info
                                        </h5>
                                        <p className="desc_grey_txt">
                                          Next, Give in the Defi launchpad
                                          information that needs to be raised.
                                          During the process, remember to
                                        </p>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Use{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Positive{" "}
                                            </span>{" "}
                                            numbers
                                          </li>
                                          <li className="mb-2">
                                            Turn on the Whitelist to include a
                                            Whitelist of presale contributors.
                                            Remember that you can Turn on or off
                                            the Whitelist option whenever you
                                            want.
                                          </li>
                                          <li className="mb-2">
                                            Enter a reasonable soft cap value
                                            (equal to or greater than 50% of the
                                            hard cap).
                                          </li>
                                          <li className="mb-2">
                                            Choose refund types (refund or burn)
                                            from the drop-down list.
                                          </li>
                                          <li className="mb-2">
                                            Now, enter the value of funds (in
                                            terms of percentage) to distribute
                                            to liquidity on Pancakeswap in the
                                            Pancakeswap Liquidity field (Minimum
                                            - 51% & Max - 100%).
                                          </li>
                                          <li className="mb-2">
                                            Enter the lock-up time for the
                                            liquidity pool in the Liquidity
                                            lock-up tab.
                                          </li>
                                          <li className="mb-2">
                                            Pancakswap listing rate is the
                                            initial rate of the liquidity pool
                                            (1 BNB = xtokens), which should be
                                            lower than the presale rate to
                                            enable a higher listing on
                                            Pancakeswap.
                                          </li>
                                          <li className="mb-2">
                                            Select the{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Vesting Contributor{" "}
                                            </span>{" "}
                                            feature to check the token required
                                            to create a presale pool.
                                          </li>
                                        </ul>
                                        <p className="desc_grey_txt mb-0">
                                          <span className="wp_grn_hts">
                                            Note
                                          </span>{" "}
                                          - Start time should be earlier than
                                          the end time.
                                        </p>
                                        <p className="desc_grey_txt">
                                          <span className="wp_grn_hts">
                                            Presale Rate
                                          </span>{" "}
                                          - Indicates the number of tokens a
                                          user can receive during the presale
                                          stage.
                                        </p>
                                        <Row className="justify-content-center">
                                          <Col md={10}>
                                            <div className="my-4 card_bg wp_img_wrp">
                                              <img
                                                src={image18}
                                                alt="Token Verification"
                                                className="img-fluid"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <h5 className="wp-sub_heads_opt">
                                          Other Additional Information
                                        </h5>

                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            In this section, enter the website
                                            and Logo URL{" "}
                                          </li>
                                          <li className="mb-2">
                                            The logo URL must be supported by an
                                            image extension (For instance, png,
                                            jpeg).
                                          </li>
                                          <li className="mb-2">
                                            After giving the necessary details,
                                            click{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Next.{" "}
                                            </span>{" "}
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image19}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </ul>
                                        <h5 className="wp-sub_heads_opt">
                                          Finish
                                        </h5>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Review all the entered details
                                          </li>
                                          <li className="mb-2">
                                            Go to the previous page to make any
                                            changes
                                          </li>
                                          <li className="mb-2">
                                            Finally, click{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Submit.{" "}
                                            </span>{" "}
                                          </li>
                                          <li className="mb-2">
                                            Metamask or another wallet asks you
                                            to confirm the transaction and the
                                            screen shows the associated
                                            transaction fee.
                                          </li>
                                          <li className="mb-2">
                                            Now, click the{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Confirm{" "}
                                            </span>{" "}
                                            button.
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image20}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </ul>
                                        <p className="desc_grey_txt mb-0">
                                          <span className="wp_grn_hts">
                                            Note
                                          </span>{" "}
                                          - For tokens with rebase, burns, or
                                          other special transfers, find a way to
                                          Whitelist multiple addresses or turn
                                          down the special transfer events
                                          (setting fees to, for instance, during
                                          the pres
                                        </p>
                                      </div>
                                      <h4 className="wp_sub_heads">
                                        Create a Presale (Manual Listing)
                                      </h4>{" "}
                                      <div className="pl-3 pl-sm-4">
                                        <h5 className="wp-sub_heads_opt">
                                          Token Verification
                                        </h5>
                                        <p className="desc_grey_txt">
                                          To verify the token
                                        </p>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Connect the wallet, then visit
                                          </li>

                                          <li className="mb-2">
                                            Input the address of an existing or
                                            new token in the launchpad.
                                          </li>

                                          <li className="mb-2">
                                            Select BNB or other tokens & then,
                                            Choose the appropriate fee.
                                          </li>
                                          <li className="mb-2">
                                            Select Manual Listing In the listing
                                            options.
                                          </li>
                                          <li className="mb-2">
                                            Next, click{" "}
                                            <span className="wp_grn_hts">
                                              Approve
                                            </span>{" "}
                                            after token address verification.
                                          </li>
                                          <li className="mb-2">
                                            Now, Metamask suggests you verify
                                            and confirm the transaction. Click
                                            on the{" "}
                                            <span className="wp_grn_hts">
                                              Confirm
                                            </span>{" "}
                                            button to finish the token
                                            verification process.
                                          </li>
                                        </ul>
                                        <h5 className="wp-sub_heads_opt">
                                          Enter Presale Info
                                        </h5>
                                        <p className="desc_grey_txt">
                                          Enter the Launchpad information after
                                          token verification. During the
                                          process, remember to
                                        </p>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Use{" "}
                                            <span className="wp_grn_hts">
                                              Positive
                                            </span>{" "}
                                            numbers
                                          </li>

                                          <li className="mb-2">
                                            Turn on the Whitelist to include a
                                            Whitelist of presale contributors.
                                            Remember that you can Turn on or off
                                            the Whitelist option whenever you
                                            want.
                                          </li>

                                          <li className="mb-2">
                                            Enter a reasonable soft cap value
                                            (equal to or greater than 50% of the
                                            hard cap).
                                          </li>
                                          <li className="mb-2">
                                            Choose refund types (refund or burn)
                                            from the drop-down list.
                                          </li>
                                          <li className="mb-2">
                                            Now, enter the value of funds (in
                                            terms of percentage) to distribute
                                            to liquidity on Pancakeswap in the
                                            Pancakeswap Liquidity field (Minimum
                                            - 51% & Max - 100%).
                                          </li>
                                          <li className="mb-2">
                                            Enter the lock-up time for the
                                            liquidity pool in the Liquidity
                                            lock-up tab.
                                          </li>
                                          <li className="mb-2">
                                            Pancakswap listing rate is the
                                            initial rate of the liquidity pool
                                            (1 BNB = xtokens), which should be
                                            lower than the presale rate to
                                            enable a higher listing on
                                            Pancakeswap.
                                          </li>
                                          <li className="mb-2">
                                            Select the{" "}
                                            <span className="wp_grn_hts">
                                              Vesting Contributor
                                            </span>{" "}
                                            feature to check the required token
                                            to create a presale pool.
                                          </li>
                                        </ul>
                                        <p className="desc_grey_txt mb-0">
                                          <span className="wp_grn_hts">
                                            Note
                                          </span>{" "}
                                          - Start time should be earlier than
                                          the end time. .
                                        </p>
                                        <h5 className="wp-sub_heads_opt">
                                          Other Additional Information
                                        </h5>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            In this section, enter the website
                                            and Logo URL
                                          </li>

                                          <li className="mb-2">
                                            The logo URL must be supported by an
                                            image extension (For instance, png,
                                            jpeg).
                                          </li>

                                          <li className="mb-2">
                                            After giving the necessary details,
                                            click{" "}
                                            <span className="wp_grn_hts">
                                              Next.
                                            </span>{" "}
                                          </li>
                                        </ul>
                                        <h5 className="wp-sub_heads_opt">
                                          Finish
                                        </h5>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Verify the entered details, and to
                                            make any changes go back to the
                                            previous page and click{" "}
                                            <span className="wp_grn_hts">
                                              Submit.
                                            </span>{" "}
                                          </li>
                                          <li className="mb-2">
                                            In the last screen, Metamask asks
                                            you to confirm the transaction by
                                            clicking the{" "}
                                            <span className="wp_grn_hts">
                                              Confirm
                                            </span>{" "}
                                            button and indicates the transaction
                                            fee needs to be paid.
                                          </li>
                                        </ul>
                                      </div>
                                      <h4 className="wp_sub_heads">
                                        How to Finalize a Presale (Manual
                                        Listing)?
                                      </h4>{" "}
                                      <p className="desc_grey_txt">
                                        Follow the given instructions to
                                        finalize the presale,
                                      </p>
                                      <div className="pl-3 pl-sm-4">
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Hire a trusted developer at…. Or
                                            Verify the developed contract
                                          </li>

                                          <li className="mb-2">
                                            Exclude fees, rewards, and max tax
                                            in the contract. Skip this step if
                                            the token is free of fee, rewards,
                                            or max tx.
                                          </li>

                                          <li className="mb-2">
                                            Click on the token address on the
                                            launchpad page or access the
                                            contract address from the BSC scan.
                                          </li>
                                          <li className="mb-2">
                                            Click Contract <FaArrowRight />{" "}
                                            Write the contract.
                                          </li>
                                          <li className="mb-2">
                                            Next, click
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Connect to Web3
                                            </span>{" "}
                                            and use the owner's address.
                                          </li>
                                          <li className="mb-2">
                                            Click{" "}
                                            <span className="wp_grn_hts">
                                              Ctrl+F
                                            </span>{" "}
                                            and type{" "}
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Exclude or Exempt,
                                            </span>{" "}
                                            then enter the Presale address
                                            button to finish the token
                                            verification process.
                                          </li>
                                          <li className="mb-2">
                                            Next, click on
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Write
                                            </span>{" "}
                                            to exclude, fee, etc., for the
                                            presale address.
                                          </li>
                                          <li className="mb-2">
                                            Now, a wallet or Metamask asks you
                                            to confirm the transaction by
                                            clicking the
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Confirm
                                            </span>{" "}
                                            button. The corresponding fee amount
                                            appears on the screen.
                                          </li>
                                        </ul>
                                      </div>
                                      <p className="wp_h5_itc">
                                        Enter{" "}
                                        <span className="wp_grn_hts">
                                          {" "}
                                          True{" "}
                                        </span>
                                        if you see exclude (bool)
                                      </p>
                                      <p className="desc_grey_txt">
                                        Click{" "}
                                        <span className="wp_grn_hts">
                                          {" "}
                                          Finalize{" "}
                                        </span>
                                        to complete the launchpad & list the
                                        token or cancel it, in the Pool Actions.
                                      </p>
                                      <p className="desc_grey_txt">
                                        Now, again Metamask asks you to confirm
                                        the transaction by clicking the{" "}
                                        <span className="wp_grn_hts">
                                          {" "}
                                          Confirm{" "}
                                        </span>
                                        button while the corresponding
                                        transaction fee appears on the screen.
                                        Click the{" "}
                                        <span className="wp_grn_hts">
                                          Confirm{" "}
                                        </span>
                                        button to finish the process.
                                      </p>
                                      <p className="desc_grey_txt">
                                        Remember that in this section, you can
                                        also select{" "}
                                        <span className="wp_grn_hts">
                                          {" "}
                                          Set Claim Time or Claim right now{" "}
                                        </span>
                                        to enable contributors to claim tokens
                                        later or at that moment.{" "}
                                      </p>
                                      <h5 className="wp-sub_heads_opt">
                                        Manual Listing
                                      </h5>
                                      <h5 className="wp-sub_heads_opt">
                                        For Project Owners
                                      </h5>
                                      <p className="desc_grey_txt">
                                        List tokens manually on the Dex platform
                                        when the pool ends. Manually list the
                                        tokens after finalizing the pool
                                        (automatic listing isn't available).
                                      </p>
                                      <h5 className="wp-sub_heads_opt">
                                        For Contributors
                                      </h5>
                                      <p className="desc_grey_txt">
                                        Once the project owner finalizes the
                                        pool, contributors receive tokens when
                                        liquidity is added for the project on
                                        Dex.
                                      </p>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="second">
                                    <div>
                                      <h4 className="wp_sub_heads">
                                        What is a Fair Launch?
                                      </h4>
                                      <p className="desc_grey_txt">
                                        A Fair launch is an event where tokens
                                        are sold at a fair price and in a fair
                                        manner to the participants.
                                      </p>
                                      <h4 className="wp_sub_heads">
                                        How to Create a Fair Launch?
                                      </h4>
                                      <p className="desc_grey_txt">
                                        Follow the guidelines to take part in
                                        the Fair Launch.
                                      </p>
                                      <p className="desc_grey_txt">
                                        <span className="wp_grn_hts">
                                          Note -{" "}
                                        </span>
                                        Token Price = Total BNB collected
                                      </p>
                                      <p className="desc_grey_txt">
                                        {" "}
                                        Total Tokens raised on Fair Launch
                                      </p>
                                      <p className="desc_grey_txt">
                                        To verify the token
                                      </p>
                                      <div className="pl-3 pl-sm-4">
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Connect the wallet, then visit
                                          </li>

                                          <li className="mb-2">
                                            For a fair launch, input the address
                                            of an existing or new token.
                                          </li>
                                          <li className="mb-2">
                                            Choose BNB (or other tokens)
                                          </li>
                                          <li className="mb-2">
                                            Now, select the appropriate fee.
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image21}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            Finally, the wallet or Metamask
                                            suggests you verify and confirm the
                                            transaction. Click on the “confirm”
                                            button to finish the token transfer
                                            process.
                                          </li>
                                        </ul>
                                        <p className="wp_h5_itc">
                                          Remember that the Stablecoin pair
                                          works with standard tokens and ensure
                                          that the token has an “Exclude
                                          Transfer fee” function if it has
                                          transfer fees.
                                        </p>
                                        <h5 className="wp-sub_heads_opt">
                                          Enter Fair Launch Info
                                        </h5>
                                        <p className="desc_grey_txt">
                                          During giving fair launch information
                                          after a token verification process,
                                          remember to
                                        </p>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Use positive numbers
                                          </li>

                                          <li className="mb-2">
                                            Enter a reasonable soft cap number
                                          </li>
                                          <li className="mb-2">
                                            Mark down the total selling amount (
                                            sum of tokens sold during the
                                            presale stage).
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image22}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            Check{" "}
                                            <span className="wp_grn_hts">
                                              Max buy per user
                                            </span>{" "}
                                            which indicates the max contribution
                                            per user in the fair launch.
                                          </li>
                                          <li className="mb-2">
                                            Select the appropriate router
                                            option. (For instance, Pancakeswap).
                                          </li>
                                          <li className="mb-2">
                                            Enter the amount for liquidity
                                            (Minimum - 51% & Max - 100%)
                                          </li>
                                          <li className="mb-2">
                                            Enter the time lock-up time for the
                                            liquidity pool, for instance, 150
                                            days.
                                          </li>
                                        </ul>{" "}
                                        <p className="desc_grey_txt">
                                          Note - Start time should be earlier
                                          than the end time.
                                        </p>
                                        <h5 className="wp-sub_heads_opt">
                                          Other Additional Information
                                        </h5>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            In this section, enter the website
                                            and Logo URL
                                          </li>

                                          <li className="mb-2">
                                            The logo URL must be supported by an
                                            image extension (For instance, png,
                                            jpeg).
                                          </li>
                                          <li className="mb-2">
                                            After giving the necessary details,
                                            click{" "}
                                            <span className="wp_grn_dts">
                                              Next.
                                            </span>
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image23}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </ul>{" "}
                                        <h5 className="wp-sub_heads_opt">
                                          Finish
                                        </h5>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Review all the entered details
                                          </li>

                                          <li className="mb-2">
                                            Go to the previous page to make any
                                            changes
                                          </li>
                                          <li className="mb-2">
                                            Finally, click{" "}
                                            <span className="wp_grn_dts">
                                              Submit.
                                            </span>
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image24}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            Metamask or another wallet asks you
                                            to confirm the transaction where the
                                            transaction fee is shown.
                                          </li>
                                          <li className="mb-2">
                                            Now, click the{" "}
                                            <span className="wp_grn_dts">
                                              Confirm{" "}
                                            </span>
                                            button.
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image25}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </ul>{" "}
                                        <p className="desc_grey_txt">
                                          When the Fair Launch meets its soft
                                          cap limit, follow the below steps to
                                          list the token, once your
                                        </p>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">Visit,</li>

                                          <li className="mb-2">
                                            Click <FaArrowRight /> My
                                            Contribution to view the pool
                                          </li>
                                          <li className="mb-2">
                                            Finalize the Fair launch with a
                                            “Sale End” status.
                                          </li>
                                          <li className="mb-2">
                                            On the token section, click on “View
                                            Pool”.
                                          </li>
                                          <li className="mb-2">
                                            Don’t include fees or dividends on
                                            BSC coins.
                                          </li>
                                          <li className="mb-2">
                                            Next, click the Finalize button in
                                            the Pool Actions section to list
                                            your token or cancel it.
                                          </li>
                                          <li className="mb-2">
                                            Remember to exclude fees, taxes,
                                            rewards, etc., for the presale
                                            address before finalizing the Fair
                                            Launch.
                                          </li>
                                          <li className="mb-2">
                                            A screen indicates the transaction
                                            fee, and the wallet (for instance,
                                            Metamask) asks you to confirm the
                                            transaction by clicking the
                                            “confirm” button.
                                          </li>
                                        </ul>{" "}
                                      </div>
                                      <h4 className="wp_sub_heads">
                                        Launchpad List
                                      </h4>
                                      <p className="desc_grey_txt">
                                        Here you can check the list of projects
                                        (KYC verified and others) available on
                                        the BitDealz Launchpad
                                      </p>
                                      <h4 className="wp_sub_heads">
                                        Affiliate Program Link
                                      </h4>
                                      <div className="pl-3 pl-sm-4">
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            To get started with an affiliate
                                            link in a token launchpad, your
                                            account needs to be set up and
                                            verified.
                                          </li>
                                          <li className="mb-2">
                                            After getting access to your unique
                                            affiliate link, share it with anyone
                                            interested in participating in token
                                            sales.
                                          </li>
                                          <li className="mb-2">
                                            When someone clicks on the link and
                                            invests in the token launchpad, you
                                            get a percentage of your referral’s
                                            investment
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="third">
                                    <div>
                                      {" "}
                                      <h4 className="wp_sub_heads">
                                        Token Creation
                                      </h4>
                                      <p className="desc_grey_txt">
                                        Follow the simple steps to develop a
                                        standard token using the Metamask wallet
                                        on the BitDealz launchpad
                                      </p>
                                      <div className="pl-3 pl-sm-4">
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">Visit,</li>

                                          <li className="mb-2">
                                            Go to Create section and click Token
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image26}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            Select “Standard Token” in the Token
                                            Type section
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image27}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            Next, give the essential information
                                            and click on Create Token.
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image28}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </ul>
                                        <h5 className="wp_h5_itc">
                                          Things to remember
                                        </h5>
                                        <p className="wp_h5_itc">
                                          Remember that Decimals should be equal
                                          to or greater than 2.
                                        </p>
                                        <p className="wp_h5_itc">
                                          Enter details for all the required
                                          fields.
                                        </p>
                                        <p className="wp_h5_itc">
                                          Enter only the positive number for
                                          total supply & decimals.
                                        </p>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Now, a wallet or Metamask asks you
                                            to confirm the transaction and
                                            indicates the transaction fee. Next,
                                            click the “confirm” button.
                                          </li>
                                        </ul>{" "}
                                      </div>
                                      <h4 className="wp_sub_heads">
                                        Liquidity Generator Token
                                      </h4>
                                      <p className="desc_grey_txt">
                                        Develop a Liquidity Generator token
                                        using the Metamask wallet from a
                                        compatible device.
                                      </p>
                                      <div className="pl-3 pl-sm-4">
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">Visit,</li>

                                          <li className="mb-2">
                                            Click on Create <FaArrowRight />{" "}
                                            Token
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image29}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            Now, Token Type section{" "}
                                            <FaArrowRight /> Liquidity Generator
                                            Token
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image30}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </ul>{" "}
                                      </div>
                                      <p className="desc_grey_txt">
                                        Enter the appropriate value for the
                                        following fields.
                                      </p>
                                      <p className="wp_grn_hts mb-0">
                                        Transaction fee to generate the yield
                                      </p>{" "}
                                      <p className="desc_grey_txt">
                                        Total tokens (in terms of percentages)
                                        are allocated to all the token holders
                                        during each transaction.
                                      </p>{" "}
                                      <p className="wp_grn_hts mb-0">
                                        Transaction fee to generate liquidity
                                      </p>
                                      <p className="desc_grey_txt">
                                        Tokens allocated(%) to the liquidity
                                        pool during each transaction.
                                      </p>
                                      <p className="wp_grn_hts mb-0">
                                        Max transaction percent
                                      </p>
                                      <p className="desc_grey_txt">
                                        Maximum allowed limit per transaction
                                        (Percentage).
                                      </p>
                                      <p className="wp_grn_hts mb-0">
                                        Charity Percent
                                      </p>
                                      <p className="desc_grey_txt">
                                        Charity percent represents tokens
                                        (Percentage) distributed to charity
                                        addresses during each transaction.
                                      </p>
                                      <p className="wp_grn_hts mb-0">
                                        Charity Address
                                      </p>
                                      <p className="desc_grey_txt">
                                        The charity address receives the number
                                        of tokens given in the Charity Percent.
                                      </p>
                                      <p className="wp_grn_hts mb-0">
                                        Token Name
                                      </p>
                                      <p className="desc_grey_txt mb-0">
                                        Name of the token.
                                      </p>
                                      <p className="desc_grey_txt mb-0">
                                        For instance, BitDealz1 star is a token
                                        name with the symbol - BZ1. Total supply
                                        - 10000 CS.
                                      </p>
                                      <p className="desc_grey_txt">
                                        For every transaction, a 10% fee is
                                        collected (5% of the fee amount is
                                        allocated to all diamond hand holders).
                                        To create a steady price rise and to
                                        stabilize the token, liquidity receives
                                        4% of the supply. Charity address
                                        receives 1% for each transaction.
                                      </p>
                                      <p className="desc_grey_txt">
                                        <span className="wp_grn_hts">
                                          Anti-whale Trade Feature -
                                        </span>{" "}
                                        transactions only equal to or lesser
                                        than 0.05% of the total supply will be
                                        allowed.
                                      </p>{" "}
                                      <Row className="justify-content-center">
                                        <Col md={10}>
                                          <div className="my-4 card_bg wp_img_wrp">
                                            <img
                                              src={image31}
                                              alt="Token Verification"
                                              className="img-fluid"
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <p className="desc_grey_txt">
                                        Next, click on “Create Token” after
                                        entering the essential information.
                                      </p>
                                      <p className="desc_grey_txt">
                                        Now, a wallet or Metamask asks you to
                                        confirm the transaction by clicking the
                                        “confirm” button while indicating the
                                        associated transaction fee.
                                      </p>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="four">
                                    <div>
                                      {" "}
                                      <h4 className="wp_sub_heads">
                                        Private sale
                                      </h4>
                                      <div className="pl-3 pl-sm-4">
                                        {" "}
                                        <h5 className="wp-sub_heads_opt">
                                          Create a Private sale
                                        </h5>{" "}
                                        <p className="desc_grey_txt">
                                          Complete the below steps to create a
                                          private sale,
                                        </p>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">Visit,</li>

                                          <li className="mb-2">
                                            Connect the wallet
                                          </li>
                                          <li className="mb-2">
                                            Give a Private sale title that
                                            should be the token name.
                                          </li>
                                          <li className="mb-2">
                                            Select the token & click on the next
                                            button.
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image32}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </ul>
                                        <h5 className="wp-sub_heads_opt">
                                          Enter Private Sale Info
                                        </h5>
                                        <p className="wp_h5_itc">
                                          Enter the private sale information
                                          after token verification. During the
                                          process, remember to
                                        </p>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Use{" "}
                                            <span className="wp_grn_hts">
                                              Positive
                                            </span>{" "}
                                            numbers
                                          </li>
                                          <li className="mb-2">
                                            Turn on the Whitelist to include a
                                            Whitelist of presale contributors.
                                            Remember that you can Turn on or off
                                            the Whitelist option whenever you
                                            want.
                                          </li>
                                          <li className="mb-2">
                                            Enter a reasonable soft cap value
                                            that must be equal to or greater
                                            than 50% of the hard cap
                                          </li>
                                          <li className="mb-2">
                                            The minimum buy should not be higher
                                            than the maximum buy.
                                          </li>
                                          <li className="mb-2">
                                            Enter the value for
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Initial Fund Allocation
                                            </span>{" "}
                                            (value should not exceed 95%). It
                                            Indicates the 1st batch of private
                                            sale funds ( %) given to the project
                                            owner.
                                          </li>
                                          <li className="mb-2">
                                            <span className="wp_grn_hts">
                                              Fund vesting period (days) -
                                            </span>{" "}
                                            denotes the time period between the
                                            release of each batch of Private
                                            Sale.
                                          </li>
                                          <li className="mb-2">
                                            <span className="wp_grn_hts">
                                              Fund Release per cycle (%) -
                                            </span>{" "}
                                            Total % of private sale funds
                                            released each cycle after the
                                            Initial Fund Allocation.
                                          </li>
                                          <li className="mb-2">
                                            Enter the lock-up time for the
                                            liquidity pool.
                                          </li>
                                        </ul>{" "}
                                        <p className="desc_grey_txt">
                                          <span className="wp_grn_hts">
                                            Note -{" "}
                                          </span>
                                          Start time should be earlier than the
                                          end time(UTC Time zone)
                                        </p>
                                        <Row className="justify-content-center">
                                          <Col md={10}>
                                            <div className="my-4 card_bg wp_img_wrp">
                                              <img
                                                src={image33}
                                                alt="Token Verification"
                                                className="img-fluid"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <h5 className="wp-sub_heads_opt">
                                          Other Additional Information
                                        </h5>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Enter the website and Logo URL
                                          </li>
                                          <li className="mb-2">
                                            Remember that logo URL must end with
                                            an image extension (For instance,
                                            png, jpeg).
                                          </li>
                                          <li className="mb-2">
                                            Give the description (128-512
                                            characters)
                                          </li>
                                          <li className="mb-2">
                                            Then, click “Next”.
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image34}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </ul>{" "}
                                        <h5 className="wp-sub_heads_opt">
                                          Finish
                                        </h5>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Review all the entered details
                                          </li>
                                          <li className="mb-2">
                                            Go to the previous page to make any
                                            changes
                                          </li>
                                          <li className="mb-2">
                                            Finally, click{" "}
                                            <span className="wp_grn_hts">
                                              Submit.
                                            </span>
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image35}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            Metamask or another wallet asks you
                                            to confirm the transaction where the
                                            transaction fee is shown.
                                          </li>
                                          <li className="mb-2">
                                            Now, click the{" "}
                                            <span className="wp_grn_hts">
                                              Confirm{" "}
                                            </span>
                                            button.
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image36}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </ul>{" "}
                                      </div>
                                      <h4 className="wp_sub_heads">
                                        Finalize a Private Sale
                                      </h4>
                                      <p className="desc_grey_txt">
                                        Once the private sale ends or the Hard
                                        cap limit exceeds, follow the given
                                        steps to list your token.
                                      </p>{" "}
                                      <div className="pl-3 pl-sm-4">
                                        {" "}
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Click “Finalize“ in the Pool Action
                                            Section
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image37}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <p className="desc_grey_txt">
                                            Next, Metamask or another wallet
                                            asks you to confirm the transaction
                                            and indicates the transaction fee.
                                            Now, click the{" "}
                                            <span className="wp_grn_hts">
                                              Confirm
                                            </span>{" "}
                                            button.
                                          </p>
                                          <li className="mb-2">
                                            Click on “Claim Fund” to receive
                                            Private sale funds
                                          </li>
                                          <li className="mb-2">
                                            Metamask or another wallet asks you
                                            to confirm the transaction where the
                                            transaction fee is shown. Now, click
                                            the{" "}
                                            <span className="wp_grn_hts">
                                              Confirm
                                            </span>{" "}
                                            button.
                                          </li>
                                          <li className="mb-2">
                                            Select the token & click on the next
                                            button.
                                          </li>
                                        </ul>
                                        <h5 className="wp-sub_heads_opt">
                                          Enter Private Sale Info
                                        </h5>
                                        <p className="wp_h5_itc">
                                          Enter the private sale information
                                          after token verification. During the
                                          process, remember to
                                        </p>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Use{" "}
                                            <span className="wp_grn_hts">
                                              Positive
                                            </span>{" "}
                                            numbers
                                          </li>
                                          <li className="mb-2">
                                            Turn on the Whitelist to include a
                                            Whitelist of presale contributors.
                                            Remember that you can Turn on or off
                                            the Whitelist option whenever you
                                            want.
                                          </li>
                                          <li className="mb-2">
                                            Enter a reasonable soft cap value
                                            that must be equal to or greater
                                            than 50% of the hard cap
                                          </li>
                                          <li className="mb-2">
                                            The minimum buy should not be higher
                                            than the maximum buy.
                                          </li>
                                          <li className="mb-2">
                                            Enter the value for
                                            <span className="wp_grn_hts">
                                              {" "}
                                              Initial Fund Allocation
                                            </span>{" "}
                                            (value should not exceed 95%). It
                                            Indicates the 1st batch of private
                                            sale funds ( %) given to the project
                                            owner.
                                          </li>
                                          <li className="mb-2">
                                            <span className="wp_grn_hts">
                                              Fund vesting period (days) -
                                            </span>{" "}
                                            denotes the time period between the
                                            release of each batch of Private
                                            Sale.
                                          </li>
                                          <li className="mb-2">
                                            <span className="wp_grn_hts">
                                              Fund Release per cycle (%) -
                                            </span>{" "}
                                            Total % of private sale funds
                                            released each cycle after the
                                            Initial Fund Allocation.
                                          </li>
                                          <li className="mb-2">
                                            Enter the lock-up time for the
                                            liquidity pool.
                                          </li>
                                        </ul>{" "}
                                        <p className="desc_grey_txt">
                                          <span className="wp_grn_hts">
                                            Note -{" "}
                                          </span>
                                          Token Price = Total BNB collected
                                        </p>
                                        <h5 className="wp-sub_heads_opt">
                                          Other Additional Information
                                        </h5>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Enter the website and Logo URL
                                          </li>
                                          <li className="mb-2">
                                            Remember that logo URL must end with
                                            an image extension (For instance,
                                            png, jpeg).
                                          </li>
                                          <li className="mb-2">
                                            Give the description (128-512
                                            characters)
                                          </li>
                                          <li className="mb-2">
                                            Then, click “Next”.
                                          </li>
                                        </ul>{" "}
                                        <h5 className="wp-sub_heads_opt">
                                          Finish
                                        </h5>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Review all the entered details
                                          </li>
                                          <li className="mb-2">
                                            Go to the previous page to make any
                                            changes
                                          </li>
                                          <li className="mb-2">
                                            Finally, click{" "}
                                            <span className="wp_grn_hts">
                                              Submit.
                                            </span>
                                          </li>
                                          <li className="mb-2">
                                            Metamask or another wallet asks you
                                            to confirm the transaction where the
                                            transaction fee is shown.
                                          </li>
                                          <li className="mb-2">
                                            Now, click the{" "}
                                            <span className="wp_grn_hts">
                                              Confirm{" "}
                                            </span>
                                            button.
                                          </li>
                                        </ul>{" "}
                                      </div>
                                      <h4 className="wp_sub_heads">
                                        Private Sale List
                                      </h4>
                                      <p className="desc_grey_txt">
                                        Projects available for Private sale on
                                        the BitDealz Launchpad are listed here
                                      </p>{" "}
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="five">
                                    {" "}
                                    <div>
                                      {" "}
                                      <h4 className="wp_sub_heads">
                                        Create a lock(Liquidity lock)
                                      </h4>
                                      <p className="desc_grey_txt">
                                        Follow the below to create a{" "}
                                        <span className="wp_grn_hts">
                                          Liquidity or lock.
                                        </span>
                                      </p>
                                      <div className="pl-3 pl-sm-4">
                                        {" "}
                                        <h5 className="wp-sub_heads_opt">
                                          Lock (without vesting)
                                        </h5>{" "}
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">Visit,</li>

                                          <li className="mb-2">
                                            Connect the wallet
                                          </li>
                                          <li className="mb-2">
                                            Give a Private sale title that
                                            should be the token name.
                                          </li>
                                          <li className="mb-2">
                                            Enter the token or LP token address.
                                          </li>
                                          <li className="mb-2">
                                            Next, enter the token owner address
                                            to send the tokens.
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image38}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            Input title for lock, for instance,
                                            BitDealz pad token.
                                          </li>
                                          <li className="mb-2">
                                            Give the amount of tokens that you
                                            wish to lock in percentages.
                                          </li>
                                          <li className="mb-2">
                                            Lock-up period - Enter the time to
                                            lock the tokens.
                                          </li>
                                          <li className="mb-2">
                                            Then, click “Approve”.
                                          </li>
                                          <li className="mb-2">
                                            Now, Metamask asks to verify the
                                            transaction. Click on “Confirm” to
                                            complete the “Approve” process after
                                            checking the fee.
                                          </li>
                                        </ul>
                                        <p className="desc_grey_txt">
                                          Exclude inlock’s lockup address………
                                          from fees, rewards, and max tx amount
                                          to lock tokens.
                                        </p>
                                        <h5 className="wp-sub_heads_opt">
                                          Lock (Vesting)
                                        </h5>{" "}
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">Visit,</li>

                                          <li className="mb-2">
                                            Connect the wallet
                                          </li>
                                          <li className="mb-2">
                                            Enter the token or LP token address.
                                          </li>
                                          <li className="mb-2">
                                            Next, enter the token owner address.
                                            Unlocked tokens will be sent to this
                                            address.
                                          </li>
                                          <li className="mb-2">
                                            Input title for lock, for instance,
                                            BitDealz1.
                                          </li>
                                          <li className="mb-2">
                                            Enter the amount of tokens for
                                            vesting
                                          </li>
                                          <li className="mb-2">
                                            Tick the “vesting” box.
                                          </li>
                                        </ul>
                                        <h5 className="wp-sub_heads_opt">
                                          Important Terms to remember
                                        </h5>{" "}
                                        <p className="desc_grey_txt">
                                          {" "}
                                          <span className="wp_grn_hts">
                                            TGE Date
                                          </span>{" "}
                                          - Listing time.
                                        </p>
                                        <p className="desc_grey_txt">
                                          {" "}
                                          <span className="wp_grn_hts">
                                            TGE (%)
                                          </span>{" "}
                                          - Initial batch of released team
                                          tokens.
                                        </p>
                                        <p className="desc_grey_txt">
                                          {" "}
                                          <span className="wp_grn_hts">
                                            Cycle (Days)
                                          </span>{" "}
                                          - Period between the release of each
                                          batch of vested tokens
                                        </p>
                                        <p className="desc_grey_txt">
                                          {" "}
                                          <span className="wp_grn_hts">
                                            Cycle Release (Percent)
                                          </span>{" "}
                                          - Tokens released per cycle followed
                                          by the Initial Token Release Batch.
                                        </p>
                                        <p className="mb-0 desc_grey_txt">
                                          Demonstration of Lock with Vesting
                                        </p>
                                        <p className="mb-0 desc_grey_txt">
                                          Total tokens to be vested = 100000 &
                                          Tokens vested at TGE = 25%.
                                        </p>
                                        <p className="mb-0 desc_grey_txt">
                                          If the Presale end date is 1/2/22, at
                                          TGE (1/2/22 18.29), your team can
                                          claim 100000*25% = 25000 for the
                                          Initial Token Release Batch. If the
                                          total cycle is 3 (90 days), on 1/3/22,
                                          your team receives 100000*25% = 25000
                                          ,and on 1/4/22, your team receives
                                          100000*25% = 25000, and on 1/5/22,
                                          your team receives 100000*25% = 25000
                                          respectively.
                                        </p>
                                        <Row className="mt-3 justify-content-sm-center">
                                          <Col xs={4} md={3}>
                                            <p className="desc_grey_txt">
                                              {" "}
                                              Cycle Release (%){" "}
                                            </p>
                                          </Col>
                                          <Col xs={4} md={3}>
                                            <div>
                                              <p className="desc_grey_txt mb-0">
                                                {" "}
                                                = 100-25(TGE){" "}
                                              </p>
                                              <p className="desc_grey_txt">
                                                {" "}
                                                3 (Total Cycle){" "}
                                              </p>
                                            </div>
                                          </Col>
                                          <Col xs={4} md={3}>
                                            <p className="desc_grey_txt">
                                              {" "}
                                              = 25%{" "}
                                            </p>
                                          </Col>
                                        </Row>
                                        <p className=" desc_grey_txt">
                                          For the above case, you might enter
                                          the info as below
                                        </p>
                                        <p className="mb-0 desc_grey_txt">
                                          <span className="wp_grn_hts">
                                            Amount ={" "}
                                          </span>
                                          100000
                                        </p>
                                        <p className="mb-0 desc_grey_txt">
                                          <span className="wp_grn_hts">
                                            TGE Date ={" "}
                                          </span>
                                          1/2/22 18.59
                                        </p>
                                        <p className="mb-0 desc_grey_txt">
                                          <span className="wp_grn_hts">
                                            TGE Percent ={" "}
                                          </span>
                                          25
                                        </p>
                                        <p className="mb-0 desc_grey_txt">
                                          <span className="wp_grn_hts">
                                            Cycle (Days) ={" "}
                                          </span>
                                          30
                                        </p>
                                        <p className=" desc_grey_txt">
                                          <span className="wp_grn_hts">
                                            Cycle Release Percent ={" "}
                                          </span>
                                          25
                                        </p>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Click “Approve”.
                                          </li>

                                          <li className="mb-2">
                                            Now, Metamask asks to verify the
                                            transaction. Click on “Confirm” to
                                            complete the “Approve” process after
                                            checking the fee.
                                          </li>
                                          <li className="mb-2">
                                            Click on “Lock”.
                                          </li>
                                          <li className="mb-2">
                                            Now, Metamask asks to verify the
                                            transaction. Click on “Confirm” to
                                            complete the “Approve” process after
                                            checking the fee.
                                          </li>
                                        </ul>
                                        <p className="wp_h5_itc mb-0">
                                          Remember to exclude Pinlock’s lockup
                                          address……. from fees, rewards, and max
                                          transaction amount to lock tokens.
                                        </p>
                                        <p className="wp_h5_itc">
                                          Note that the Rebase Tokens will not
                                          be covered by Team vesting.
                                        </p>
                                      </div>
                                      <h4 className="wp_sub_heads">
                                        How to unlock
                                      </h4>
                                      <div className="pl-3 pl-sm-4">
                                        {" "}
                                        <h5 className="wp-sub_heads_opt">
                                          Unlock (without vesting)
                                        </h5>{" "}
                                        <p className="desc_grey_txt">
                                          Click the “Unlock” button on the
                                          Pinlock page once the locking cycle is
                                          completed to unlock the locked tokens.
                                        </p>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">Visit,</li>

                                          <li className="mb-2">
                                            Connect the wallet
                                          </li>
                                          <li className="mb-2">
                                            Click on “My lock” <FaArrowRight />{" "}
                                            “View”.
                                          </li>
                                          <li className="mb-2">
                                            Then, click on “Unlock”.
                                          </li>
                                          <li className="mb-2">
                                            Now, Metamask asks to verify the
                                            transaction. Click on “Confirm” to
                                            complete the “Approve” process after
                                            checking the fee.
                                          </li>
                                        </ul>
                                        <h5 className="wp-sub_heads_opt">
                                          Renouncing Lock Ownership
                                        </h5>{" "}
                                        <p className="desc_grey_txt">
                                          Click on “Renounce Lock Ownership”
                                          which will transfer the ownership to
                                          an inoperable wallet at the end of the
                                          lock. This prevents you from unlocking
                                          or removing the liquidity/tokens
                                          associated with your wallet.
                                        </p>
                                        <p className="desc_grey_txt">
                                          <span className="wp_grn_hts">
                                            {" "}
                                            Note -{" "}
                                          </span>{" "}
                                          Renounce lock ownership only if you
                                          understand the concept completely.
                                          Bitdea launchpad does not provide any
                                          liability for any loss that occurred
                                          to any person acting or refraining
                                          from action as a result of this
                                          feature.
                                        </p>
                                        <h5 className="wp-sub_heads_opt">
                                          Unlock (With Vesting)
                                        </h5>{" "}
                                        <p className="desc_grey_txt">
                                          Click the “Unlock” button on the
                                          Pinlock page once the vesting cycle
                                          ends to unlock the vested tokens.
                                        </p>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="six">
                                    <div>
                                      <p className="text-white">
                                        <span>Note -</span>It is important to
                                        accurately calculate the tokens needed
                                        to meet your airdrop requirement before
                                        committing tokens to the airdrop.
                                      </p>
                                      <div className="pl-3 pl-sm-4">
                                        {" "}
                                        <h5 className="wp-sub_heads_opt">
                                          Steps to Create an Airdrop
                                        </h5>{" "}
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            To create a new airdrop, visit
                                          </li>

                                          <li className="mb-2">
                                            Now, on the new page, enter the
                                            chosen token address in the
                                            corresponding section. Click Next
                                            after checking all the details
                                            (token name, symbol, etc.)
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image39}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            In the next step, enter the details
                                            of Airdrop like Airdrop Title, Logo
                                            URL, Website, etc. The logo URL must
                                            end with an image extension (For
                                            instance, png, jpeg). You can upload
                                            the image using …. and get the link.
                                          </li>
                                          <li className="mb-2">
                                            Now, click Create New Airdrop.
                                          </li>
                                          <Row className="justify-content-center">
                                            <Col md={10}>
                                              <div className="my-4 card_bg wp_img_wrp">
                                                <img
                                                  src={image40}
                                                  alt="Token Verification"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <li className="mb-2">
                                            On the next page, Click Set
                                            Allocations after selecting airdrop
                                            addresses. Next, a pop-up appears
                                            where you can add the token address
                                            & amount to the User Allocation
                                            list.
                                          </li>
                                        </ul>
                                        <p className="desc_grey_txt">
                                          {" "}
                                          <span className="wp_grn_hts">
                                            Note -
                                          </span>{" "}
                                          Addresses are entered separately in a
                                          new line, and the amount of each user
                                          is separated by a comma.
                                        </p>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Now, click Add Allocations after
                                            adding addresses. Next, Metamask
                                            asks you to confirm the transaction
                                            by clicking the “confirm” button and
                                            indicates the associated transaction
                                            fee. Click the “Confirm” button to
                                            finish the process.
                                          </li>

                                          <li className="mb-2">
                                            Airdrop allocations are shown on the
                                            airdrop page after successfully
                                            adding airdrop allocations
                                          </li>
                                          <li className="mb-2">
                                            Now, click Set Vesting.
                                          </li>
                                          <li className="mb-2">
                                            Now, click Create New Airdrop.
                                          </li>
                                        </ul>
                                        <p className="desc_grey_txt">
                                          <span className="wp_grn_hts">
                                            Note
                                          </span>
                                        </p>
                                        <p className="desc_grey_txt mb-0">
                                          TGE Release (Percent) - Initial batch
                                          of airdrop tokens released{" "}
                                        </p>
                                        <p className="desc_grey_txt mb-0">
                                          Cycle (Days) - The time period between
                                          the release of every batch of vested
                                          tokens
                                        </p>
                                        <p className="desc_grey_txt">
                                          Cycle Release (Percent) - Percentage
                                          of airdrop tokens released per cycle
                                          followed by the Initial TGE Release
                                          Batch.{" "}
                                        </p>
                                        <p className="desc_grey_txt mb-0">
                                          Demonstration of Airdrop Allocations{" "}
                                        </p>
                                        <p className="desc_grey_txt mb-0">
                                          Total tokens vested by Airdrop
                                          participants = 1000{" "}
                                        </p>
                                        <p className="desc_grey_txt mb-0">
                                          TGE Release(%) = 40{" "}
                                        </p>
                                        <p className="desc_grey_txt mb-0">
                                          Cycle Release (%) = 10{" "}
                                        </p>
                                        <p className="desc_grey_txt ">
                                          Total Cycle = 6 (30 Days per cycle){" "}
                                        </p>
                                        <p className="desc_grey_txt ">
                                          For instance, the presale on BitDealz
                                          ends on 2nd May. Your airdrop
                                          participants can get 1000*40 % = 400
                                          tokens once the tokens have been
                                          listed. For the six four months, they
                                          can claim 1000*10% =100 tokens on the
                                          2nd of every month.
                                        </p>{" "}
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Next, Metamask asks you to confirm
                                            the transaction by clicking the
                                            “confirm” button and indicates the
                                            associated transaction fee. Click
                                            the “Confirm” button to finish the
                                            process.
                                          </li>

                                          <li className="mb-2">
                                            Exclude Transfer fee, and max tx for
                                            airdrop address before initiating
                                            airdrop. On the airdrop page,
                                            allocated airdrop addresses are
                                            shown
                                          </li>
                                          <li className="mb-2">
                                            Successfully start airdrop after
                                            finalizing the presale pool. Visit
                                            the airdrop page by clicking.
                                          </li>
                                          <li className="mb-2">
                                            Now, click Start Airdrop
                                          </li>
                                          <li className="mb-2">
                                            In this section, check the amount of
                                            tokens contributed to the airdrop
                                            (along with a 1% fee), Start now or
                                            at a specific time
                                          </li>
                                        </ul>
                                        <h5 className="wp-sub_heads_opt">
                                          Image Showing Airdrop Start Time
                                        </h5>{" "}
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Next, again Metamask asks you to
                                            confirm the transaction by clicking
                                            the “confirm” button and indicates
                                            the associated transaction fee.
                                            Click the “Confirm” button to finish
                                            the process.
                                          </li>
                                        </ul>
                                        <h5 className="wp-sub_heads_opt">
                                          How to Claim Airdrop?
                                        </h5>{" "}
                                        <p className="desc_grey_txt">
                                          Follow the steps to claim the airdrop
                                          tokens.
                                        </p>
                                        <ul className="wp_ul pl-3 desc_grey_txt">
                                          <li className="mb-2">
                                            Visit …Then, click Airdrop - View
                                            Airdrop. A list of airdrop
                                            allocations is shown here.
                                          </li>
                                          <li className="mb-2">
                                            Click on Claim after checking
                                            claimable tokens.
                                          </li>
                                          <li className="mb-2">
                                            Next, Metamask asks you to confirm
                                            the transaction by clicking the
                                            “confirm” button and indicates the
                                            associated transaction fee. Click
                                            the “Confirm” button to finish the
                                            process.
                                          </li>
                                        </ul>
                                      </div>
                                      <h4 className="wp_sub_heads">
                                        Airdrop List
                                      </h4>{" "}
                                      <div className="pl-3 pl-sm-4">
                                        {" "}
                                        <p className="desc_grey_txt">
                                          {" "}
                                          Check the list of crypto projects with
                                          airdrop initiative available on the
                                          BitDealz Launchpad
                                        </p>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="seven">
                                    <datagrid>
                                      <div>
                                        <h4 className="wp_sub_heads">KYC</h4>
                                        <p className="desc_grey_txt">
                                          <span className="wp_grn_hts">
                                            For KYC{" "}
                                          </span>
                                          Visit
                                        </p>
                                        <div className="pl-3 pl-sm-4">
                                          <h5 className="wp-sub_heads_opt">
                                            KYC (Project Owners)
                                          </h5>{" "}
                                          <p className="desc_grey_txt">
                                            In BitDealz Launchpad, KYC feature
                                            helps users to get a complete
                                            insight into the authenticity &
                                            capability of presale projects to
                                            enable them to make well-informed
                                            investing decisions.
                                          </p>
                                          <p className="desc_grey_txt">
                                            The projects receive a KYC badge
                                            that appears next to the project
                                            name for completing the process
                                          </p>
                                          <h5 className="wp-sub_heads_opt">
                                            Few Things About KYC
                                          </h5>{" "}
                                          <p className="desc_grey_txt">
                                            KYC (Know Your Customer) is a part
                                            of the identity verification process
                                            that enables the project owners to
                                            submit government-issued IDs like
                                            National IDs, passports, etc., to
                                            automatically verify their identity{" "}
                                          </p>
                                          <p className="desc_grey_txt">
                                            The KYC process is the same as KYC
                                            registration while opening a bank
                                            account.
                                          </p>
                                          <h5 className="wp-sub_heads_opt">
                                            Why is KYC needed?
                                          </h5>{" "}
                                          <p className="desc_grey_txt">
                                            <span className="wp_grn_hts">
                                              {" "}
                                              KYC
                                            </span>{" "}
                                            process prevents scams and rug pulls
                                            while maintaining trust and
                                            transparency on the BitDealz
                                            Launchpad.
                                          </p>
                                          <p className="desc_grey_txt">
                                            KYC helps investors to ensure their
                                            safety and they can submit a report
                                            with proof & evidence to support
                                            their claim (if a user believes a
                                            project is a scam). The BitDealz
                                            Launchpad team will investigate the
                                            concern within a matter of time and
                                            take appropriate actions.
                                          </p>
                                          <p className="desc_grey_txt">
                                            KYC helps project owners to earn
                                            investors' trust with a KYC badge.
                                            On the trending bar, the KYC badge
                                            will improve the project's ranking.
                                          </p>
                                          <h5 className="wp-sub_heads_opt">
                                            For KYC, contact
                                          </h5>{" "}
                                          <p className="desc_grey_txt">
                                            KYC procedures & AMA features ensure
                                            the security and trustability of the
                                            BitDealz Launchpad.
                                          </p>
                                          <p className="desc_grey_txt">
                                            Contact{" "}
                                            <a
                                              href="mailto:support@bitdealz.com"
                                              className="wp_grn_hts"
                                            >
                                              support@bitdealz.com
                                            </a>{" "}
                                            to complete the KYC process.{" "}
                                          </p>
                                          {/* <p className="desc_grey_txt mb-0">
                                            <span className="wp_grn_hts">
                                              Note -{" "}
                                            </span>
                                            KYC processing fees are
                                            non-refundable, and the project
                                            owners are not entitled to receive a
                                            refund under any circumstances.
                                          </p> */}
                                          {/* <p className="desc_grey_txt mb-0">
                                            The KYC verification costs around $
                                            and takes 1-2 business days to
                                            complete.
                                          </p> */}
                                          {/* <p className="desc_grey_txt">
                                            Use your real documents & give
                                            genuine details during the KYC
                                            process.
                                          </p> */}
                                        </div>
                                        <h4 className="wp_sub_heads">
                                          Disclaimer
                                        </h4>
                                        <div className="pl-3 pl-sm-4">
                                          <p className="desc_grey_txt">
                                            KYC badge claims don't indicate that
                                            the project is recommended or
                                            supported by us. We suggest you do a
                                            proper analysis of the presale
                                            before investing.
                                          </p>
                                        </div>
                                      </div>
                                    </datagrid>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="eight">
                                    <div>
                                      <h5 className="wp-sub_heads_opt">
                                        What is BCoin?
                                      </h5>{" "}
                                      <p className="desc_grey_txt">
                                        BCoin is a native token of BitDealz
                                        Exchange where users can earn by
                                        staking, farming or exchange for other
                                        tokens
                                      </p>
                                      <p className="desc_grey_txt">
                                        BCoin Token as well can be used through
                                        various BitDealz services such as
                                        physical products marketplace, NFT
                                        Marketplace, games, booking and lot
                                        more.
                                      </p>
                                      <h5 className="wp-sub_heads_opt">
                                        How to buy a BCoin?
                                      </h5>{" "}
                                      <p className="desc_grey_txt">
                                        Follow the simple steps to buy the BCoin
                                        or BC during the presale
                                      </p>
                                      <p className="desc_grey_txt">Visit,...</p>
                                      <ul className="wp_ul pl-3 desc_grey_txt">
                                        <li className="mb-2">
                                          Go to the BCoin Token Presale page.
                                        </li>
                                        <Row className="justify-content-center">
                                          <Col md={10}>
                                            <div className="my-4 card_bg wp_img_wrp">
                                              <img
                                                src={image41}
                                                alt="Token Verification"
                                                className="img-fluid"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <li className="mb-2">
                                          On the Presale page, enter the desired
                                          token amount (for instance, BSC) to
                                          buy the BCoin on the Paid Amount
                                          section. For the given amount, you can
                                          check the equivalent amount of BCoin
                                          you receive on the Receive BCoin Token
                                          field
                                        </li>
                                        <li className="mb-2">
                                          Remember that the entered amount
                                          should be greater than or equal to the
                                          Minimum buy and within the Maximum buy
                                          value of the corresponding token
                                        </li>
                                        <Row className="justify-content-center">
                                          <Col md={10}>
                                            <div className="my-4 card_bg wp_img_wrp">
                                              <img
                                                src={image42}
                                                alt="Token Verification"
                                                className="img-fluid"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <li className="mb-2">
                                          Remember to check the specified soft
                                          cap value & hard cap value. Make sure
                                          to view the soft cap number{" "}
                                        </li>
                                        <li className="mb-2">
                                          After verifying, click the Approve and
                                          next, Claim button
                                        </li>
                                        <li className="mb-2">
                                          Now, a wallet, Metamask asks to verify
                                          the transaction. Click on “Confirm” to
                                          complete the “Approve” & “Claim”
                                          process successfully after checking
                                          the fee.
                                        </li>
                                      </ul>
                                      <p className="desc_grey_txt">
                                        You can check the new BCoin holdings on
                                        the Your Purchase label
                                      </p>
                                      <h5 className="wp-sub_heads_opt">
                                        Important Terms to be remembered while
                                        buying BCoin during presale
                                      </h5>{" "}
                                      <h5 className="wp-sub_heads_opt">
                                        Token Address
                                      </h5>{" "}
                                      <p className="desc_grey_txt">
                                        Represents the address of the BCoin{" "}
                                        <span className="wp_grn_hts">
                                          i.e,
                                          0x58a2C4B27caBCF7c956577c1e8f82D4AA755F599
                                        </span>{" "}
                                      </p>
                                      <h5 className="wp-sub_heads_opt">
                                        Access Type
                                      </h5>{" "}
                                      <p className="desc_grey_txt">
                                        Indicates who can buy the tokens during
                                        the presale, like verified members or
                                        project owners
                                      </p>
                                      <h5 className="wp-sub_heads_opt">
                                        Status
                                      </h5>{" "}
                                      <p className="desc_grey_txt">
                                        Status represents the presale phase of
                                        the BCoin, for instance, progress or
                                        open indicates that presale is going on
                                      </p>
                                      <h5 className="wp-sub_heads_opt">
                                        Minimum Buy
                                      </h5>{" "}
                                      <p className="desc_grey_txt">
                                        The Bcoin token purchase should be done
                                        with the specified amount or greater
                                        than the value mention in this field
                                      </p>
                                      <h5 className="wp-sub_heads_opt">
                                        Maximum Buy
                                      </h5>{" "}
                                      <p className="desc_grey_txt">
                                        The maximum buy represents the amount of
                                        BCoin tokens that can be brought during
                                        the presale per transaction
                                      </p>
                                      <h5 className="wp-sub_heads_opt">
                                        Token Purchase
                                      </h5>{" "}
                                      <p className="desc_grey_txt">
                                        This value represents the total number
                                        of BCoin tokens purchased by an user,
                                        for instance, 100 Bcoin
                                      </p>
                                      <p className="desc_grey_txt">
                                        The pie-chart on the BCoin Token presale
                                        page indicates token metrics & the
                                        availability of tokens. For instance,
                                        the green portion on the chart
                                        represents the total number of BCoin
                                        tokens sold in percentages.
                                      </p>
                                      <Row className="justify-content-center">
                                        <Col md={10}>
                                          <div className="my-4 card_bg wp_img_wrp">
                                            <img
                                              src={image43}
                                              alt="Token Verification"
                                              className="img-fluid"
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </div>
                            </Col>
                          </Row>
                        </Tab.Container>
                      </div>
                    </div>
                  </div>

                  <Container className="pb-5 px-0">
                    <center>
                      <small className="mt-0 bottom_text">
                        Disclaimer: The information provided shall not in any
                        way constitute a recommendation as to whether you should
                        invest in any product discussed. We accept no liability
                        for any loss occasioned to any person acting or
                        refraining from action as a result of any material
                        provided or published.
                      </small>
                    </center>
                  </Container>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}

export default WhitePaper;
