import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";

import Header from '../Header';
import Sidebar from '../Sidebar';
import Trendingslider from '../trendingslider';
import { getAccount, getAllocationInfo, getChainId } from '../../hooks/useAccount'
import Chart from "react-apexcharts";
import Countdown from 'react-countdown';
import {  Container, ProgressBar,InputGroup, FormControl} from 'react-bootstrap';
import '../../css/styles.css';
import favicon from "../../images/Bitdeal/logo_curve.png"
import bclogo from "../../images/Bitdeal/bclogo.png"
import exchangeicon from "../../images/whiteiconlogo.png"
import exchangeiconb from "../../images/Bitdeal/exc_white.png"
import wallet_icon_coin from "../../images/Bitdeal/exc_white.png"

import { Link } from 'react-router-dom';
import WhitelistModal from "../WhitelistModal"
import RemoveWhitelistmodal from "../RemoveWhitelistmodal"
import ChangeStatusmodal from "../ChangeStatusmodal"
import { approveContractbuy, checkIsApproved, Checksalebalance, Getliquiditytokenamount, Getsalebalance, getSaleInfoCard, GetSalePerAccount, Getunsoldtoken, Getwalletbalance, PresaleSupply, toFixedNumber, UseTokenInfo } from '../../hooks/useContract';
import { isSaleLive, isUpcoming, Salediffernce, UpcomingDiffernce } from '../../hooks/useProjects';
import { CHAINS, ONEDAYINSECONDS } from '../../config/env';
import { BcoinBuyToken, BuyToken, calculateBuy, claimToken } from '../../hooks/useBuy';
import { Finalise, getUnsold, SetWhitelisted, updateStopEvent, withdrawBNB } from '../../hooks/useAdmin';
import { getallsalehook, gettrendingdisplayhook } from '../../hooks/usebackend';
import HtmlParser from 'react-html-parser';

import ReactPlayer from 'react-player';




const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
      return  <div className='cout_man_div'>
      <div>{days}d </div>
      <div>{hours}h</div>
      <div>{minutes}m</div>   
      <div>{seconds}s </div>         
   </div>
    } else {
      // Render a countdown
      return <div className='cout_man_div'>
                {/* <span>{days}<span>Days</span> </span>
                <span>{hours}<span>Hours</span></span>
                <span>{minutes}<span>Minuits</span></span>   
                <span>{seconds}<span>Seconds</span> </span>    */}
                 <div>{days}d </div>
      <div>{hours}h</div>
      <div>{minutes}m</div>   
      <div>{seconds}s </div>
            </div>;
    }
  };


class BcoinDetail extends Component {
  showLoader()
  {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.05;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");


  }

  hideLoader()
  {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");



  }
  
    componentDidMount()
    {
      // document.getElementById("exchangenew_parent").classList.add("active");
      // this.showLoader();
      this.hideLoader();
       this.setState({ accountInfo: getAccount() });
    //   this.gettrending();
    //   this.handlegetkyc()
      this.refreshData()
    }

    handlegetkyc = async() => {
      let auditkycinfo = await getallsalehook();
        if(auditkycinfo?.data?.data?.length > 0){
            this.setState({auditkyc : auditkycinfo?.data?.data})
        }

        let singleaudit = auditkycinfo?.data?.data?.find(e => e.saleaddress == window.location.pathname.split('/')[2])
        console.log("auditkuycinfo" , auditkycinfo?.data?.data , singleaudit);

        if(singleaudit)
        this.setState({singleaudit : singleaudit})
    }
    async gettrending(){
      const {saleData} = this.props
      let data = await gettrendingdisplayhook();
      console.log("dataa trending" , data?.data?.data);
      if(data?.data?.data?.length > 0)
      this.setState({trending : data?.data?.data}) 
      let finddata = data?.data?.data?.find((e)=> e?.saleaddress == this.state.saleAddress)
      this.setState({singletrending : finddata})
  }


    constructor(props) {
        super(props);
        this.state = {          
            walletModal: false,
            saleAddress: window.location.pathname.split('/')[2],
            accountInfo: '',
            series: [44, 55, 41, 17, 15],
            options: {
              labels: ["Total Tokens", "Sold Tokens", "Unsold Tokens", "Liquidity Tokens"],
              chart: {
                type: 'donut',
              },
              dataLabels: {
                enabled: true,
               
                textAnchor: 'middle',
                distributed: false,
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '10px'
                },
             
              },
              responsive: [
                {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 250
                  },
                  legend: {
                    position: 'bottom'
                  }
                },
              },
              {
                breakpoint: 600,
                options: {
                  chart: {
                    width: 300
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              },
              {
                breakpoint: 1199,
                options: {
                  chart: {
                    width: 300
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              },
              {
                breakpoint: 2000,
                options: {
                  chart: {
                    width: 350
                  },
                  legend: {
                    position: 'right'
                  }
                }
              },
              {
                breakpoint: 10000,
                options: {
                  chart: {
                    width: 350
                  },
                  legend: {
                    position: 'right'
                  }
                }
              }
            ]
            },
            iswhitelisted : true,
            whitelistModal : false,
            removewhitelistModal:false,
            changestatueModal:false,
            buyToken: "",
            saleInfo: {},
            userData: {},
            allocations: [],
            buyTokenDecimal : 18,
            amount: 0,
            claim: 0,
            max: 0,
            isMax: false,
            liqtoken : "",
            unsoldtoken : "",
            chartstatus : false,
          charttokendecimal : "",
          trending : [],
          singletrending : {}, 
          approvebutton : false,
          auditkyc : [],
            singleaudit : {},
            withdrawbtn : null,
            userbalance : 0,
            salebal : 0,
            supply : {},
            tokeninfo : {}
        };
    }

    handlewithdraw = async() => {
      let account = getAccount();
      let withdraw = await withdrawBNB(this.state.saleAddress , account);
    }

    approveFunction = async (accountInfo,tokenAddress,saleAddress) => {
      const approval = await checkIsApproved(accountInfo,tokenAddress,saleAddress);
      console.log("approval0",approval);
       this.setState({ approvebutton: approval}) 
    
   
}


async approvetoken(){
  await approveContractbuy(localStorage.getItem("accountInfo"),this.state.saleInfo.useWithToken,this.state.saleAddress);
  this.setState({approvebutton : true})
}

     loadData = async() => {
    //   this.showLoader();
      // if(window.location.pathname.split('/')[3] || window.location.pathname.split('/')[3] == "true"){
      //   console.log("db");
      //   let saleDetail =  JSON.parse(localStorage.getItem("saledata"))
      //   this.setState({ saleInfo: saleDetail});
      // }
      // else{
        const saleDetail = await getSaleInfoCard(this.state.saleAddress);
      this.setState({ saleInfo: saleDetail });
      if(localStorage.getItem("accountInfo")){
        const user = await GetSalePerAccount(localStorage.getItem("accountInfo"),this.state.saleAddress);
        this.setState({userData: user});
       
        if(saleDetail.isClaimable){
          const allocated = await getAllocationInfo(user.actualBalance,user.userClaimbale,saleDetail.vestingInterval,saleDetail.vestingPercent,user.initialClaim);
          this.setState({ allocations: allocated });
          console.log("allcations L ",allocated)
        }

        if(!saleDetail?.buytype){
          this.approveFunction(localStorage.getItem("accountInfo"),saleDetail?.useWithToken,saleDetail?.saleAddress)
        }
      }
     // }
      this.hideLoader();
    }
    
    refreshData = async()=>{
     this.showLoader();
    //  if(window.location.pathname.split('/')[3] || window.location.pathname.split('/')[3] == "true"){
    //   console.log("db");
    //   let saleDetail =  JSON.parse(localStorage.getItem("saledata"))
    //   this.setState({ saleInfo: saleDetail});
    // }
    // else{
      console.log("address" , this.state.saleAddress);
      const saleDetail = await getSaleInfoCard(this.state.saleAddress);
      console.log("saledetail" , saleDetail);
      this.getbalance(saleDetail?.useWithToken);
      this.hidewithdraw(this.state.saleAddress , saleDetail?.useWithToken)
      console.log("saleDetail" , saleDetail);
      let salebalance = await Checksalebalance(saleDetail?.tokenAddress , saleDetail?.saleAddress)
      this.setState({"salebal" : salebalance})
      console.log("salebalance" , salebalance);
      const token = await UseTokenInfo(saleDetail?.tokenAddress);
      this.setState({tokeninfo : token});
      const psupply = await PresaleSupply(saleDetail?.tokenAddress , this.state.saleAddress);
      this.setState({supply : psupply})
      let decimal ;
      console.log("tokentokentoken" , token);
      this.setState({charttokendecimal : 18})
      let liqtoken = await Getliquiditytokenamount(this.state.saleAddress);
      console.log("liqtoken" , liqtoken);
      
      this.setState({"liqtoken" : liqtoken/10**18})
      let unsoldtoken = await Getunsoldtoken(this.state.saleAddress);
      console.log("liqtoken" , liqtoken);
      this.setState({unsoldtoken : unsoldtoken/10**18});
     
      this.setState({ saleInfo: saleDetail });
      this.setState({"chartstatus" : true});
       this.GetBuytokeninfo(saleDetail?.useWithToken)
      if(localStorage.getItem("accountInfo")){
        const user = await GetSalePerAccount(localStorage.getItem("accountInfo"),this.state.saleAddress);
        console.log("user",user);
       this.setState({userData : user});
       if(saleDetail.isClaimable){
        const allocated = await getAllocationInfo(user.actualBalance,saleDetail.vestingInterval,saleDetail.vestingPercent,user.initialClaim);
       this.setState({ allocations: allocated });
       }

       if(!saleDetail?.buytype){
        this.approveFunction(localStorage.getItem("accountInfo"),saleDetail?.useWithToken,saleDetail?.saleAddress)
      }
       
      }
    // }
      
      this.hideLoader()
    }

     GetBuytokeninfo = async(value)=>{
     
      if(value === "0x0000000000000000000000000000000000000000"){
        console.log("kxcnvjxch");
         this.setState( { buyToken: CHAINS[localStorage.getItem("CHAIN")? localStorage.getItem("CHAIN") : 0].SYMBOL});
         this.setState({buyTokenDecimal : 18})
      }else{
          const token =  await UseTokenInfo(this.state.saleInfo && this.state.saleInfo?.useWithToken);
          console.log("token" , token);
          this.setState({ buyToken: token?.symbol });
          this.setState({buyTokenDecimal : Number(token?.decimals)})
          
      }
      }

      async max(){
        this.setState({ max: this.state.saleInfo && (parseInt(this.state.saleInfo?.maxEthLimit)/10** this.state.buyTokenDecimal), isMax: true })
        this.setState({amount : this.state.saleInfo && (parseInt(this.state.saleInfo?.maxEthLimit)/10** this.state.buyTokenDecimal)})
        
      }

      hidewithdraw = async(sale , istoken) => {
        var balance = await Getsalebalance(sale , istoken);
        if(balance > 0){
          // return false
          this.setState({withdrawbtn : false})
        }
        else{
          // return true
          this.setState({withdrawbtn : true})
        }
      }
       
      
    //   async calculateToken(value){
       
    //     const tokenValue = await calculateBuy(this.state.saleAddress,value);
    //     this.setState({ claim: (tokenValue/10**this.state.saleInfo?.decimals) })
       
    // }

    async getbalance(token){
      var balance = await Getwalletbalance(token);
      console.log("wallet balance" , balance);
      this.setState({userbalance : balance});
    }

    async calculateToken(value){console.log("calculate claim");
        if(this.state.saleInfo.useWithToken == "0x0000000000000000000000000000000000000000"){
          const tokenValue = await calculateBuy(this.state.saleAddress,value);
          console.log("calculate claim in bnb" , tokenValue , this.state.saleInfo.decimals);
          this.setState({ claim: (tokenValue/10**18) })
        }
        else{
            let tokeninfo = await UseTokenInfo(this.state.saleInfo.useWithToken)
            const tokenValue = await calculateBuy(this.state.saleAddress,value);
            console.log("calculate claim token" , tokenValue , tokeninfo?.decimals);
            this.setState({ claim: (tokenValue/10**tokeninfo?.decimals) });
        }
    }

    async calculatepercenttoken(value){
      let max = parseInt(this.state.saleInfo?.maxEthLimit)/10** this.state.buyTokenDecimal;
      let onepercent = parseFloat(max)/100;
      let finalvalue = parseFloat(onepercent)*value;
      this.setState({amount : finalvalue});
      this.calculateToken(finalvalue);
    }

    async buy(){
      console.log("buyyyyyyyyyyyyyyyyyyyyyyy");
      await BcoinBuyToken(this.state.saleAddress,this.state.amount,this.state.isMax,this.state.max,getAccount(),this.state.buyTokenDecimal , this.state.buyToken);
      window.location.reload();
      // this.reset();
      // this.props.onUpdate();
    }
    
    
    async Claim(){
      await claimToken(this.state.saleAddress,getAccount());
      // this.props.onUpdate();
    }

    async stopSaleEvent(){
      await updateStopEvent(this.state.saleAddress,getAccount());
      await this.refreshData()
     
    }
    
    
    
    async withdrawToken(){
      await getUnsold(this.state.saleAddress,getAccount());
      await this.refreshData()
     ;
    }
    
    async BNBwithdraw(){
      await withdrawBNB(this.state.saleAddress,getAccount());
      await this.refreshData()
      
    }
    
    async Finalisesale(){
      await Finalise(this.state.saleAddress,getAccount());
      await this.refreshData()
      
    }
    
    async Handlewhitelist(value){
      await SetWhitelisted(this.state.saleAddress,value,getAccount());
      await this.refreshData()
    }
 
    render() {
   
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const {saleInfo ,userInfo, whitelistModal,removewhitelistModal,changestatueModal , userData , iswhitelisted} = this.state
      {console.log("saleinfo" , saleInfo);}
	return (
    <div id="loader_main">
    <div id="loader_div">
    <span className="spin_round">

    </span>
    <img src={favicon} className="logo_load" />
  </div>
        <div className='logo_overlay' id="logo_overlay">

           <Header/>
          
           <div className="whole_sec pb-5">
           <div className='flex_side_right'>
            <Sidebar />
           {/* ongoing_sec */}
           <div className='right_side_sec'>
           {/* <div className="text-white topBar d-flex justify-content-between">
                  
                  <small>Trending</small>
                <small>
                  #1&nbsp;<span>Husy</span>
                </small>
                <small>
                  #2&nbsp;<span>ORL</span>
                </small>
                <small>
                  #3&nbsp;<span>UWC</span>
                </small>
                <small>
                  #4&nbsp;<span>777</span>
                </small>
                <small>
                  #5&nbsp;<span>IDXS</span>
                </small>
                <small>
                  #6&nbsp;<span>COUGNU</span>
                </small>
                <small>
                  #7&nbsp;<span>CRICLE</span>
                </small>
                <small>
                  #8&nbsp;<span>KASA</span>
                </small>
                <small>
                  #9&nbsp;<span>FIFAPP</span>
                </small>
                <small>
                  #10&nbsp;<span>SOG</span>
                </small>
                <small>
                  #11&nbsp;<span>COOSHA</span>
                </small>
                <small>
                  #12&nbsp;<span>Honey</span>
                </small>
                
                
              </div> */}
              {/* <Trendingslider/> */}
              <div className="right_side_spacing">
        <Container className='px-1'>
        <div className='row mt-5'>

        {
            this.state?.saleInfo?.isPresaleOpen && parseFloat(this?.state?.salebal) <= 0?
            <div className='col-12 mb-3'>
            <div className='card_footer_form form_new_banner'>
                                  <div className='d-flex align-items-center justify-content-center'>
                                  <p className='mb-0 pl-3'>This sale has not been deposited yet.</p>
                                  </div>
  
                              </div>  
            </div>
            :
            <></>
          }
           <div className='col-12 col-md-6 col-lg-6 mb-4'>
                 <div className='card_bg card h-100'>
            <div className='card-body'>
            {/* <span class="badge badge-kyc-rect mt-0 mb-2 badge_trend_detail"><span class="blk_txt">#Ontop {}</span></span> */}
            {this.state.singletrending && <span class="badge badge-kyc-rect mt-0 mb-2 badge_trend_detail"><span class="blk_txt">#OnTop{this.state.singletrending?.position}</span></span>}
              <div className='row align-items-center pb-4 row_left_res row_left_res_new'>
                <div className='col-12 col-sm-12 col-md-12 col-xl-8 mb-3 mb-md-0'>
                <div className='d-flex align-items-center fklex_dircol flex_mob_blk_div align_col_bkk'>
                    <div className='d-flex align-items-center'>
                    <div className='profimg'>
                        <img src={bclogo} className='img-fluid img_ind_circle img_ind_circle_bc' />
                    {/* <img src={saleInfo && saleInfo.logo} alt={saleInfo && saleInfo.symbol} className='img_ind_circle'/> */}
                       {/* <img src="https://bscscan.com/images/svg/brands/bnb.svg?v=1.3" alt="image" className='img_ind_circle'/> */}
                   </div>
                   <span className='sale_deta_name'>
                   Bcoin
                   </span>
                   </div>
                   <div>
                   
                   </div>
              
           
                </div>
      
                  
                </div>
                
             
              </div>
         


            <div>
            {/* <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt private_sal_size_new'>Private Sale Address :</span>
            <span className='desc_grey_txt font_12'>
                <a href={`https://etherscan.io/address/${this.state.saleAddress}`} target='_blank' className='desc_grey_txt font_12 a_brk_new'>{this?.state?.saleAddress}</a></span>
            </p>     */}


<div className='desc_video'>
                <p className='typograp_data'>{HtmlParser(saleInfo && saleInfo?.social && saleInfo?.description)}
                  {/* Token is following the new hype meta set by the main  🐱 Birthed from the legacy of 🤩 Witness the QUICKEST rising memetoken , supercharged by the TikTok Hype! 😎 
🏆No Team Tokens ⭕️ Contract Renounced 🌕 Trending at launch 💎20+ influencers support us 💎100+ KOLS⚡️ Low 1/1 taxes✅ LP  locked for a year ✅ Leading the charts in trading volume 🐳 Legacy  whales have joined the voyage */}
              </p>
              <div className='iframe_div'>
           

<ReactPlayer
                                url={saleInfo && saleInfo?.social && saleInfo?.social[9]}
                                controls={true}
                                width="100%"
                                height="330px"
                              />
{/* <iframe width="853" height="480" src={saleInfo && saleInfo?.social && saleInfo?.social[9]} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" title="Pool Intro"></iframe> */}
              </div>
              
              </div>



            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>1 {this.state.buyToken ? this.state.buyToken : CHAINS[localStorage.getItem("CHAIN")? localStorage.getItem("CHAIN") : 0].SYMBOL} :</span>
            <span className='desc_grey_txt font_12'>
            {/* 10 BC */}
                {saleInfo && saleInfo.presaleRate} {saleInfo && saleInfo.symbol}
            </span>
            </p> 

          

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>Softcap :</span>
            <span className='desc_grey_txt font_12'>
            {/* 5.00 USDT */}
                {saleInfo && (parseInt(saleInfo?.softCap)/10**18).toFixed(2)} {this?.state?.buyToken}
            </span>
            </p> 

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>Hardcap :</span>
            <span className='desc_grey_txt font_12'>
            {/* 5.00 USDT */}
                {saleInfo && (parseInt(saleInfo?.hardCap)/10**18).toFixed(2)} {this?.state?.buyToken}
            </span>
            </p>         

            {/* {!window.location.pathname.split('/')[3] || window.location.pathname.split('/')[3] == "false" ?  */}
            {/* <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>Start Time :</span>
            <span className='desc_grey_txt font_12'>{saleInfo && new Date(saleInfo.startTime*1000).toLocaleDateString()+"  "
             +new Date(saleInfo.startTime*1000).getHours()+":"+new Date(saleInfo.startTime*1000).getMinutes()+":"
             +new Date(saleInfo.startTime*1000).getSeconds()+"(GMT)"  }
            </span>
            </p>  */}
            {/* // : <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            // <span className='desc_grey_txt'>Start Time :</span>
            // <span className='desc_grey_txt font_12'>{saleInfo && new Date(saleInfo.startTime).toLocaleDateString()+"  "
            //  +new Date(saleInfo.startTime).getHours()+":"+new Date(saleInfo.startTime).getMinutes()+":"
            //  +new Date(saleInfo.startTime).getSeconds()+"(GMT)"  }
            // </span>
            // </p>}    */}

            {/* {!window.location.pathname.split('/')[3] || window.location.pathname.split('/')[3] == "false"? */}
            
            {/* <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>End Time :</span>
            <span className='desc_grey_txt font_12'>{saleInfo && new Date(saleInfo.endTime*1000).toLocaleDateString()+"  "
             +new Date(saleInfo.endTime*1000).getHours()+":"+new Date(saleInfo.endTime*1000).getMinutes()+":"
             +new Date(saleInfo.endTime*1000).getSeconds()+"(GMT)" }
            </span>
            </p>  */}
            {/* :  <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>End Time :</span>
            <span className='desc_grey_txt font_12'>{saleInfo && new Date(saleInfo.endTime).toLocaleDateString()+"  "
             +new Date(saleInfo.endTime).getHours()+":"+new Date(saleInfo.endTime).getMinutes()+":"
             +new Date(saleInfo.endTime).getSeconds()+"(GMT)" }
            </span>
            </p>}          */}



            {/* <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>First Release for Project :</span>
            <span className='desc_grey_txt font_12'>30%
            </span>
            </p>   */}

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>Vesting Claim :</span>
            <span className='desc_grey_txt font_12'> {saleInfo && saleInfo.isVested ? 'Enabled' : 'Disabled'}
            </span>
            </p>
            {/* <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>Pancakeswap Listing</span>
            <span className='desc_grey_txt font_12'> {saleInfo && saleInfo.isPancake ? 'Enabled' : 'Disabled'}
            </span>
            </p>   */}

            { saleInfo.isPancake ? 
            <>
            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>Pancakeswap Rate :</span>
            <span className='desc_grey_txt font_12'> {saleInfo && saleInfo?.pancakeRate}
            </span>
</p>

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>Pancakeswap Rate :</span>
            <span className='desc_grey_txt font_12'>  {saleInfo && (saleInfo?.lpUnlockon*1000)/(1000*60*60*24)} Days
            </span>

           
            </p> 
            </>  : 
            <></>}
            {saleInfo && saleInfo.isVested ?
             <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>Vesting for Project :</span>
            <span className='desc_grey_txt font_12'>{saleInfo && saleInfo.vestingPercent/100} % each {saleInfo && saleInfo.vestingInterval/ONEDAYINSECONDS} Day(s)
            </span>
            </p> :<></>} 
       
            {/* <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>Vesting for Project :</span>
            <span className='desc_grey_txt font_12'>5% each 20 minutes
            </span>
            </p>   */}

<div className='text-center mt-3 btn_exc_align_os'>
<a href="https://bitdealz.exchange/swap" target="_blank" className="get-started-btn">
          <img src={exchangeicon} className='img-fluid exch_icon_re_he mr-2' />
                          Native BCoin Exchange
                        </a>

                        </div>
                        </div>
                      
             </div>
             </div>
            </div>

            <div className='col-12 col-md-6 col-lg-6 mb-4'>
            <div className='card_bg card h-100'>
            <div className='card-body'>
            <div className='card_footer_form'>
                                <div className='d-flex align-items-center justify-content-center'>
                                <p className='mb-0 pl-3'>Make sure the website is cryptotrading</p>
                                </div>

                            </div>  
                           

                    <>
                    <p className='text-center sale_deta_name mt-3'>Hard Cap</p>
                    {/* <div className='countdown_new text-center'>
                    {console.log("Salediffernce(saleInfo.endTime)",Salediffernce(saleInfo.endTime))}
                    <Countdown date={Date.now() +  Salediffernce(saleInfo?.endTime)}  renderer={renderer} className="countdown_grey" />
                    </div> */}
                    </>
<>
                          <ProgressBar now={saleInfo && parseInt(parseInt(saleInfo?.earnedCap)/parseInt(saleInfo?.softCap) * 100)} className='yellow_bar mt-3'/>
            <p className='white_txt_sm d-flex justify-content-between mt-1 text_row_oneline'>
               <span className='desc_grey_txt'>
                {/* 0 */}
                {saleInfo && parseInt(parseInt(saleInfo?.earnedCap)/parseInt(saleInfo?.softCap) * 100)}
                 % </span>
               <span className='desc_grey_txt'>
               {/* 0.000/5.00 USDT */}
                {saleInfo && ((saleInfo?.earnedCap)/10**this.state.buyTokenDecimal).toFixed(3)}/{(parseInt(saleInfo.softCap)/10**this.state.buyTokenDecimal).toFixed(2)} {this.state.buyToken}
                </span>
                </p>             
                <p className='input_desc_sm'>Amount (Min: {saleInfo && saleInfo?.minEthLimit / 10 ** this.state.buyTokenDecimal} & Max: {saleInfo && saleInfo?.maxEthLimit / 10 ** this.state.buyTokenDecimal}  {this.state.buyToken})</p>
             
              </>
   

               {/* <p className='input_desc_sm'>You Will Get : {this.state.claim} {this.state.saleInfo?.name}</p> */}

                {/* {this?.state?.claim} {this.state.saleInfo?.name} */}

                {/* {!window.location.pathname.split('/')[3] || window.location.pathname.split('/')[3] == "false" &&  */}
                {this?.state?.accountInfo && <p className='input_desc_sm'>Your balance : {this?.state?.userbalance} {this.state.buyToken}</p>}
                
                <p className='input_desc_sm mt-3'>Paid Amount:</p>

<div className="inputs input-groups date_inoput_grps">
    <InputGroup className="datepicker_input">
    <FormControl id="amountmax" placeholder="0.0"
            aria-describedby="basic-addon2"
            value={this.state.amount}
          onChange={(e)=> { this.setState({ amount: e.target.value });this.calculateToken(e.target.value);}}
        />
      
    </InputGroup>
    <InputGroup.Append className='cur_pointer'>
            <button variant="outline-secondary" className="trans_cal_btn"
              onClick={async()=> {await this.max();await this.calculateToken(this.state.amount)}}
            >
            Max
           </button>
        </InputGroup.Append>
</div>
                <p className='input_desc_sm mt-3'>Receive BCoin Tokens:</p>
               
                <div className="inputs input-groups date_inoput_grps">
                        <InputGroup className="datepicker_input">
                        <FormControl id="amountmax" placeholder="0.0"
                                aria-describedby="basic-addon2"
                                value={this.state.claim}
                              // onChange={(e)=> { this.setState({ amount: e.target.value });this.calculateToken(e.target.value);}}
                             readOnly= {true}
                            />
                          
                        </InputGroup>
                        <InputGroup.Append className='cur_pointer'>
                                <button variant="outline-secondary" className="trans_cal_btn"
                                  onClick={async()=> {await this.max();await this.calculateToken(this.state.amount)}}
                                >
                                <img src={wallet_icon_coin} className='img-fluid wallet_icon_coin'/>
                                </button>
                            </InputGroup.Append>
                    </div>
                 

                    {/* <p className='input_desc_sm mt-3'>You Will Get : 
              1 BC */}
              {/* {this.state.claim} {this.state.saleInfo?.name} */}
              {/* </p> */}

                    <div className='btn-group bc_grp mt-3'>
                    <button className="get-started-btn" onClick={() => this.calculatepercenttoken(10)}>
                           10%
                        </button>

                        <button className="get-started-btn" onClick={() => this.calculatepercenttoken(25)}>
                           25%
                        </button>

                        <button className="get-started-btn" onClick={() => this.calculatepercenttoken(50)}>
                           50%
                        </button>

                        <button className="get-started-btn" onClick={() => this.calculatepercenttoken(75)}>
                           75%
                        </button>

                        <button className="get-started-btn" onClick={() => this.calculatepercenttoken(100)}>
                           100%
                        </button>


                        <button className="get-started-btn" onClick={async()=> {await this.max();await this.calculateToken(this.state.amount)}}>
                          Max
                        </button>
                    </div>
                    {/* } */}
                    {/* {console.log("!window.location.pathname.split('/')[3]" , window.location.pathname.split('/')[3])} */}
                    {/* { saleInfo && isSaleLive(saleInfo.startTime,saleInfo.endTime,saleInfo.isPresaleOpen) || isUpcoming(saleInfo.startTime,saleInfo.endTime) ? 
                    <button onClick={this.buy.bind(this)}disabled={ saleInfo && saleInfo.isWhitelisted ? userData && !(userData.userWhitelistedAmount > 0) : false } className="get-started-btn">{saleInfo && saleInfo.isWhitelisted ? (userData && !(userData.userWhitelistedAmount > 0) ? "Not Whitelisted" : "Buy"):"Buy"} with {this.state.buyToken} </button>:
                    // (isUpcoming(saleInfo.startTime,saleInfo.endTime) ? <button disabled={true} className="get-started-btn mr-2" > Upcoming </button> :
                    ((!isSaleLive(saleInfo.startTime,saleInfo.endTime) ?<button disabled={true} className="get-started-btn mr-2" > Sale InActive </button>:<button onClick={this.buy.bind(this)} className="get-started-btn" > Buy </button>))
                    } */}

{/* {!window.location.pathname.split('/')[3] || window.location.pathname.split('/')[3] == "false" && <div> */}
<div className='bc_btn_main_grp'>
{saleInfo.buytype  ?

                   <> 
                    { saleInfo && isSaleLive(saleInfo.startTime,saleInfo.endTime,saleInfo.isPresaleOpen) || isUpcoming(saleInfo.startTime,saleInfo.endTime)? 
                      <button onClick={this.buy.bind(this)}disabled={ saleInfo && saleInfo.isWhitelisted ? userData && !(userData.userWhitelistedAmount > 0) : false } className="get-started-btn">{saleInfo && saleInfo.isWhitelisted ? (userData && !(userData.userWhitelistedAmount > 0) ? "Not Whitelisted" : "Buy"):"Buy"} with {this.state.buyToken} </button>:
                      // (isUpcoming(saleInfo.startTime,saleInfo.endTime) ? <button disabled={true} className="get-started-btn mr-2" > Upcoming </button> :
                      ((!isSaleLive(saleInfo.startTime,saleInfo.endTime) ?<button disabled={true} className="get-started-btn mr-2" > Sale InActive </button>:<button onClick={this.buy.bind(this)} className="get-started-btn" > Buy </button>))
                      }
                      </> : 
                      


                      <>
                      {!this.state.approvebutton ?
                        <button className="get-started-btn" onClick={()=>{this.approvetoken()}}>
                           Approve
                        </button>  : 
                        <>
                        { saleInfo && isSaleLive(saleInfo.startTime,saleInfo.endTime,saleInfo.isPresaleOpen) || isUpcoming(saleInfo.startTime,saleInfo.endTime)? 
                          <button onClick={this.buy.bind(this)} 
                           className="get-started-btn">{saleInfo && saleInfo.isWhitelisted ? (userData && !(userData.userWhitelistedAmount > 0) ? "Not Whitelisted" : "Buy"):"Buy"} with {this.state.buyToken} </button>:
                          // (isUpcoming(saleInfo.startTime,saleInfo.endTime) ? <button disabled={true} className="get-started-btn mr-2" > Upcoming </button> :
                          ((!isSaleLive(saleInfo.startTime,saleInfo.endTime) ?<button  className="get-started-btn mr-2" > Sale InActive </button>:<button onClick={this.buy.bind(this)} className="get-started-btn" > Buy </button>))
                          }
                          </>
                        }</>}

                    {/* {!window.location.pathname.split('/')[3] || window.location.pathname.split('/')[3] == "false" && <> */}
                    {/* { saleInfo && saleInfo.isVested ?
                    <button  className="get-started-btn mt-3"  disabled = {!this.state.saleInfo?.isClaimable}>Vested  {this.state.saleInfo?.symbol} </button>:
                    (saleInfo && saleInfo.isClaimable  && (parseFloat(userData?.userClaimbale) >0) ?
             
                    <button  onClick={() => this.Claim()} disabled = {!this?.state?.saleInfo?.owner!= getAccount() && !this.state.saleInfo?.isClaimable} className="get-started-btn ml-3"> Claim  {this.state.saleInfo?.symbol}  </button> :
                    <button onClick={() => this.Claim()} disabled = {!this?.state?.saleInfo?.owner!= getAccount() && !this.state.saleInfo?.isClaimable} className="get-started-btn ml-3"> Claim  {this.state.saleInfo?.symbol}  </button>)
                    } */}
                    {/* </>} */}
{/* </div>} */}

</div>
                </div>
                </div>
                </div>
        </div>

                <div className='row'>
                <div className='col-12 col-md-12 col-lg-12 mb-4'>
                <div className='card_bg card h-100'>
            <div className='card-body'>
            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>Token Address :</span>
            <span>
                <a href={`${CHAINS[getChainId()].Adress}${saleInfo?.tokenAddress}`} target='_blank'className='desc_grey_txt font_12'>
                    {saleInfo && saleInfo?.tokenAddress}
                    </a></span>
            </p> 

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>Access Type:</span>
            {saleInfo && saleInfo?.isWhitelisted ?
            <span className='desc_grey_txt font_12'>Private</span> :
           
           
             <span className='desc_grey_txt font_12'>Public</span>}
             </p>
             <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>Status :</span>
  {saleInfo && isSaleLive(saleInfo?.startTime,saleInfo?.endTime,saleInfo?.isPresaleOpen)  ?
            <span className='desc_grey_txt font_12'>Live</span> : 
            ( isUpcoming(saleInfo?.startTime) ? 
            <span className='desc_grey_txt font_12'>Upcoming</span> :
            <span className='desc_grey_txt font_12'>Closed</span> )}
            </p>

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>Minimum Buy :</span>
            <span className='desc_grey_txt font_12'>
            {/* 0.100 USDT */}
                {saleInfo && (parseInt(saleInfo.minEthLimit)/10**this.state.buyTokenDecimal)}  {this.state.buyToken}
                </span>
            </p> 
{console.log("minimum buy" , this.state.buyTokenDecimal)}
            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>Maximum Buy :</span>
            <span className='desc_grey_txt font_12'>
            {/* 1.000 USDT */}
                {(saleInfo && saleInfo?.maxEthLimit / 10 ** this?.state?.buyTokenDecimal)}  {this.state.buyToken}
                </span>
            </p>  
{console.log("this?.state?.buyTokenDecimal" , this?.state?.buyTokenDecimal)}
            {/* {!window.location.pathname.split('/')[3] || window.location.pathname.split('/')[3] == "false" && */}
            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>You Purchased :</span>
            {console.log("userData.userClaimbale",userData?.userClaimbale)}
            <span className='desc_grey_txt font_12'> 
{/* 10 BC */}
            {userData && (parseInt(userData?.userClaimbale)/10**parseInt(saleInfo.decimals)).toFixed(2)} {saleInfo && saleInfo.symbol}
            </span>
            </p>
            {/* } */}

             {/* NEW DATA */}
             <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>Presale Address :</span>
            <span><a href={localStorage.getItem("CHAIN") == 0 ? `https://testnet.snowtrace.io/address/${saleInfo && saleInfo.tokenAddress}`: `https://testnet.bscscan.com/address/${saleInfo && saleInfo.tokenAddress}`} target='_blank'className='desc_grey_txt font_12'>{saleInfo && saleInfo?.tokenAddress}</a></span>
            </p> 

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>Token Name :</span>
            <span className='desc_grey_txt font_12'>{this?.state?.tokeninfo?.name}</span>
            </p> 

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>Token Symbol :</span>
            <span className='desc_grey_txt font_12'>{this?.state?.tokeninfo?.symbol}</span>
            </p> 

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>Total Supply :</span>
            <span className='desc_grey_txt font_12'>{toFixedNumber((parseFloat(this?.state?.supply?.presalesupply)/10**parseFloat(this?.state?.tokeninfo?.decimals)))}{this?.state?.tokeninfo?.symbol}</span>
            </p> 

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>Presale Supply :</span>
            <span className='desc_grey_txt font_12'>{toFixedNumber((parseFloat(this?.state?.supply?.presalesupply)/10**parseFloat(this?.state?.tokeninfo?.decimals)))}{this?.state?.tokeninfo?.symbol}</span>
            </p> 

            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1 text_row_oneline'>
            <span className='desc_grey_txt'>Presale Rate :</span>
            <span className='desc_grey_txt font_12'>{saleInfo && saleInfo.presaleRate} {saleInfo && saleInfo.symbol}</span>
            </p>
            {/* END NEW DATA */}

       <><p className='text-white mt-4'>Token Metrics</p>
       <hr class="hr_green" />
       <Chart options={this.state.options} 
                   series={[((saleInfo?.hardCap/10**this.state.charttokendecimal) * saleInfo?.presaleRate) , 
                   ((saleInfo?.hardCap/10**this.state.charttokendecimal) * saleInfo?.presaleRate)-(this.state.unsoldtoken) , this.state.unsoldtoken , this.state.liqtoken]}
                className="chart_donut_res chart_center_new" type="donut" />
           </>

            {/* {window.location.pathname.split('/')[3] && <Chart options={this.state.options} 
                   series={[((saleInfo?.hardCap/10**this.state.charttokendecimal) * saleInfo?.presaleRate) , 
                   ((saleInfo?.hardCap/10**this.state.charttokendecimal) * saleInfo?.presaleRate)-(0) , 10 , 5]}
                className="chart_donut_res" type="donut" />} */}
                </div>
                </div>
</div>

            </div>
        

                
               

        </Container>

            <Container className='pb-5 px-0 mt-4'>
                 
                 <center>
                   <small className="mt-3 bottom_text">
                     Disclaimer: The information provided shall not in any way
                     constitute a recommendation as to whether you should invest
                     in any product discussed. We accept no liability for any
                     loss occasioned to any person acting or refraining from
                     action as a result of any material provided or published.
                   </small>
                 </center>
               </Container>
               </div>
            </div>
           </div>
           </div>
           {whitelistModal && <WhitelistModal saleAddress = {saleInfo.saleAddress} tokendecimal = {this.state?.buyTokenDecimal} onDismiss={() => this.setState({ whitelistModal: false })} /> }
           {removewhitelistModal && <RemoveWhitelistmodal saleAddress = {saleInfo.saleAddress} onDismiss={() => this.setState({ removewhitelistModal: false })} /> }
           {changestatueModal && <ChangeStatusmodal saleAddress = {saleInfo.saleAddress} onDismiss={() => this.setState({ changestatueModal: false })} /> }
        </div>
        </div>
        )
    }
}

export default BcoinDetail