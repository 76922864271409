import bsc from "../images/bsc.png"
import polygon from "../images/polygon.png"
import bsctestnet from "../images/bsctestnet.png"
import avalanchetestnet from "../images/avalanche.png"
import eth from "../images/eth.png"
import ropsten from "../images/ropsten.png"
export const RPC_URL = "https://bsc-dataseed.binance.org/";
export const CHAIN_ID = 56;
  
// export const RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";
//  export const CHAIN_ID = 97;
 
 export const frontendurl  =     window.location.origin;
 export const ONEDAYINSECONDS = 86400;
  
 export const userFee = 5

 export const userFeeWithtoken = 4

 export const CHAINS = [
    // {
    //     NAME: "Avalanche-TEST",
    //     RPC_URL:"https://api.avax-test.network/ext/bc/C/rpc",
    //     CHAIN_ID:43113,
    //     IMAGE: avalanchetestnet,
    //     ROUTER: "0x29035dEbC68181B33183E3A7fb9291bC2815644F", //pancake
    //     // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
    //     LIQUIDITY_TOKEN_ADDRESS : "0x36ff68d44e5A38Ac561900431eDd799781DE2c3f",
    //     STANDARD_TOKEN_ADDRESS : "0xF037798BDdEd40336498d3121dC5392a2E676778",
    //     LOCK_ADDRESS : "0x783edf2e8673f2e886a942fdF7629634207Ac30D", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
    //     ETH : "0x0000000000000000000000000000000000000000",
    //     BUSD : "0x8d9f46183A23d81dcCc172C92bDb4553c1b67FFf",
    //     USDT : "0x8d9f46183A23d81dcCc172C92bDb4553c1b67FFf",
    //     USDC : "0x8d9f46183A23d81dcCc172C92bDb4553c1b67FFf",
    //     AIRDROP_ADDRESS : "0x3b3bCCa9BC1C5d5f22F5110613062f48530C60a7", //"0x385b0e7d746ed4711ed3e7bea48e2d9b5052373a",
    //     TRANSACTION : "https://testnet.snowtrace.io/tx/",
    //     TokenTracker : "https://testnet.snowtrace.io/token/",
    //     Adress : "https://testnet.snowtrace.io/address/",
    //     SYMBOL : "AVAX",
    //     AVAX : "0x0000000000000000000000000000000000000000"
    // },

    {
        NAME: "Bsc",
        RPC_URL:"https://bsc-dataseed.binance.org/",
        CHAIN_ID:56,
        IMAGE: bsc,
        ROUTER: "0x10ED43C718714eb63d5aA57B78B54704E256024E", //pancake
        LOCK_ADDRESS: "0x41e6093cd89108DF2e441bDf6C0c3ABa7a950057",
        LIQUIDITY_TOKEN_ADDRESS : "0xF037798BDdEd40336498d3121dC5392a2E676778",
        STANDARD_TOKEN_ADDRESS : "0x6cbac8789bcdd095ba49b354968ca1062c8506ea",
        BNB : "0x0000000000000000000000000000000000000000",
        USDT : "0x55d398326f99059fF775485246999027B3197955",
        BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
        USDC : "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
        AIRDROP_ADDRESS : "0x5Ec9bC8dEb4cA0Fb0163fB5FE031EfdFEaF34903", 
        TRANSACTION : "https://bscscan.com/tx/",
        TokenTracker : "https://bscscan.com/token/",
        Adress : "https://bscscan.com/address/",
        SYMBOL : "BNB",
    },
    // {
    //     NAME: "Arbitrum",
    //     RPC_URL:"https://arb1.arbitrum.io/rpc",
    //     CHAIN_ID:42161,
    //     IMAGE: bsctestnet,
    //     ROUTER: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506", //pancake
    //     // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
    //     LIQUIDITY_TOKEN_ADDRESS : "0xf997125D111066E1989c94f18bA097C20ca858Ff",
    //     STANDARD_TOKEN_ADDRESS : "0xc228c9AB8889901C7C6936C6C6B8d5547006d727",
    //     LOCK_ADDRESS : "0x500d3cB1a8709e06C6274CbFa952E4F2a13dd87d", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
    //     // BNB : "0x0000000000000000000000000000000000000000",
    //     BNB : "0x0000000000000000000000000000000000000000",
    //     DAI  : "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    //     USDT : "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    //     USDC : "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
    //     TRANSACTION : "https://arbiscan.io/tx/",
    //     TokenTracker : "https://arbiscan.io/token/" 
    // },
    // {
    //     NAME: "BSC-Testnet",
    //     RPC_URL:"https://sparkling-crimson-pool.bsc-testnet.discover.quiknode.pro/df18f1ec10499d3fcc64dab45f73ed79f2a2d617/",
    //     CHAIN_ID:97,
    //     IMAGE: bsctestnet,
    //     ROUTER: "0x29035dEbC68181B33183E3A7fb9291bC2815644F", //pancake
    //     // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
    //     LIQUIDITY_TOKEN_ADDRESS : "0x527b203C7E0A6463E16DdE46bB71Ce 6a9B093595",
    //     STANDARD_TOKEN_ADDRESS : "0x06AAA4C8Da86Ad14c735Faa6B65C009f5B5FfE19",
    //     LOCK_ADDRESS : "0x388Ba9C5A1213aa8D12C9874A49014434C4aB24C", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
    //     ETH : "0x0000000000000000000000000000000000000000",
    //     BUSD : "0xbCf448aa88d80C1B7bb8Fb5A1e68cd4B6c11c354",
    //     USDT : "0xDFCd0b91AE2BB34a90D9b4970Dc113DFaf25004d",
    //     USDC : "0xDFCd0b91AE2BB34a90D9b4970Dc113DFaf25004d",
    //     AIRDROP_ADDRESS : "0x70b1f342b950e1d6CE3a1C058817Bef2682927cd", //"0x385b0e7d746ed4711ed3e7bea48e2d9b5052373a",
    //     TRANSACTION : "https://testnet.bscscan.com/tx/",
    //     TokenTracker : "https://testnet.bscscan.com/token/",
    //     SYMBOL : "BNB",
    //     BNB : "0x0000000000000000000000000000000000000000"
    // },
];

// export const API_URL = "http://localhost:7097" //local

// export const API_URL = "http://43.204.99.101:7097" //demo

export const API_URL = "https://api.bitdealz.finance" //live

export const FUND_RECEIVER = "0xd63a21AA0778069ADb89CDcC60995B33C4e7fAc8" // client address

export const COINS = ["ETH" , "DAI" , "USDT" , "USDC" , "ETH" , "MATIC" , "BNB" , "CRO" , "FTM"];
  
export const HC = ["Presale" , "Fairlaunch" , "Auction" , "Subscription"];
  
export const STATUS = ["Upcoming" , "Live" , "Ended"];

export const TOKEN_FEE = 0.01;

export const ZEROTH_ADDRESS = "0x0000000000000000000000000000000000000000"


export const WBNB_Address = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; // BSC Mainet

// export const WBNB_Address = "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd";  //BSC Testnet

// export const WBNB_Address = "0x1d308089a2d1ced3f1ce36b1fcaf815b07217be3"

export const PROJECT_ID = "b8a1daa2dd22335a2fe1d2e139980ae0"
















// import bsc from "../images/bsc.png"
// import polygon from "../images/polygon.png"
// import bsctestnet from "../images/bsctestnet.png"
// import eth from "../images/eth.png"
// import ropsten from "../images/ropsten.png"
// // export const RPC_URL = "https://bsc-dataseed1.binance.org/";
// // export const CHAIN_ID = 56;
  
// export const RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";
//  export const CHAIN_ID = 97;
 
//  export const ONEDAYINSECONDS = 86400;

//  export const userFee = 5

//  export const userFeeWithtoken = 4

//  export const CHAINS = [
//     {
//         NAME: "Avalanche-TEST",
//         RPC_URL:"https://api.avax-test.network/ext/bc/C/rpc",
//         CHAIN_ID:43113,
//         IMAGE: bsctestnet,
//         ROUTER: "0x29035dEbC68181B33183E3A7fb9291bC2815644F", //pancake
//         // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
//         LIQUIDITY_TOKEN_ADDRESS : "0x36ff68d44e5A38Ac561900431eDd799781DE2c3f",
//         STANDARD_TOKEN_ADDRESS : "0xF037798BDdEd40336498d3121dC5392a2E676778",
//         LOCK_ADDRESS : "0x0D42554AEcff5CB84E16ec6F82f3197B5fF3c2ce", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
//         BNB : "0x0000000000000000000000000000000000000000",
//         BUSD : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         USDT : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         USDC : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         AIRDROP_ADDRESS : "0x3b3bCCa9BC1C5d5f22F5110613062f48530C60a7", //"0x385b0e7d746ed4711ed3e7bea48e2d9b5052373a",
//         TRANSACTION : "https://testnet.snowtrace.io/tx/",
//         TokenTracker : "https://testnet.snowtrace.io/token/"
//     },
//     {
//         NAME: "Arbitrum",
//         RPC_URL:"https://arb1.arbitrum.io/rpc",
//         CHAIN_ID:42161,
//         IMAGE: bsctestnet,
//         ROUTER: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506", //pancake
//         // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
//         LIQUIDITY_TOKEN_ADDRESS : "0xf997125D111066E1989c94f18bA097C20ca858Ff",
//         STANDARD_TOKEN_ADDRESS : "0xc228c9AB8889901C7C6936C6C6B8d5547006d727",
//         LOCK_ADDRESS : "0x500d3cB1a8709e06C6274CbFa952E4F2a13dd87d", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
//         // BNB : "0x0000000000000000000000000000000000000000",
//         ETH : "0x0000000000000000000000000000000000000000",
//         DAI  : "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
//         USDT : "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
//         USDC : "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
//         TRANSACTION : "https://arbiscan.io/tx/",
//         TokenTracker : "https://arbiscan.io/token/" 
//     },
//     {
//         NAME: "BSC-Testnet",
//         RPC_URL:"https://sparkling-crimson-pool.bsc-testnet.discover.quiknode.pro/df18f1ec10499d3fcc64dab45f73ed79f2a2d617/",
//         CHAIN_ID:97,
//         IMAGE: bsctestnet,
//         ROUTER: "0x29035dEbC68181B33183E3A7fb9291bC2815644F", //pancake
//         // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
//         LIQUIDITY_TOKEN_ADDRESS : "0x527b203C7E0A6463E16DdE46bB71Ce6a9B093595",
//         STANDARD_TOKEN_ADDRESS : "0x06AAA4C8Da86Ad14c735Faa6B65C009f5B5FfE19",
//         LOCK_ADDRESS : "0x388Ba9C5A1213aa8D12C9874A49014434C4aB24C", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
//         BNB : "0x0000000000000000000000000000000000000000",
//         BUSD : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         USDT : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         USDC : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         AIRDROP_ADDRESS : "0x70b1f342b950e1d6CE3a1C058817Bef2682927cd", //"0x385b0e7d746ed4711ed3e7bea48e2d9b5052373a",
//         TRANSACTION : "https://testnet.snowtrace.io/tx/",
//         TokenTracker : "https://testnet.snowtrace.io/token/"
//     },
// ];

// // export const API_URL = "http://localhost:7097"

// export const API_URL = "http://43.204.99.101:7097"

// // export const API_URL = "https://api.spaceai.finance"

// export const FUND_RECEIVER = "0x12ABeD5514FD6F6716F1154a1Be05beD9E1a11dF"

// export const COINS = ["ETH" , "DAI" , "USDT" , "USDC" , "ETH" , "MATIC" , "AVAX" , "CRO" , "FTM"];

// export const HC = ["Presale" , "Fairlaunch" , "Auction" , "Subscription"];

// export const STATUS = ["Upcoming" , "Live" , "Ended"];

// export const TOKEN_FEE = 0.01;

// export const ZEROTH_ADDRESS = "0x0000000000000000000000000000000000000000"


// // export const WBNB_Address = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; // BSC Mainet

// // export const WBNB_Address = "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd";  //BSC Testnet

// export const WBNB_Address = "0x1d308089a2d1ced3f1ce36b1fcaf815b07217be3"

// export const PROJECT_ID = "b8a1daa2dd22335a2fe1d2e139980ae0"
