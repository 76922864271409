import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';


import Adminheader from './Adminheader';
import Adminsidebar from './Adminsidebar';
import Cookies from 'universal-cookie';



import '../../css/styles.css';

import favicon from "../../images/Bitdeal/logo_curve.png"

import loader from "../../images/loader.gif"
import { InputGroup,FormControl } from 'react-bootstrap';
import { getsettinghook } from '../../hooks/usebackend';
import { GetAdminfee, GetAirdropfee, getSaleInfo, getSaleInfoCard, SetAirdropDeployfee, SetDeploymentfee, SetMaxvalue, SetMinvalue, SetTokenfee, SetTokenRate, SetUserfee } from '../../hooks/useContract';
import { BSALE } from '../../config/proxy';
import { withdrawBNB } from '../../hooks/useAdmin';
import { getAccount } from '../../hooks/useAccount';
const cookies = new Cookies();
class AdminBcoinSettings extends Component {
   
  showLoader()
  {
    document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 0.5;
    document.getElementById("loader_div").classList.remove("d-none");
    document.getElementById("loader_div").classList.add("d-block");


  }

  hideLoader()
  {
    document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
    document.getElementById("logo_overlay").style.opacity = 1;
    document.getElementById("loader_div").classList.remove("d-block");
    document.getElementById("loader_div").classList.add("d-none");
  }
  
    componentDidMount()
    {
     this.hideLoader();
     this.getfee();
     this.airdropgetfee();
     this.restrict();
    }

    restrict = async()=>{
      var email = cookies.get('cryp-launch-admin-email');
      console.log("email" , email);
      if(!email){
        this.props.history.push("/login")
      }
    }

    async getfee(){
      // var fee = await GetAdminfee();
      // console.log("fee" , fee);
      // this.setState({deploymentfee : fee.deploymentfee/10**18});
      // this.setState({tokenfee : fee.tokenfee/10**18});
      // this.setState({userfee : fee.liquidityTokenFee/10**18});
      let saleinfo = await getSaleInfoCard(BSALE);
      
      let min = parseFloat(saleinfo?.minEthLimit)/10**18
      let max = parseFloat(saleinfo?.maxEthLimit)/10**18
      let rate = parseFloat(saleinfo.presaleRate)
      this.setState({"saleinfo" : saleinfo , "minlimit" : min , "maxlimit" : max , tokenrate : rate});
      console.log("saleingo" , saleinfo);
    }
    async airdropgetfee(){
      let fee = await GetAirdropfee();
      console.log("fee" , fee);
      this.setState({ airdropfee: fee / 10 ** 18 })
  }

    async setTokenrate(value){
      let newfee = value.toString()
      console.log("newFee" , newfee);
      await SetTokenRate(BSALE , newfee);
    }

    async setminethlimit(value){
      let newfee = parseFloat(value)*(10**18)
      console.log("newFee" , newfee);
      await SetMinvalue(BSALE , newfee.toString());
    }

    async setmaxethlimit(value){
      let newfee = value*(10**18)
      await SetMaxvalue(BSALE , newfee.toString());
    }

    async BNBwithdraw(){
      await withdrawBNB(BSALE,getAccount());
      // await this.refreshData()
    }


    async setairdropfee(value){
      let newfee = value*(10**18)
      let fee = await SetAirdropDeployfee(newfee);
      window.location.reload();
    }

    constructor(props) {
      super(props);
      this.state = {
        deploymentfee : 0,
        tokenfee : 0,
        userfee : 0,
        airdropfee : 0,
        minlimit : 0,
        maxlimit : 0,
        tokenrate : 0,
        saleinfo : {}
      }
    }

    render() {
     

      const location = this.props.location.pathname.split('/')[1];


      
	return (
    <div id="loader_main">
    <div id="loader_div">
    <span className="spin_round">

    </span>
    <img src={favicon} className="logo_load" />
  </div>
        <div className='logo_overlay' id="logo_overlay">
      

           <Adminheader />
        
           <div className="whole_sec pb-5">
            <div className='flex_side_right'>
            <Adminsidebar />
           {/* ongoing_sec */}
           <div className='right_side_sec'>
        
              <div className="right_side_spacing">
              <div className='ongoing_sec pb-5 admn_form'>
            <div className="container px-0">
                <div className='top_heqad_sec mt-5 mt-md-0'>
                   
                  <div className="row">
                      <div className="col-12 col-md-12 flex_cen_col mt-md-5 mb-md-4">
                        <p className="banner_subtitle form_subhead">BCoin Token Presale Settings</p>



                       
                      
                      </div>
                      
                        </div>

                        <div className='row'>
           <div className='col-12 col-md-10 col-lg-9 mx-auto'>
           <div className="tab_img">
                 <div className='card_bg card'>
            <div className='card-body'>
            <div className='row'>
                            <div className='col-12'>
                            <p className='input_desc_sm'>Token Rate 1 BNB</p>
                        <div className="inputs input-groups noearp_input_admin">
                        <InputGroup className="datepicker_input">
                        <FormControl id="price" placeholder="Enter Price"
                                aria-describedby="basic-addon2"
                               onChange={(e)=>{this.setState({tokenrate : e?.target?.value})}}
                                value = {this.state.tokenrate}
                            />
                              <InputGroup.Text id="basic-addon2">
                              <button className="get-started-btn"
                                onClick={()=>{this.setTokenrate(this.state.tokenrate)}}
                              >
                                Update
                                </button>
                                </InputGroup.Text>
                          
                        </InputGroup>
                            </div>

                            <p className='input_desc_sm mt-3'>Minimum Buy Limit in BNB(peruser)</p>
                        <div className="inputs input-groups noearp_input_admin">
                        <InputGroup className="datepicker_input">
                        <FormControl id="price" placeholder="Enter Price"
                                aria-describedby="basic-addon2"
                               onChange={(e)=>{this.setState({minlimit : e?.target?.value})}}
                                value = {this.state.minlimit}
                            />
                              <InputGroup.Text id="basic-addon2">
                              <button className="get-started-btn"
                                onClick={()=>{this.setminethlimit(this.state.minlimit)}}
                              >
                                Update
                                </button>
                                </InputGroup.Text>
                          
                        </InputGroup>
                            </div>


                            <p className='input_desc_sm mt-3'>Maximum Buy Limit in BNB(peruser)</p>
                        <div className="inputs input-groups noearp_input_admin">
                        <InputGroup className="datepicker_input">
                        <FormControl id="price" placeholder="Enter Price"
                                aria-describedby="basic-addon2"
                               onChange={(e)=>{this.setState({maxlimit : e?.target?.value})}}
                                value = {this.state.maxlimit}
                            />
                              <InputGroup.Text id="basic-addon2">
                              <button className="get-started-btn"
                                onClick={()=>{this.setmaxethlimit(this.state.maxlimit)}}
                              >
                                Update
                                </button>
                                </InputGroup.Text>
                          
                        </InputGroup>
                            </div>

                            <div className='mt-4 flke_align_btn_txt'>
                              <h1 className='input_desc_sm'>WITHDRAW BNB</h1>
                              <button className="get-started-btn"
                                onClick={()=>{this.BNBwithdraw()}}
                              >
                                Withdraw
                                </button>
                            </div>
                            </div>
                            
                      

                          
                            {/* <div className='col-12 mt-3'>
                            <p className='input_desc_sm'>Maximum Contribution for USDT*</p>
                        <div className="inputs input-groups noearp_input_admin">
                        <InputGroup className="datepicker_input">
                        <FormControl id="maxcontribution" placeholder="Enter Maximum Contribution"
                                aria-describedby="basic-addon2"
                               
                                
                            />
                              <InputGroup.Text id="basic-addon2">
                              <button className="get-started-btn">
                                Update
                                </button>
                                </InputGroup.Text>
                          
                        </InputGroup>
                            </div>
                            </div>

                            <div className='col-12 mt-3'>
                            <p className='input_desc_sm'>Hard Cap for USDT*</p>
                        <div className="inputs input-groups noearp_input_admin">
                        <InputGroup className="datepicker_input">
                        <FormControl id="hardcap" placeholder="Enter Hard Cap"
                                aria-describedby="basic-addon2"
                               
                                
                            />
                              <InputGroup.Text id="basic-addon2">
                              <button className="get-started-btn">
                                Update
                                </button>
                                </InputGroup.Text>
                          
                        </InputGroup>
                            </div>
                            </div>

                            <div className='col-12 mt-3'>
                            <p className='input_desc_sm'>Deposit Token*</p>
                        <div className="inputs input-groups noearp_input_admin">
                        <InputGroup className="datepicker_input">
                        <FormControl id="deposittoken" placeholder="Enter Deposit Token"
                                aria-describedby="basic-addon2"
                               
                                
                            />
                              <InputGroup.Text id="basic-addon2">
                              <button className="get-started-btn">
                                Update
                                </button>
                                </InputGroup.Text>
                          
                        </InputGroup>
                            </div>
                            </div> */}
{/* 
                            <div className='col-12 col-md-12 text-center mt-4'>                   
                        <button className="get-started-btn">
                          Submit
                        </button>
                        </div> */}
                        </div>
                </div>
                </div>
                </div>
                </div>
                </div>
               
                       
                        </div>
                        </div>
                        </div>
            {/* end ongoing_sec */}

            </div>
            </div>
            </div>
            </div>
          


            {/* <Footer /> */}
        </div>
        </div>
        )
    }
}

export default AdminBcoinSettings